import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SevenOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Seven of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "seven_of_swords",
        title: "seven_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Seven of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/seven_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords07.jpg' alt='Seven of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/six_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Six of Swords</Link>   
                    <Link to='/eight_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Eight of Swords</Link>
                </div>
                <h2>Overview of the Seven of Swords</h2>

                <p>The Seven of Swords is a card in the Minor Arcana of the Tarot deck that represents deception, strategy, and cunning. It signifies a period of using one's wit and resourcefulness to navigate complex situations, but also warns of the potential for deceit and betrayal. This card encourages the querent to be cautious, think strategically, and remain aware of potential hidden agendas.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Seven of Swords typically features a figure stealthily carrying five swords while leaving two swords behind. The scene depicts a sense of strategy, cunning, and the careful execution of a plan. The overall imagery emphasizes themes of deception, strategy, and the need for caution.</p>

                <p>The Seven of Swords' symbolism emphasizes themes of deception, strategy, and cunning. This card encourages the querent to be cautious, think strategically, and remain aware of potential hidden agendas.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Seven of Swords appears in a reading, it signifies deception, strategy, and cunning. It suggests that the querent is in or will soon enter a period of using their wit and resourcefulness to navigate complex situations, but also warns of the potential for deceit and betrayal. The Seven of Swords encourages the querent to be cautious, think strategically, and remain aware of potential hidden agendas.</p>

                <p>In the context of personal growth, the Seven of Swords can indicate a period of self-discovery and the development of strategic thinking and resourcefulness. It may suggest that the querent is learning to navigate complex situations, use their wit, and remain cautious of potential deceit. In relationships, the Seven of Swords can signify the need for honesty, transparency, and the careful navigation of trust and communication.</p>

                <p>In career and financial matters, the Seven of Swords can signal opportunities for growth and success through strategic thinking and cunning. It advises the querent to be cautious, think strategically, and remain aware of potential hidden agendas. The Seven of Swords reminds the querent that with deception, strategy, and cunning, they can navigate complex situations and achieve their goals.</p>

                <h2>Conclusion</h2>

                <p>The Seven of Swords in the Tarot deck is a powerful symbol of deception, strategy, and cunning. By understanding and embracing the energy it represents, individuals can be cautious, think strategically, and remain aware of potential hidden agendas. The Seven of Swords encourages the querent to be cautious, think strategically, and remain aware of potential hidden agendas. It is a reminder that with deception, strategy, and cunning, we can navigate complex situations and achieve our goals.</p>

                <Footer />
            </div>
        </>
    )
}
