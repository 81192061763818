import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SixOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Six of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "six_of_pentacles",
        title: "six_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Six of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/six_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents06.jpg' alt='Six of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/five_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Five of Pentacles</Link>   
                    <Link to='/seven_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Seven of Pentacles</Link>
                </div>
                <h2>Overview of the Six of Pentacles</h2>

                <p>The Six of Pentacles is a card in the Minor Arcana of the Tarot deck that represents generosity, charity, and the balance between giving and receiving. It signifies a period of sharing resources, helping others, and finding equilibrium in the flow of wealth and support. This card encourages the querent to practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Six of Pentacles typically features a wealthy figure distributing coins to two beggars, with a balanced scale in one hand. The scene depicts a sense of charity, fairness, and the equitable distribution of resources. The overall imagery emphasizes themes of generosity, charity, and the balance between giving and receiving.</p>

                <p>The Six of Pentacles' symbolism emphasizes themes of generosity, charity, and the balance between giving and receiving. This card encourages the querent to practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Six of Pentacles appears in a reading, it signifies generosity, charity, and the balance between giving and receiving. It suggests that the querent is in or will soon enter a period of sharing resources, helping others, and finding equilibrium in the flow of wealth and support. The Six of Pentacles encourages the querent to practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help.</p>

                <p>In the context of personal growth, the Six of Pentacles can indicate a period of self-discovery and the development of a generous spirit. It may suggest that the querent is learning to balance their needs with the needs of others and is finding fulfillment in acts of kindness and charity. In relationships, the Six of Pentacles can signify the need for mutual support, understanding, and the importance of giving and receiving help within the partnership.</p>

                <p>In career and financial matters, the Six of Pentacles can signal opportunities for growth and success through generosity and fair dealings. It advises the querent to practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help. The Six of Pentacles reminds the querent that with generosity, charity, and balance, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Six of Pentacles in the Tarot deck is a powerful symbol of generosity, charity, and the balance between giving and receiving. By understanding and embracing the energy it represents, individuals can practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help. The Six of Pentacles encourages the querent to practice generosity, seek balance in their financial dealings, and understand the importance of both giving and receiving help. It is a reminder that with generosity, charity, and balance, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
