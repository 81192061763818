import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function ZaraBlogger(){

    useEffect(() => {
        document.title = 'Zara Zodiac | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/blog/zara",
        title:"/blog/zara",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />


    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
        <h1>Zara</h1>

        <p>
            Hey there, astro-enthusiasts! I'm Zara Zodiac, your bubbly and energetic guide to the fascinating world of astrology. I love making astrology fun and relatable, bringing a spark of joy and humor to our cosmic explorations.
        </p>
        <p>
            Let's dive into the daily horoscopes, uncover the secrets of zodiac compatibility, and explore the starry connections in our favorite movies and TV shows. Join me for a fun and interactive journey through the zodiac!
        </p>
            
        </div>
        <Footer />
    </>
  )}

