import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ThreeOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Three of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "three_of_swords",
        title: "three_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Three of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/three_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords03.jpg' alt='Three of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/two_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Two of Swords</Link>   
                    <Link to='/four_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Four of Swords</Link>
                </div>
                <h2>Overview of the Three of Swords</h2>

                <p>The Three of Swords is a card in the Minor Arcana of the Tarot deck that represents heartbreak, sorrow, and emotional pain. It signifies a period of grief, betrayal, or loss that deeply affects the querent's emotional state. This card encourages the querent to acknowledge their pain, allow themselves to grieve, and seek healing and understanding.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Three of Swords typically features a heart pierced by three swords, with stormy clouds and rain in the background. The scene depicts a sense of heartbreak, sorrow, and emotional turmoil. The overall imagery emphasizes themes of grief, betrayal, and the deep emotional impact of loss.</p>

                <p>The Three of Swords' symbolism emphasizes themes of heartbreak, sorrow, and emotional pain. This card encourages the querent to acknowledge their pain, allow themselves to grieve, and seek healing and understanding.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Three of Swords appears in a reading, it signifies heartbreak, sorrow, and emotional pain. It suggests that the querent is in or will soon enter a period of grief, betrayal, or loss that deeply affects their emotional state. The Three of Swords encourages the querent to acknowledge their pain, allow themselves to grieve, and seek healing and understanding.</p>

                <p>In the context of personal growth, the Three of Swords can indicate a period of self-discovery and the development of emotional resilience. It may suggest that the querent is learning to process their emotions, heal from their pain, and find strength in their experiences. In relationships, the Three of Swords can signify the need for honesty, communication, and the resolution of conflicts to heal from emotional wounds.</p>

                <p>In career and financial matters, the Three of Swords can signal challenges and setbacks that may cause emotional distress. It advises the querent to acknowledge their pain, allow themselves to grieve, and seek healing and understanding. The Three of Swords reminds the querent that with heartbreak, sorrow, and emotional pain, they can still find a way to heal and move forward.</p>

                <h2>Conclusion</h2>

                <p>The Three of Swords in the Tarot deck is a powerful symbol of heartbreak, sorrow, and emotional pain. By understanding and embracing the energy it represents, individuals can acknowledge their pain, allow themselves to grieve, and seek healing and understanding. The Three of Swords encourages the querent to acknowledge their pain, allow themselves to grieve, and seek healing and understanding. It is a reminder that with heartbreak, sorrow, and emotional pain, we can find a way to heal and move forward.</p>

                <Footer />
            </div>
        </>
    )
}
