import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SevenOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Seven of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "seven_of_wands",
        title: "seven_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Seven of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/seven_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands07.jpg' alt='Seven of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/six_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Six of Wands</Link>   
                    <Link to='/eight_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Eight of Wands</Link>
                </div>
                <h2>Overview of the Seven of Wands</h2>

                <p>The Seven of Wands is a card in the Minor Arcana of the Tarot deck that represents defense, perseverance, and standing one's ground. It signifies a period of challenges and opposition, where one must defend their position and beliefs against external pressures. This card encourages the querent to stay firm, be confident in their abilities, and persist through obstacles to achieve their goals.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Seven of Wands typically features a figure standing on a hill, holding a wand defensively, while six other wands are pointed towards them from below. The scene depicts a sense of conflict and the need to defend one's position. The overall imagery emphasizes themes of defense, perseverance, and standing one's ground.</p>

                <p>The Seven of Wands' symbolism emphasizes themes of defense, perseverance, and standing one's ground. This card encourages the querent to stay firm, be confident in their abilities, and persist through obstacles to achieve their goals.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Seven of Wands appears in a reading, it signifies defense, perseverance, and standing one's ground. It suggests that the querent is in or will soon enter a period of challenges and opposition, where they must defend their position and beliefs against external pressures. The Seven of Wands encourages the querent to stay firm, be confident in their abilities, and persist through obstacles to achieve their goals.</p>

                <p>In the context of personal growth, the Seven of Wands can indicate a period of self-discovery and the development of resilience through challenges. It may suggest that the querent is learning to stand firm in their beliefs and defend their position against opposition. In relationships, the Seven of Wands can signify the need to address conflicts and maintain one's stance while navigating challenges within the partnership.</p>

                <p>In career and financial matters, the Seven of Wands can signal opportunities for growth and success through perseverance and the defense of one's position. It advises the querent to stay firm, be confident in their abilities, and persist through obstacles to achieve their goals. The Seven of Wands reminds the querent that with defense, perseverance, and standing one's ground, they can achieve their goals and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Seven of Wands in the Tarot deck is a powerful symbol of defense, perseverance, and standing one's ground. By understanding and embracing the energy it represents, individuals can stay firm, be confident in their abilities, and persist through obstacles to achieve their goals. The Seven of Wands encourages the querent to stay firm, be confident in their abilities, and persist through obstacles to achieve their goals. It is a reminder that with defense, perseverance, and standing one's ground, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
