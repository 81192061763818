import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';
import Home from '../src/pages/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import FAQ from './pages/FAQ/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/BlogPost';
import Post1 from './pages/Blog/BlogPosts/Post1';
import Post2 from './pages/Blog/BlogPosts/Post2';
import LunaBlogger from './pages/Blog/Luna';
import StarBlogger from './pages/Blog/Star';
import MavenBlogger from './pages/Blog/Maven';
import ZaraBlogger from './pages/Blog/Zara';
import ChaldeanNumerology from '../src/pages/ChaldeanNumerology/ChaldeanNumerology';
import PythagoreanNumerology from '../src/pages/PythagoreanNumerology/PythagoreanNumerology';
import KabNum from './pages/Numerology/KabNum';
import ChineseNumerology from './pages/Numerology/ChineseNumerology';
import NumberPage from '../src/pages/NumberPage/NumberPage'
import Zodiac from './pages/Zodiac/Zodiac';
import Numerology from './pages/Numerology/Numerology';
import Aries from './pages/Zodiac/Aries';
import Taurus from './pages/Zodiac/Taurus';
import Gemini from './pages/Zodiac/Gemini';
import Cancer from './pages/Zodiac/Cancer';
import Leo from './pages/Zodiac/Leo';
import Virgo from './pages/Zodiac/Virgo';
import Libra from './pages/Zodiac/Libra';
import Scorpio from './pages/Zodiac/Scorpio';
import Sagittarius from './pages/Zodiac/Sagittarius';
import Capricorn from './pages/Zodiac/Capricorn';
import Aquarius from './pages/Zodiac/Aquarius';
import Pisces from './pages/Zodiac/Pisces';
import Chald1 from './pages/NumberPage/Chald1';
import Chald2 from './pages/NumberPage/Chald2';
import Chald3 from './pages/NumberPage/Chald3';
import Chald4 from './pages/NumberPage/Chald4';
import Chald5 from './pages/NumberPage/Chald5';
import Chald6 from './pages/NumberPage/Chald6';
import Chald7 from './pages/NumberPage/Chald7';
import Chald8 from './pages/NumberPage/Chald8';
import Chald9 from './pages/NumberPage/Chald9';
import LifePath1 from './pages/NumberPage/LifePath1';
import LifePath2 from './pages/NumberPage/LifePath2';
import LifePath3 from './pages/NumberPage/LifePath3';
import LifePath4 from './pages/NumberPage/LifePath4';
import LifePath5 from './pages/NumberPage/LifePath5';
import LifePath6 from './pages/NumberPage/LifePath6';
import LifePath7 from './pages/NumberPage/LifePath7';
import LifePath8 from './pages/NumberPage/LifePath8';
import LifePath9 from './pages/NumberPage/LifePath9';
import Tarot from './pages/Tarot/Tarot';
import MajorArcana from './pages/Tarot/MajorArcana';
import MinorArcana from './pages/Tarot/MinorArcana';
import Wands from './pages/Tarot/Wands';
import Swords from './pages/Tarot/Swords';
import Cups from './pages/Tarot/Cups';
import Pentacles from './pages/Tarot/Pentacles';
import DeathTarot from './pages/Tarot/Death';
import FoolTarot from './pages/Tarot/TheFool';
import MagicianTarot from './pages/Tarot/TheMagician';
import HighPriestessTarot from './pages/Tarot/TheHighPriestess';
import EmpressTarot from './pages/Tarot/TheEmpress';
import EmperorTarot from './pages/Tarot/TheEmperor';
import HierophantTarot from './pages/Tarot/TheHierophant';
import LoversTarot from './pages/Tarot/TheLovers';
import ChariotTarot from './pages/Tarot/TheChariot';
import StrengthTarot from './pages/Tarot/Strength';
import HermitTarot from './pages/Tarot/TheHermit';
import WheelOfFortuneTarot from './pages/Tarot/WheelOfFortune';
import JusticeTarot from './pages/Tarot/Justice';
import HangedManTarot from './pages/Tarot/TheHangedMan';
import TemperanceTarot from './pages/Tarot/Temperance';
import DevilTarot from './pages/Tarot/TheDevil';
import TowerTarot from './pages/Tarot/TheTower';
import StarTarot from './pages/Tarot/TheStar';
import MoonTarot from './pages/Tarot/TheMoon';
import SunTarot from './pages/Tarot/TheSun';
import JudgmentTarot from './pages/Tarot/Judgement';
import WorldTarot from './pages/Tarot/TheWorld';
import AceOfCups from './pages/Tarot/Cups/AceOfCups';
import TwoOfCups from './pages/Tarot/Cups/TwoOfCups';
import ThreeOfCups from './pages/Tarot/Cups/ThreeOfCups';
import FourOfCups from './pages/Tarot/Cups/FourOfCups';
import FiveOfCups from './pages/Tarot/Cups/FiveOfCups';
import SixOfCups from './pages/Tarot/Cups/SixOfCups';
import SevenOfCups from './pages/Tarot/Cups/SevenOfCups';
import EightOfCups from './pages/Tarot/Cups/EightOfCups';
import NineOfCups from './pages/Tarot/Cups/NineOfCups';
import TenOfCups from './pages/Tarot/Cups/TenOfCups';
import PageOfCups from './pages/Tarot/Cups/PageOfCups';
import KnightOfCups from './pages/Tarot/Cups/KnightOfCups';
import QueenOfCups from './pages/Tarot/Cups/QueenOfCups';
import KingOfCups from './pages/Tarot/Cups/KingOfCups';
import AceOfWands from './pages/Tarot/Wands/AceOfWands';
import TwoOfWands from './pages/Tarot/Wands/TwoOfWands';
import ThreeOfWands from './pages/Tarot/Wands/ThreeOfWands';
import FourOfWands from './pages/Tarot/Wands/FourOfWands';
import FiveOfWands from './pages/Tarot/Wands/FiveOfWands';
import SixOfWands from './pages/Tarot/Wands/SixOfWands';
import SevenOfWands from './pages/Tarot/Wands/SevenOfWands';
import EightOfWands from './pages/Tarot/Wands/EightOfWands';
import NineOfWands from './pages/Tarot/Wands/NineOfWands';
import TenOfWands from './pages/Tarot/Wands/TenOfWands';
import PageOfWands from './pages/Tarot/Wands/PageOfWands';
import KnightOfWands from './pages/Tarot/Wands/KnightOfWands';
import QueenOfWands from './pages/Tarot/Wands/QueenOfWands';
import KingOfWands from './pages/Tarot/Wands/KingOfWands';
import AceOfPentacles from './pages/Tarot/Pents/AceOfPentacles.jsx';
import TwoOfPentacles from './pages/Tarot/Pents/TwoOfPentacles.jsx';
import ThreeOfPentacles from './pages/Tarot/Pents/ThreeOfPentacles.jsx';
import FourOfPentacles from './pages/Tarot/Pents/FourOfPentacles.jsx';
import FiveOfPentacles from './pages/Tarot/Pents/FiveOfPentacles.jsx';
import SixOfPentacles from './pages/Tarot/Pents/SixOfPentacles.jsx';
import SevenOfPentacles from './pages/Tarot/Pents/SevenOfPentacles.jsx';
import EightOfPentacles from './pages/Tarot/Pents/EightOfPentacles.jsx';
import NineOfPentacles from './pages/Tarot/Pents/NineOfPentacles.jsx';
import TenOfPentacles from './pages/Tarot/Pents/TenOfPentacles.jsx';
import PageOfPentacles from './pages/Tarot/Pents/PageOfPentacles.jsx';
import QueenOfPentacles from './pages/Tarot/Pents/QueenOfPentacles.jsx';
import KnightOfPentacles from './pages/Tarot/Pents/KnightOfPentacles.jsx';
import KingOfPentacles from './pages/Tarot/Pents/KingOfPentacles.jsx';
import AceOfSwords from './pages/Tarot/Swords/AceOfSwords.jsx';
import KingOfSwords from './pages/Tarot/Swords/KingOfSwords.jsx';
import TwoOfSwords from './pages/Tarot/Swords/TwoOfSwords.jsx';
import ThreeOfSwords from './pages/Tarot/Swords/ThreeOfSwords.jsx';
import FourOfSwords from './pages/Tarot/Swords/FourOfSwords.jsx';
import FiveOfSwords from './pages/Tarot/Swords/FiveOfSwords.jsx';
import SixOfSwords from './pages/Tarot/Swords/SixOfSwords.jsx';
import SevenOfSwords from './pages/Tarot/Swords/SevenOfSwords.jsx';
import EightOfSwords from './pages/Tarot/Swords/EightOfSwords.jsx';
import NineOfSwords from './pages/Tarot/Swords/NineOfSwords.jsx';
import TenOfSwords from './pages/Tarot/Swords/TenOfSwords.jsx';
import PageOfSwords from './pages/Tarot/Swords/PageOfSwords.jsx';
import KnightOfSwords from './pages/Tarot/Swords/KnightOfSwords.jsx';
import QueenOfSwords from './pages/Tarot/Swords/QueenOfSwords.jsx';

import ChineseZodiac from './pages/ChineseZodiac/ChineseZodiac.jsx';
import ChineseZodiacRat from './pages/ChineseZodiac/ChineseZodiacRat.jsx';
import ChineseZodiacOx from './pages/ChineseZodiac/ChineseZodiacOx.jsx';
import ChineseZodiacTiger from './pages/ChineseZodiac/ChineseZodiacTiger.jsx';
import ChineseZodiacRabbit from './pages/ChineseZodiac/ChineseZodiacRabbit.jsx';
import ChineseZodiacDragon from './pages/ChineseZodiac/ChineseZodiacDragon.jsx';
import ChineseZodiacSnake from './pages/ChineseZodiac/ChineseZodiacSnake.jsx';
import ChineseZodiacHorse from './pages/ChineseZodiac/ChineseZodiacHorse.jsx';
import ChineseZodiacGoat from './pages/ChineseZodiac/ChineseZodiacGoat.jsx';
import ChineseZodiacMonkey from './pages/ChineseZodiac/ChineseZodiacMonkey.jsx';
import ChineseZodiacRooster from './pages/ChineseZodiac/ChineseZodiacRooster.jsx';
import ChineseZodiacDog from './pages/ChineseZodiac/ChineseZodiacDog.jsx';
import ChineseZodiacPig from './pages/ChineseZodiac/ChineseZodiacPig.jsx';
import FoolsJourney from './pages/Tarot/FoolsJourney.jsx';



//npm start
//npm run build



function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );


  const handleAcceptCookie = () => {

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }    

  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    console.log("DECLINED!")
  };



  return (
    <>
    <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie} buttonText="Sure!" declineButtonText = "No Thanks" style={{height:'35%', fontSize:'1.5em', textAlign:'center'}} declineButtonStyle={{color: "black", background: '#535bf2', fontSize: "1em", fontWeight:'bold', minWidth:'150px', borderRadius:'12px'}} buttonStyle={{background: '#535bf2', fontSize: "1em", fontWeight:'bold', minWidth:'150px', borderRadius:'12px'}}>
        Our website uses cookies to give you the best possible experience, would you like to allow them?
      </CookieConsent>

      <BrowserRouter>
        <Routes>
          <Route path="/chaldean_numerology" element={<ChaldeanNumerology />} />
          <Route path="/pythagorean_numerology" element={<PythagoreanNumerology />} />
          <Route path="/kabbalistic_numerology" element={<KabNum />} />
          <Route path="/chinese_numerology" element={<ChineseNumerology />} />
          <Route path='/chaldean_number/:number' element={<NumberPage />} />
          <Route path='/chaldean_number_1' element={<Chald1 />} />
          <Route path='/chaldean_number_2' element={<Chald2 />} />
          <Route path='/chaldean_number_3' element={<Chald3 />} />
          <Route path='/chaldean_number_4' element={<Chald4 />} />
          <Route path='/chaldean_number_5' element={<Chald5 />} />
          <Route path='/chaldean_number_6' element={<Chald6 />} />
          <Route path='/chaldean_number_7' element={<Chald7 />} />
          <Route path='/chaldean_number_8' element={<Chald8 />} />
          <Route path='/chaldean_number_9' element={<Chald9 />} />
          <Route path='/life_path_1' element={<LifePath1 />} />
          <Route path='/life_path_2' element={<LifePath2 />} />
          <Route path='/life_path_3' element={<LifePath3 />} />
          <Route path='/life_path_4' element={<LifePath4 />} />
          <Route path='/life_path_5' element={<LifePath5 />} />
          <Route path='/life_path_6' element={<LifePath6 />} />
          <Route path='/life_path_7' element={<LifePath7 />} />
          <Route path='/life_path_8' element={<LifePath8 />} />
          <Route path='/life_path_9' element={<LifePath9 />} />
          <Route path='/numerology' element={<Numerology />} />
          <Route path='/tarot' element={<Tarot />} />
          <Route path='/fools_journey' element={<FoolsJourney />} />
          <Route path='/major_arcana' element={<MajorArcana />} />
          <Route path='/minor_arcana' element={<MinorArcana />} />
          <Route path='/death_tarot' element={<DeathTarot />} />
          <Route path='/fool_tarot' element={<FoolTarot />} />
          <Route path='/magician_tarot' element={<MagicianTarot />} />
          <Route path='/high_priestess_tarot' element={<HighPriestessTarot />} />
          <Route path='/empress_tarot' element={<EmpressTarot />} />
          <Route path='/emperor_tarot' element={<EmperorTarot />} />
          <Route path='/hierophant_tarot' element={<HierophantTarot />} />
          <Route path='/lovers_tarot' element={<LoversTarot />} />
          <Route path='/chariot_tarot' element={<ChariotTarot />} />
          <Route path='/strength_tarot' element={<StrengthTarot />} />
          <Route path='/hermit_tarot' element={<HermitTarot />} />
          <Route path='/wheel_of_fortune_tarot' element={<WheelOfFortuneTarot />} />
          <Route path='/justice_tarot' element={<JusticeTarot />} />
          <Route path='/hanged_man_tarot' element={<HangedManTarot />} />
          <Route path='/temperance_tarot' element={<TemperanceTarot />} />
          <Route path='/devil_tarot' element={<DevilTarot />} />
          <Route path='/tower_tarot' element={<TowerTarot />} />
          <Route path='/star_tarot' element={<StarTarot />} />
          <Route path='/moon_tarot' element={<MoonTarot />} />
          <Route path='/sun_tarot' element={<SunTarot />} />
          <Route path='/judgement_tarot' element={<JudgmentTarot />} />
          <Route path='/world_tarot' element={<WorldTarot />} />
          <Route path='/ace_of_cups' element={<AceOfCups />} />
          <Route path='/two_of_cups' element={<TwoOfCups />} />
          <Route path='/three_of_cups' element={<ThreeOfCups />} />
          <Route path='/four_of_cups' element={<FourOfCups />} />
          <Route path='/five_of_cups' element={<FiveOfCups />} />
          <Route path='/six_of_cups' element={<SixOfCups />} />
          <Route path='/seven_of_cups' element={<SevenOfCups />} />
          <Route path='/eight_of_cups' element={<EightOfCups />} />
          <Route path='/nine_of_cups' element={<NineOfCups />} />
          <Route path='/ten_of_cups' element={<TenOfCups />} />
          <Route path='/two_of_cups' element={<TwoOfCups />} />
          <Route path='/two_of_cups' element={<TwoOfCups />} />
          <Route path='/two_of_cups' element={<TwoOfCups />} />
          <Route path='/two_of_cups' element={<TwoOfCups />} />
          <Route path='/ten_of_cups' element={<TwoOfCups />} />
          <Route path='/page_of_cups' element={<PageOfCups />} />
          <Route path='/knight_of_cups' element={<KnightOfCups />} />
          <Route path='/queen_of_cups' element={<QueenOfCups />} />
          <Route path='/king_of_cups' element={<KingOfCups />} />
          <Route path='/ace_of_wands' element={<AceOfWands />} />
          <Route path='/two_of_wands' element={<TwoOfWands />} />
          <Route path='/three_of_wands' element={<ThreeOfWands />} />
          <Route path='/four_of_wands' element={<FourOfWands />} />
          <Route path='/five_of_wands' element={<FiveOfWands />} />
          <Route path='/six_of_wands' element={<SixOfWands />} />
          <Route path='/seven_of_wands' element={<SevenOfWands />} />
          <Route path='/eight_of_wands' element={<EightOfWands />} />
          <Route path='/nine_of_wands' element={<NineOfWands />} />
          <Route path='/ten_of_wands' element={<TenOfWands />} />
          <Route path='/page_of_wands' element={<PageOfWands />} />
          <Route path='/knight_of_wands' element={<KnightOfWands />} />
          <Route path='/queen_of_wands' element={<QueenOfWands />} />
          <Route path='/king_of_wands' element={<KingOfWands />} />
          <Route path='/ace_of_pentacles' element={<AceOfPentacles />} />
          <Route path='/two_of_pentacles' element={<TwoOfPentacles />} />
          <Route path='/three_of_pentacles' element={<ThreeOfPentacles />} />
          <Route path='/four_of_pentacles' element={<FourOfPentacles />} />
          <Route path='/five_of_pentacles' element={<FiveOfPentacles />} />
          <Route path='/six_of_pentacles' element={<SixOfPentacles />} />
          <Route path='/seven_of_pentacles' element={<SevenOfPentacles />} />
          <Route path='/eight_of_pentacles' element={<EightOfPentacles />} />
          <Route path='/nine_of_pentacles' element={<NineOfPentacles />} />
          <Route path='/ten_of_pentacles' element={<TenOfPentacles />} />
          <Route path='/page_of_pentacles' element={<PageOfPentacles />} />
          <Route path='/knight_of_pentacles' element={<KnightOfPentacles />} />
          <Route path='/queen_of_pentacles' element={<QueenOfPentacles />} />
          <Route path='/king_of_pentacles' element={<KingOfPentacles />} />
          <Route path='/ace_of_swords' element={<AceOfSwords />} />
          <Route path='/two_of_swords' element={<TwoOfSwords />} />
          <Route path='/three_of_swords' element={<ThreeOfSwords />} />
          <Route path='/four_of_swords' element={<FourOfSwords />} />
          <Route path='/five_of_swords' element={<FiveOfSwords />} />
          <Route path='/six_of_swords' element={<SixOfSwords />} />
          <Route path='/seven_of_swords' element={<SevenOfSwords />} />
          <Route path='/eight_of_swords' element={<EightOfSwords />} />
          <Route path='/nine_of_swords' element={<NineOfSwords />} />
          <Route path='/ten_of_swords' element={<TenOfSwords />} />
          <Route path='/page_of_swords' element={<PageOfSwords />} />
          <Route path='/knight_of_swords' element={<KnightOfSwords />} />
          <Route path='/queen_of_swords' element={<QueenOfSwords />} />
          <Route path='/king_of_swords' element={<KingOfSwords />} />


          <Route path='/wands' element={<Wands />} />
          <Route path='/cups' element={<Cups />} />
          <Route path='/pentacles' element={<Pentacles />} />
          <Route path='/swords' element={<Swords />} />
          <Route path='/zodiac' element={<Zodiac />} />
          <Route path='/aries' element={<Aries />} />
          <Route path='/taurus' element={<Taurus />} />
          <Route path='/gemini' element={<Gemini />} />
          <Route path='/cancer' element={<Cancer />} />
          <Route path='/leo' element={<Leo />} />
          <Route path='/virgo' element={<Virgo />} />
          <Route path='/libra' element={<Libra />} />
          <Route path='/scorpio' element={<Scorpio />} />
          <Route path='/sagittarius' element={<Sagittarius />} />
          <Route path='/capricorn' element={<Capricorn />} />
          <Route path='/aquarius' element={<Aquarius />} />
          <Route path='/pisces' element={<Pisces />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/luna' element={<LunaBlogger />} />
          <Route path='/blog/star' element={<StarBlogger />} />
          <Route path='/blog/maven' element={<MavenBlogger />} />
          <Route path='/blog/zara' element={<ZaraBlogger />} />

          <Route path='/blog/1' element={<Post1 />} />
          <Route path='/blog/2' element={<Post2 />} />
          <Route path="/chinese_zodiac" element={<ChineseZodiac />} />
          <Route path="/chinese_zodiac_rat" element={<ChineseZodiacRat />} />
          <Route path="/chinese_zodiac_ox" element={<ChineseZodiacOx />} />
          <Route path="/chinese_zodiac_tiger" element={<ChineseZodiacTiger />} />
          <Route path="/chinese_zodiac_rabbit" element={<ChineseZodiacRabbit />} />
          <Route path="/chinese_zodiac_dragon" element={<ChineseZodiacDragon />} />
          <Route path="/chinese_zodiac_snake" element={<ChineseZodiacSnake />} />
          <Route path="/chinese_zodiac_horse" element={<ChineseZodiacHorse />} />
          <Route path="/chinese_zodiac_goat" element={<ChineseZodiacGoat />} />
          <Route path="/chinese_zodiac_monkey" element={<ChineseZodiacMonkey />} />
          <Route path="/chinese_zodiac_rooster" element={<ChineseZodiacRooster />} />
          <Route path="/chinese_zodiac_dog" element={<ChineseZodiacDog />} />
          <Route path="/chinese_zodiac_pig" element={<ChineseZodiacPig />} />

          
          <Route path="/" element={<Home />}>
          <Route path="/info" element={<Home />} />

          
          </Route>
        </Routes>
      </BrowserRouter>
        
        


    </>
  )


}

export default App;
