import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function FourOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Four of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "four_of_pentacles",
        title: "four_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Four of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/four_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents04.jpg' alt='Four of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/three_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Three of Pentacles</Link>   
                    <Link to='/five_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Five of Pentacles</Link>
                </div>
                <h2>Overview of the Four of Pentacles</h2>

                <p>The Four of Pentacles is a card in the Minor Arcana of the Tarot deck that represents stability, security, and material possession. It signifies a period of conserving resources, maintaining control, and holding onto what one has achieved. This card encourages the querent to be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Four of Pentacles typically features a figure sitting on a bench, holding a pentacle tightly, with two pentacles under their feet and one on their head. The scene depicts a sense of control, stability, and the importance of material security. The overall imagery emphasizes themes of stability, security, and the careful management of resources.</p>

                <p>The Four of Pentacles' symbolism emphasizes themes of stability, security, and material possession. This card encourages the querent to be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Four of Pentacles appears in a reading, it signifies stability, security, and material possession. It suggests that the querent is in or will soon enter a period of conserving resources, maintaining control, and holding onto what they have achieved. The Four of Pentacles encourages the querent to be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth.</p>

                <p>In the context of personal growth, the Four of Pentacles can indicate a period of self-discovery and the development of stability and security through careful management of resources. It may suggest that the querent is learning to balance their desire for material possession with the need for flexibility and generosity. In relationships, the Four of Pentacles can signify the need to address issues of control, possessiveness, and the importance of sharing and mutual support.</p>

                <p>In career and financial matters, the Four of Pentacles can signal opportunities for growth and success through stability, security, and the careful management of resources. It advises the querent to be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth. The Four of Pentacles reminds the querent that with stability, security, and the careful management of resources, they can achieve their goals and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Four of Pentacles in the Tarot deck is a powerful symbol of stability, security, and material possession. By understanding and embracing the energy it represents, individuals can be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth. The Four of Pentacles encourages the querent to be mindful of their resources, ensure financial stability, and avoid becoming overly possessive or rigid in their approach to wealth. It is a reminder that with stability, security, and the careful management of resources, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
