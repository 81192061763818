import {React, useState} from "react"
import {Link} from 'react-router-dom';

export default function ChaldComponant(props){

    
    const number = props.chNum
    const dispLink = props.dispLink
    const[data,setData] = useState({
        planetAssoc:'',
        characteristics:'',
        strengths:'', 
        weaknesses:'',
        image:'',
        imageAltText:'' 
    })

    if(number==1)
        {
            data.planetAssoc = 'Sun'
            data.characteristics = 'Individuals with a Chaldean number of 1 are natural leaders. They are ambitious, confident, and possess strong determination.'
            data.strengths = 'Leadership, creativity, independence, and charisma.'
            data.weaknesses = 'Arrogance, stubbornness, and a tendency to dominate others.'
            data.image = 'https://d3zlw6prgce9n.cloudfront.net/Sun1.png'
            data.imageAltText = 'Cosmic Sun representing Chaldean Number 1'

        }
    if(number==2)
            {
                data.planetAssoc = 'Moon'
                data.characteristics = 'Individuals with a Chaldean number of 2 are sensitive, intuitive, and diplomatic. They are good at mediation and creating harmony.'
                data.strengths = 'Empathy, cooperation, adaptability, and patience.'
                data.weaknesses = 'Indecisiveness, over-sensitivity, and a tendency to avoid confrontation.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Moon1.png'
                data.imageAltText = 'Cosmic Moon representing Chaldean Number 2'
            }
    if(number==3)
            {
                data.planetAssoc = 'Jupiter'
                data.characteristics = 'Individuals with a Chaldean number of 3 are optimistic, social, and enjoy being in the spotlight. They are creative and have a strong sense of self-expression.'
                data.strengths = 'Enthusiasm, creativity, communication, and sociability.'
                data.weaknesses = 'Superficiality, extravagance, and a tendency to be scattered.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Jupiter1.png'
                data.imageAltText = 'Jupiter floating majestically amongst the cosmos, representing Chaldean Number 3'
            }
    if(number==4)
            {
                data.planetAssoc = 'Rahu (North Node of the Moon)'
                data.characteristics = 'Individuals with a Chaldean number of 4 are practical, hardworking, and disciplined. They value order and stability.'
                data.strengths = 'Organization, reliability, determination, and practicality.'
                data.weaknesses = 'Rigidity, stubbornness, and a tendency to be overly cautious.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Moon2.png'
                data.imageAltText = 'The moon, Rahu being the North Node, representing Chaldean Number 4'
            }
    if(number==5)
            {
                data.planetAssoc = 'Mercury'
                data.characteristics = 'Individuals with a Chaldean number of 5 are adaptable, curious, and versatile. They enjoy change and variety in their lives.'
                data.strengths = 'Flexibility, communication skills, curiosity, and resourcefulness.'
                data.weaknesses = 'Restlessness, inconsistency, and a tendency to be easily distracted.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Mercury1.png'
                data.imageAltText = 'Mercury in the cosmos, representing Chaldean Number 5'
            }
   if(number==6)
            {
                data.planetAssoc = 'Venus'
                data.characteristics = 'Individuals with a Chaldean number of 6 are charming, nurturing, and enjoy beauty and harmony. They have a strong sense of responsibility towards their loved ones.'
                data.strengths = 'Strengths: Compassion, creativity, responsibility, and a sense of balance.'
                data.weaknesses = 'Weaknesses: Over-protectiveness, possessiveness, and a tendency to be overly self-sacrificing.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Venus.png'
                data.imageAltText = 'Venus floating amongst the stars, representing Chaldean Number 6'
            }
    if(number==7)
            {
                data.planetAssoc = 'Ketu (South Node of the Moon)'
                data.characteristics = 'Individuals with a Chaldean number of 7 are introspective, spiritual, and analytical. They seek deeper truths and are often drawn to mysticism.'
                data.strengths = 'Intuition, analytical thinking, spirituality, and wisdom.'
                data.weaknesses = 'Isolation, skepticism, and a tendency to be overly critical.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Moon3.png'
                data.imageAltText = 'The moon in space amongst the stars, representing Ketu (the south node of the moon), associated with Chaldean Number 7'
            }
    if(number==8)
            {
                data.planetAssoc = 'Saturn'
                data.characteristics = 'Individuals with a Chaldean number of 8 are ambitious, disciplined, and have a strong sense of duty. They are determined to achieve their goals.'
                data.strengths = 'Persistence, discipline, practicality, and ambition.'
                data.weaknesses = 'Rigidity, pessimism, and a tendency to be overly serious.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Saturn1.png'
                data.imageAltText = 'Stunning Saturn, representing Chaldean Number 8'
            }
    if(number==9)
            {
                data.planetAssoc = 'Mars'
                data.characteristics = 'Individuals with a Chaldean number of 9 are passionate, courageous, and determined. They have a strong drive to achieve their desires.'
                data.strengths = 'Bravery, determination, energy, and humanitarianism.'
                data.weaknesses = 'Impulsiveness, aggression, and a tendency to be overly competitive.'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/Mars.png'
                data.imageAltText = 'Mercury in the cosmos, representing Chaldean Number 5'
            }




return(

    <>
        <div style={{display:'flex', flexDirection:'column', alignContent:'center', backgroundColor:'rgb(46,45,41)', width:'95%', maxWidth:'800px', margin:'auto', marginBottom:'25px', borderRadius:'20px', borderColor:'#646cff', borderWidth:'3px', borderStyle:'solid'}}>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{display:'flex', flex:'2', flexDirection:'column', margin:'auto', justifyContent:'center', textAlign:'center', marginTop:'0px'}}>
            <div>
                <h1>
                    Chaldean Number {number}
                </h1>
            </div>
            <div >
                <h2>
                   Planet: {data.planetAssoc}
                </h2>
            </div>
            
            
        
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{maxWidth:'600px'}}>


            </div>
            </div>
            </div>
            <div style={{display:'flex', flex:1, paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <img src={data.image} alt={data.imageAltText} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}}></img>
            </div>
            
        </div> 
        <div style={{margin:'auto'}}>
            {dispLink ? <Link to={`/life_path_${number}`}> <h3>Learn More</h3></Link>:null}
        </div>
        </div>



    </>

    // <>
    //     <div style={{display:'flex', flexDirection:'column', alignContent:'center', backgroundColor:'rgb(46,45,41)', width:'95%', maxWidth:'800px', margin:'auto', marginBottom:'25px', borderRadius:'20px', borderColor:'#646cff', borderWidth:'3px', borderStyle:'solid'}}>
    //     <div style={{display:'flex', flexDirection:'row'}}>
    //     <div style={{display:'flex', flex:'2', flexDirection:'column', margin:'auto', justifyContent:'center', textAlign:'center', marginTop:'0px'}}>
    //         <h1>
    //         Chaldean Number {number}:
    //         </h1>
        
    //     <div style={{display:'flex', flexDirection:'row'}}>
    //         <div style={{maxWidth:'600px'}}>
    //             <div style={{display:'flex', flexDirection:'row', flex:'1', marginLeft:'25px', marginBottom:'20px', fontSize:'12pt' }}>
    //                 <div style={{display:'flex', flex:1, fontWeight:'bold', marginRight:'10px', alignItems:'left', textAlign:'left'}}>
    //                     Planetary Association:
    //                 </div>
    //                 <div style={{display:'flex', flex:3, alignItems:'center', textAlign:'left'}} >
    //                     {data.planetAssoc}
    //                 </div>
    //             </div>

    //             <div style={{display:'flex', flexDirection:'row', flex:'1', marginLeft:'25px', marginBottom:'20px', fontSize:'12pt', alignContent:'center', textAlign:'left'}}>
    //                 <div style={{display:'flex', flex:1, margin:'auto', fontWeight:'bold', marginRight:'10px', alignItems:'center', }}>
    //                     Characteristics: 
    //                 </div>
    //                 <div style={{display:'flex', flex:3, alignItems:'center', textAlign:'left'}} >
    //                     {data.characteristics}
    //                 </div>
    //             </div>

    //             <div style={{display:'flex', flexDirection:'row', flex:'1', marginLeft:'25px', marginBottom:'20px', fontSize:'12pt' }}>
    //                 <div style={{display:'flex', flex:1, margin:'auto', fontWeight:'bold', marginRight:'10px', alignItems:'center', textAlign:'left'}}>
    //                     Strengths: 
    //                 </div>
    //                 <div style={{display:'flex', flex:3, alignItems:'center', textAlign:'left'}} >
    //                     {data.strengths}
    //                 </div>
    //             </div>
    //             <div style={{display:'flex', flexDirection:'row', flex:'1', marginLeft:'25px', marginBottom:'20px', fontSize:'12pt' }}>
    //                 <div style={{display:'flex', flex:1, margin:'auto', fontWeight:'bold', marginRight:'10px', alignItems:'center', textAlign:'left'}}>
    //                     Weaknesses: 
    //                 </div>
    //                 <div style={{display:'flex', flex:3, alignItems:'center', textAlign:'left'}} >
    //                     {data.weaknesses}
    //                 </div>
    //             </div>
    //         </div>
    //         </div>
    //         </div>
    //         <div style={{display:'flex', flex:1, paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
    //             <img src={data.image} alt={data.imageAltText} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}}></img>
    //         </div>
            
    //     </div> 
    //     <div style={{margin:'auto'}}>
    //         {dispLink ? <Link to={`/chaldean_number/${number}`}> Learn More </Link>:null}
    //     </div>
    //     </div>



    // </>
    )

}