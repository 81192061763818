import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function KnightOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Knight of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "knight_of_cups",
        title: "knight_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Knight of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/knight_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups12.jpg' alt='Knight of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/page_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Page of Cups</Link>   
                    <Link to='/queen_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Queen of Cups</Link>
                </div>
                <h2>Overview of the Knight of Cups</h2>

                <p>The Knight of Cups is a card in the Minor Arcana of the Tarot deck that represents romance, charm, and emotional pursuit. It signifies a period of active engagement with one's emotions, creativity, and romantic inclinations. This card encourages the querent to follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Knight of Cups typically features a knight riding a white horse, holding a cup outstretched in front of him. The knight's attire and the gentle, flowing landscape in the background suggest a sense of romanticism, charm, and emotional engagement. The overall imagery emphasizes themes of romance, charm, and the active pursuit of emotional and creative goals.</p>

                <p>The Knight of Cups' symbolism emphasizes themes of romance, charm, and emotional pursuit. This card encourages the querent to follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Knight of Cups appears in a reading, it signifies romance, charm, and emotional pursuit. It suggests that the querent is in or will soon enter a period of active engagement with their emotions, creativity, and romantic inclinations. The Knight of Cups encourages the querent to follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence.</p>

                <p>In the context of personal growth, the Knight of Cups can indicate a period of emotional exploration and the pursuit of creative and romantic goals. It may suggest that the querent is actively engaging with their emotions and creativity, and is learning to express their feelings and passions. In relationships, the Knight of Cups can signify the arrival of a charming and romantic individual, the deepening of emotional connections, or the pursuit of romantic goals.</p>

                <p>In career and financial matters, the Knight of Cups can signal opportunities for creative expression and the pursuit of emotionally fulfilling goals. It advises the querent to follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence. The Knight of Cups reminds the querent that with romance, charm, and emotional pursuit, they can achieve their goals and find joy and fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Knight of Cups in the Tarot deck is a powerful symbol of romance, charm, and emotional pursuit. By understanding and embracing the energy it represents, individuals can follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence. The Knight of Cups encourages the querent to follow their heart, pursue their passions, and engage in creative and romantic endeavors with enthusiasm and confidence. It is a reminder that with romance, charm, and emotional pursuit, we can achieve our goals and find joy and fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
