import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function WorldTarot() {

    useEffect(() => {
        document.title = 'Tarot | The World Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "world_tarot",
        title: "world_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The World</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/world_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/21_World.jpg' alt='World Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The World</h2>

                <p>The World is the twenty-first and final card in the Major Arcana of the Tarot deck, numbered as 21. It represents completion, fulfillment, and the attainment of goals. The World is a symbol of wholeness, achievement, and the culmination of a journey. This card encourages the querent to celebrate their successes, recognize their achievements, and embrace the sense of fulfillment and completion that comes with reaching their goals.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the World card typically features a figure dancing within a large wreath, symbolizing completion and wholeness. The figure often holds two wands, representing balance and the integration of opposites. Surrounding the wreath are the four fixed signs of the zodiac (Taurus, Leo, Scorpio, and Aquarius), symbolizing stability and the interconnectedness of all things. The overall imagery emphasizes themes of completion, fulfillment, and the attainment of goals.</p>

                <p>The World's number, 21, signifies completion, fulfillment, and the culmination of a journey. This card's symbolism encourages the querent to celebrate their successes, recognize their achievements, and embrace the sense of fulfillment and completion that comes with reaching their goals.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the World card appears in a reading, it signifies completion, fulfillment, and the attainment of goals. It suggests that the querent has reached a significant milestone and is experiencing a sense of wholeness and achievement. The World card encourages the querent to celebrate their successes, recognize their achievements, and embrace the sense of fulfillment and completion that comes with reaching their goals.</p>

                <p>In the context of personal growth, the World card can indicate a period of self-realization and the attainment of inner harmony. It may suggest that the querent is experiencing a deep sense of fulfillment and is able to see the interconnectedness of their journey. In relationships, the World card can signify the completion of a significant phase, the achievement of mutual goals, and the sense of wholeness that comes with a fulfilling partnership.</p>

                <p>In career and financial matters, the World card can signal opportunities for success and the achievement of long-term goals. It advises the querent to celebrate their achievements, recognize their successes, and embrace the sense of fulfillment that comes with reaching their goals. The World card reminds the querent that with dedication, perseverance, and the recognition of their achievements, they can experience the wholeness and fulfillment that comes with reaching their goals.</p>

                <h2>Conclusion</h2>

                <p>The World card in the Tarot deck is a powerful symbol of completion, fulfillment, and the attainment of goals. By understanding and embracing the energy it represents, individuals can celebrate their successes, recognize their achievements, and embrace the sense of fulfillment and completion that comes with reaching their goals. The World card encourages the querent to celebrate their achievements, recognize their successes, and embrace the sense of fulfillment that comes with reaching their goals. It is a reminder that with dedication, perseverance, and the recognition of our achievements, we can experience the wholeness and fulfillment that comes with reaching our goals.</p>

                <Footer />
            </div>
        </>
    )
}
