import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function JudgmentTarot() {

    useEffect(() => {
        document.title = 'Tarot | Judgement Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "judgment_tarot",
        title: "judgment_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Judgment</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/judgment_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/20_Judgement.jpg' alt='Judgment Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of Judgment</h2>

                <p>Judgment is the twentieth card in the Major Arcana of the Tarot deck, numbered as 20. It represents rebirth, renewal, and the call to a higher purpose. The Judgment card is a symbol of awakening, self-evaluation, and transformation. This card encourages the querent to reflect on their life, heed the call to a higher purpose, and embrace the opportunity for a fresh start and new beginnings.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Judgment card typically features an angel blowing a trumpet, calling forth the dead from their graves. The figures rising from the graves symbolize rebirth and awakening. The angel, often depicted as Archangel Gabriel, represents divine intervention and the call to a higher purpose. The overall imagery emphasizes themes of renewal, self-evaluation, and the awakening to a new phase of life.</p>

                <p>Judgment's number, 20, signifies transformation, renewal, and the call to a higher purpose. This card's symbolism encourages the querent to reflect on their life, heed the call to a higher purpose, and embrace the opportunity for a fresh start and new beginnings.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Judgment card appears in a reading, it signifies rebirth, renewal, and the call to a higher purpose. It suggests that the querent is undergoing a period of self-evaluation and transformation, where they are being called to awaken to a new phase of life. The Judgment card encourages the querent to reflect on their past actions, learn from their experiences, and embrace the opportunity for a fresh start and new beginnings.</p>

                <p>In the context of personal growth, the Judgment card can indicate a period of self-reflection and awakening. It may suggest that the querent is becoming aware of their higher purpose and is ready to embrace a new phase of life. In relationships, the Judgment card can signify the renewal of connections, the resolution of past issues, and the call to a higher level of understanding and commitment.</p>

                <p>In career and financial matters, the Judgment card can signal opportunities for transformation and new beginnings. It advises the querent to reflect on their past actions, learn from their experiences, and embrace the opportunity for a fresh start and new ventures. The Judgment card reminds the querent that with self-evaluation, renewal, and the call to a higher purpose, they can achieve their goals and embark on a new and fulfilling path.</p>

                <h2>Conclusion</h2>

                <p>The Judgment card in the Tarot deck is a powerful symbol of rebirth, renewal, and the call to a higher purpose. By understanding and embracing the energy it represents, individuals can reflect on their life, heed the call to a higher purpose, and embrace the opportunity for a fresh start and new beginnings. The Judgment card encourages the querent to reflect on their past actions, learn from their experiences, and embrace the opportunity for a fresh start and new ventures. It is a reminder that with self-evaluation, renewal, and the call to a higher purpose, we can achieve our goals and embark on a new and fulfilling path.</p>

                <Footer />
            </div>
        </>
    )
}
