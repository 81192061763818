import {useNavigate, Link} from 'react-router-dom';

export default function Footer(){

    return (
    <>
    <footer className="footer" style={{backgroundColor:'rgb(33,32,30)', marginTop:'100px'}}>
    
    <Link to='/numerology' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Numerology</Link>
    <Link to='/zodiac' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Zodiac</Link>
    <Link to='/tarot' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Tarot</Link> 
    <br />
    <Link to='/chinese_zodiac' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Chinese Zodiac</Link>
    <Link to='/about' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>About</Link>
    <Link to='/contact' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Contact</Link>
    <br />
    <Link to='/privacy_policy' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Privacy Policy</Link>
    <Link to='/faq' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>FAQ</Link>
    <Link to='/blog' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Blog</Link>

                <p>&copy; 2024 Cosmic-Secrets.com  -  All rights reserved.</p>
    </footer>
    
    </>)
}
