import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacDragon() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Dragon | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_dragon",
        title: "chinese_zodiac_dragon",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Dragon</h1>
                    <Link to='/chinese_zodiac_dragon' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacDragon.png' alt='A dragon in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rabbit' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rabbit</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_snake' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Snake</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Dragon</h2>
                    <p>The Dragon is the fifth animal in the Chinese Zodiac cycle. Those born under this sign are known for their ambition, energy, and charisma. Dragons are dynamic and powerful leaders, capable of inspiring and motivating others. They have a magnetic personality and are often seen as visionaries. However, their strong-willed nature can sometimes make them overbearing and prone to arrogance.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Dragon</h2>
                    <p>The Dragon is the fifth sign in the Chinese Zodiac, and individuals born under this sign are known for their confidence, charisma, and ambition. Dragons are natural leaders who possess a strong sense of self and a desire to achieve greatness. Their dynamic and energetic personality often inspires others and draws people to them.</p>

                    <p>Dragons are also known for their intelligence and creativity. They are innovative thinkers who are capable of coming up with bold ideas and solutions. Their visionary mindset allows them to see the bigger picture and pursue their goals with determination. However, their high expectations and perfectionist tendencies can sometimes lead to frustration and impatience.</p>

                    <p>Despite their strong exterior, Dragons have a compassionate and generous side. They care deeply about their loved ones and are always willing to offer support and protection. Their loyalty and dedication make them reliable friends and partners. However, their dominant nature can sometimes make them appear controlling or overbearing.</p>

                    <p>Dragons are also known for their adventurous spirit and love of challenges. They thrive in dynamic environments and are always seeking new experiences and opportunities. Their fearless approach to life often leads them to take risks and explore uncharted territories. However, it is important for Dragons to balance their ambition with mindfulness and self-care to avoid burnout and maintain their well-being.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Dragon</h2>
                <p>In their careers, Dragons excel in roles that require leadership, innovation, and vision. They are often successful in fields such as entrepreneurship, technology, and entertainment, where their dynamic and charismatic nature can shine. Dragons are natural leaders who inspire others with their confidence and ambition. Their ability to see the bigger picture and pursue bold ideas makes them valuable assets in any professional environment.</p>

                <p>Dragons are known for their innovative thinking and ability to turn ideas into reality. They thrive in environments that encourage creativity and allow them to take risks. Their confidence and determination often lead to groundbreaking achievements and significant contributions to their fields. However, their high expectations and perfectionist tendencies can sometimes lead to stress and frustration. It's important for Dragons to practice self-care and find a balance between their professional and personal lives.</p>

                <p>When it comes to compatibility, Dragons are most compatible with the Rooster, Monkey, and Rat. These signs complement the Dragon's traits and help to balance their ambitious nature. The Rooster offers diligence and reliability, the Monkey provides quick wit and resourcefulness, and the Rat shares the Dragon's intelligence and adaptability. Dragons may experience challenges in relationships with the Dog and Rabbit, as these signs may clash with the Dragon's personality and create conflict. To maintain harmony, Dragons need to practice patience and understanding in their relationships.</p>
                 </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Dragon</h2>
                <p>Dragons are known for their vitality and robust health. Their energetic and dynamic nature helps them stay active and maintain good physical fitness. However, their ambitious and driven personality can sometimes lead to stress and overexertion. Dragons need to ensure they balance their work with relaxation and self-care to avoid burnout. Regular exercise, a balanced diet, and adequate sleep are essential for maintaining their health and energy levels.</p>

                <p>Dragons are prone to high blood pressure and stress-related issues due to their intense nature. It's important for them to find effective ways to manage stress, such as through meditation, mindfulness practices, or engaging in hobbies they enjoy. Spending time in nature and practicing deep breathing exercises can also be particularly beneficial for Dragons, helping them to relax and recharge.</p>

                <p>In terms of lifestyle, Dragons enjoy excitement and novelty. They are often drawn to activities that challenge them both physically and mentally, such as travel, adventure sports, or exploring new hobbies. Dragons also value their independence and enjoy having the freedom to pursue their interests. However, it's important for them to maintain a healthy balance and avoid overextending themselves.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rabbit' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rabbit</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_snake' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Snake</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
