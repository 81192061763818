import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Cancer(){

    useEffect(() => {
        document.title = 'Zodiac | Cancer | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/cancer",
        title:"cancer",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Cancer: The Crab (June 21 - July 22)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/cancer1.png' alt='Cancer the Crab' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/gemini' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Gemini</Link>   
                    Next:      <Link to='/leo' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Leo</Link>
                </div>
                <p>Cancer is the fourth sign of the zodiac, symbolizing home, family, and emotional depth. Represented by the Crab, Cancer is ruled by the moon, which governs emotions, intuition, and maternal instincts. People born under this sign are known for their nurturing nature, sensitivity, and strong connection to their loved ones.</p>

                <h2>Personality Traits</h2>
                <p>Cancer individuals are characterized by their empathetic and compassionate nature. They are deeply intuitive and often possess a strong sense of empathy, making them excellent listeners and caregivers. Cancers are known for their loyalty and dedication to family and friends, often putting others' needs before their own.</p>
                <p>Despite their caring and gentle exterior, Cancers have a tough inner core, much like the hard shell of a crab. They can be protective and cautious, especially when it comes to their emotional well-being. While they are usually reserved and introspective, they can be fiercely protective of their loved ones and will go to great lengths to ensure their safety and happiness.</p>

                <h2>Career</h2>
                <p>In the professional realm, Cancer excels in roles that involve caregiving, support, and nurturing. They are well-suited for careers in healthcare, counseling, social work, and any field that allows them to care for others. Cancers' strong intuition and emotional intelligence make them valuable assets in any team.</p>
                <p>Cancer employees are known for their dedication and reliability. They are often the ones who create a supportive and harmonious work environment, fostering collaboration and teamwork. However, they may need to work on setting boundaries and managing stress, as their caring nature can sometimes lead to emotional exhaustion.</p>

                <h2>Relationships</h2>
                <p>In relationships, Cancer is loving and devoted. They seek partners who can provide emotional security and a deep, meaningful connection. Cancer values loyalty and trust, and they appreciate partners who are understanding and compassionate. They are drawn to individuals who can create a stable and nurturing home environment.</p>
                <p>As friends, Cancers are supportive and dependable. They are always there to offer a listening ear and a shoulder to lean on. In romantic relationships, they are affectionate and attentive, often going out of their way to make their partners feel cherished and appreciated. However, their sensitivity can sometimes lead to mood swings, so open communication and understanding are key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Cancer is a deeply emotional and caring sign. Their nurturing instincts, loyalty, and intuitive nature make them wonderful friends and partners. By embracing their strengths and working on their challenges, Cancer individuals can create a life filled with love, security, and emotional fulfillment.</p>
            </div>


            <Footer />
            </>
    )}