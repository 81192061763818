import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function LoversTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Lovers Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "lovers_tarot",
        title: "lovers_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Lovers</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/lovers_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/06_Lovers.jpg' alt='Lovers Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of the Lovers</h2>

                <p>The Lovers is the seventh card in the Major Arcana of the Tarot deck, numbered as 6. It represents love, harmony, and the power of choice. The Lovers is a symbol of deep emotional connections, partnerships, and the union of opposites. This card encourages the querent to seek harmony in their relationships, make decisions from the heart, and embrace the transformative power of love.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Lovers card typically features a man and a woman standing beneath an angel, often depicted as Archangel Raphael, who represents healing and divine love. Behind the figures, there is a lush garden reminiscent of the Garden of Eden, symbolizing purity and the innocence of love. The sun shines brightly above, illuminating the scene and symbolizing clarity, warmth, and enlightenment.</p>

                <p>The Lovers' number, 6, signifies harmony, balance, and the importance of making choices that align with one's values and higher purpose. This card's symbolism encourages the querent to seek deep and meaningful connections, make conscious choices, and embrace the power of love in all its forms.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Lovers card appears in a reading, it signifies the importance of love, relationships, and harmonious connections. It suggests that the querent is experiencing or seeking a deep emotional bond with another person. The Lovers card encourages the querent to make choices that honor their values and to seek relationships that are based on mutual respect, trust, and love.</p>

                <p>In the context of personal growth, the Lovers card can indicate a period of self-discovery and the integration of different aspects of oneself. It may suggest that the querent is learning to love and accept themselves, and is finding balance and harmony within. In relationships, the Lovers card can signify a deep and meaningful connection, the start of a new romance, or the strengthening of an existing partnership.</p>

                <p>In career and financial matters, the Lovers card can signal opportunities for collaboration, partnership, and making choices that align with one's values. It advises the querent to seek harmony in their work relationships, make decisions that are true to their heart, and embrace opportunities that bring fulfillment and satisfaction. The Lovers card reminds the querent that love, harmony, and conscious choices are essential for creating a balanced and fulfilling life.</p>

                <h2>Conclusion</h2>

                <p>The Lovers card in the Tarot deck is a powerful symbol of love, harmony, and the power of choice. By understanding and embracing the energy it represents, individuals can seek deep and meaningful connections, make conscious decisions, and embrace the transformative power of love. The Lovers card encourages the querent to honor their values, seek harmony in their relationships, and make choices that align with their higher purpose. It is a reminder that love is a powerful force that can bring balance, fulfillment, and joy into one's life.</p>

                <Footer />
            </div>
        </>
    )
}
