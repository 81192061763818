import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function StrengthTarot() {

    useEffect(() => {
        document.title = 'Tarot | Strength Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "strength_tarot",
        title: "strength_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Strength</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/strength_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/08_Strength.jpg' alt='Strength Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of Strength</h2>

                <p>Strength is the ninth card in the Major Arcana of the Tarot deck, numbered as 8. It represents inner strength, courage, and the power of compassion. The Strength card is a symbol of resilience, patience, and the ability to overcome challenges through calm determination and gentle influence. This card encourages the querent to harness their inner strength, face their fears with courage, and approach life's challenges with compassion and understanding.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Strength card typically features a woman gently holding open the jaws of a lion. The woman represents grace, calmness, and inner strength, while the lion symbolizes raw emotion, passion, and instinct. The infinity symbol above the woman's head signifies infinite potential and the balance between the physical and spiritual realms. In the background, there is often a serene landscape, symbolizing peace and harmony.</p>

                <p>Strength's number, 8, signifies balance, power, and the cyclical nature of life. This card's symbolism encourages the querent to harness their inner strength, maintain balance, and approach life's challenges with calm determination and gentle influence.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Strength card appears in a reading, it signifies the importance of inner strength, courage, and compassion. It suggests that the querent has the resilience and patience to overcome challenges and face their fears. The Strength card encourages the querent to harness their inner power, approach situations with calm determination, and use gentle influence to achieve their goals.</p>

                <p>In the context of personal growth, the Strength card can indicate a period of self-empowerment and the development of inner resilience. It may suggest that the querent is learning to face their fears and challenges with courage and compassion. In relationships, the Strength card can signify the importance of patience, understanding, and the gentle influence needed to maintain harmony and balance.</p>

                <p>In career and financial matters, the Strength card can signal opportunities for growth and success through resilience and determination. It advises the querent to stay focused on their goals, approach challenges with calm determination, and use their inner strength to overcome obstacles. The Strength card reminds the querent that with courage, compassion, and resilience, they can achieve their ambitions and maintain balance in their life.</p>

                <h2>Conclusion</h2>

                <p>The Strength card in the Tarot deck is a powerful symbol of inner strength, courage, and the power of compassion. By understanding and embracing the energy it represents, individuals can harness their inner power, face their fears with courage, and approach life's challenges with calm determination and gentle influence. The Strength card encourages the querent to maintain balance, use their inner strength to overcome obstacles, and achieve their goals with resilience and patience. It is a reminder that true strength comes from within and that with courage and compassion, anything is possible.</p>

                <Footer />
            </div>
        </>
    )
}
