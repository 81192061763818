import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TenOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Ten of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "ten_of_swords",
        title: "ten_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Ten of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/ten_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords10.jpg' alt='Ten of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/nine_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Nine of Swords</Link>   
                    <Link to='/page_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Page of Swords</Link>
                </div>
                <h2>Overview of the Ten of Swords</h2>

                <p>The Ten of Swords is a card in the Minor Arcana of the Tarot deck that represents endings, betrayal, and rock bottom. It signifies a period of complete and painful endings, where the querent feels defeated and betrayed. This card encourages the querent to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Ten of Swords typically features a figure lying face down with ten swords piercing their back, under a dark sky. The scene depicts a sense of finality, betrayal, and the lowest point of a difficult journey. The overall imagery emphasizes themes of painful endings, defeat, and the inevitability of change.</p>

                <p>The Ten of Swords' symbolism emphasizes themes of endings, betrayal, and rock bottom. This card encourages the querent to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Ten of Swords appears in a reading, it signifies endings, betrayal, and rock bottom. It suggests that the querent is in or will soon enter a period of complete and painful endings, where they feel defeated and betrayed. The Ten of Swords encourages the querent to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery.</p>

                <p>In the context of personal growth, the Ten of Swords can indicate a period of self-discovery and the development of resilience through painful endings. It may suggest that the querent is learning to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery. In relationships, the Ten of Swords can signify the need for closure, healing from betrayal, and the importance of moving forward from difficult endings.</p>

                <p>In career and financial matters, the Ten of Swords can signal challenges and setbacks that may lead to endings and feelings of defeat. It advises the querent to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery. The Ten of Swords reminds the querent that with endings, betrayal, and rock bottom, they can still find a way to recover and achieve their goals.</p>

                <h2>Conclusion</h2>

                <p>The Ten of Swords in the Tarot deck is a powerful symbol of endings, betrayal, and rock bottom. By understanding and embracing the energy it represents, individuals can acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery. The Ten of Swords encourages the querent to acknowledge the end of a cycle, learn from their experiences, and prepare for new beginnings and recovery. It is a reminder that with endings, betrayal, and rock bottom, we can still find a way to recover and achieve our goals.</p>

                <Footer />
            </div>
        </>
    )
}
