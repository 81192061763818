import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import PythagComponant from "../../componants/PythagComponant";
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function LifePath3() {

  useEffect(() => {
    document.title = 'Pythagorean Numerology | Life Path Number 3 | Cosmic Secrets';
}, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/life_path_3",
        title:"life_path_3",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>
      <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
        <h1>Life Path Number 3 - The Communicator</h1>
        <div style={{ margin:'auto', textAlign:'center'}}>
            <img src='https://d3zlw6prgce9n.cloudfront.net/LifePath3.png' alt='Woman in front of swirling cosmic background, representing The Communicator' style={{ width: '300px', height: '300px', borderRadius: '10px' }} />
        </div>
        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/life_path_2' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 2</Link>   
                Next:      <Link to='/life_path_4' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 4</Link>
             </div>
        <p>In Pythagorean numerology, Life Path Number 3 is known as the number of the communicator. People with this life path number are often seen as creative, expressive, and sociable individuals who bring joy and inspiration to others. They are charismatic, artistic, and have a natural ability to express themselves in various forms.</p>
        
        <h2>Personality</h2>
        <p>Individuals with Life Path Number 3 possess a vibrant and dynamic personality. They are outgoing, enthusiastic, and full of energy, often drawing people to them with their charm and wit. These individuals are natural entertainers and enjoy being the center of attention. Their creativity knows no bounds, and they often have multiple talents in areas such as writing, art, music, and performing. Their optimistic outlook on life helps them overcome challenges with ease.</p>

        <h2>Career</h2>
        <p>Career-wise, those with Life Path Number 3 excel in fields that allow them to express their creativity and connect with others. They are well-suited for careers in the arts, entertainment, media, and communication. Roles such as writers, actors, musicians, artists, and public speakers are ideal for their expressive nature. Additionally, their charisma and people skills make them effective in marketing, public relations, and social work. They thrive in environments that offer variety and opportunities for self-expression.</p>

        <h2>Relationships</h2>
        <p>In relationships, Life Path Number 3 individuals value communication and emotional connection. They are affectionate and playful partners who bring excitement and joy to their relationships. Their sociable nature means they enjoy spending time with friends and loved ones, and they often have a wide social circle. However, they may need to work on being more grounded and consistent in their relationships. Ensuring that they listen to their partner's needs and provide stability is crucial for maintaining healthy and fulfilling relationships.</p>

        <h2>Strengths</h2>
        <p>The strengths of Life Path Number 3 include their creativity, expressiveness, and charisma. They are highly talented individuals who can inspire and uplift others through their artistic endeavors and positive energy. Their ability to communicate effectively and connect with people on an emotional level makes them influential and admired. They are also adaptable and can thrive in various situations, using their creativity to find solutions and opportunities.</p>

        <h2>Weaknesses</h2>
        <p>Despite their many strengths, Life Path Number 3 individuals can also have weaknesses. Their desire for attention and excitement can sometimes lead to a lack of focus and discipline. They may struggle with consistency and follow-through, finding it difficult to stick to routines or complete long-term projects. Additionally, their sensitivity to criticism can affect their self-esteem and cause them to become overly self-conscious. It is important for them to learn to balance their creative pursuits with practicality and develop resilience in the face of challenges.</p>
        
        <h2>Conclusion</h2>
        <p>In conclusion, Life Path Number 3 is the path of the communicator and creative. Those with this life path are driven by a desire to express themselves and inspire others through their artistic talents and charismatic presence. By embracing their strengths and addressing their weaknesses, they can lead fulfilling and impactful lives, bringing joy and inspiration to those around them.</p>
        </div>


      
      </div>
      <Footer />
      </>
    )
}
