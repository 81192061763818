import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function NineOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Nine of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "nine_of_swords",
        title: "nine_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Nine of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/nine_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords09.jpg' alt='Nine of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/eight_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Eight of Swords</Link>   
                    <Link to='/ten_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Ten of Swords</Link>
                </div>
                <h2>Overview of the Nine of Swords</h2>

                <p>The Nine of Swords is a card in the Minor Arcana of the Tarot deck that represents anxiety, worry, and mental anguish. It signifies a period of intense stress, nightmares, and overwhelming thoughts that disturb the querent's peace of mind. This card encourages the querent to confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Nine of Swords typically features a figure sitting up in bed, holding their head in their hands, with nine swords hanging on the wall behind them. The scene depicts a sense of distress, sleeplessness, and mental turmoil. The overall imagery emphasizes themes of anxiety, worry, and the impact of negative thoughts on one's well-being.</p>

                <p>The Nine of Swords' symbolism emphasizes themes of anxiety, worry, and mental anguish. This card encourages the querent to confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Nine of Swords appears in a reading, it signifies anxiety, worry, and mental anguish. It suggests that the querent is in or will soon enter a period of intense stress, nightmares, and overwhelming thoughts that disturb their peace of mind. The Nine of Swords encourages the querent to confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity.</p>

                <p>In the context of personal growth, the Nine of Swords can indicate a period of self-discovery and the development of coping mechanisms for managing stress and anxiety. It may suggest that the querent is learning to recognize and address their fears, seek support, and find ways to regain their mental well-being. In relationships, the Nine of Swords can signify the need for open communication, mutual support, and the importance of addressing mental health concerns within the partnership.</p>

                <p>In career and financial matters, the Nine of Swords can signal challenges and setbacks that may cause anxiety and worry. It advises the querent to confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity. The Nine of Swords reminds the querent that with anxiety, worry, and mental anguish, they can still find a way to overcome their challenges and achieve their goals.</p>

                <h2>Conclusion</h2>

                <p>The Nine of Swords in the Tarot deck is a powerful symbol of anxiety, worry, and mental anguish. By understanding and embracing the energy it represents, individuals can confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity. The Nine of Swords encourages the querent to confront their fears, seek help if needed, and find ways to manage their anxiety and regain mental clarity. It is a reminder that with anxiety, worry, and mental anguish, we can still find a way to overcome our challenges and achieve our goals.</p>

                <Footer />
            </div>
        </>
    )
}
