import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function HangedManTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Hanged Man Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "hanged_man_tarot",
        title: "hanged_man_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Hanged Man</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/hanged_man_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/12_Hanged_Man.jpg' alt='Hanged Man Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Hanged Man</h2>

                <p>The Hanged Man is the thirteenth card in the Major Arcana of the Tarot deck, numbered as 12. It represents suspension, letting go, and seeing things from a different perspective. The Hanged Man is a symbol of surrender, patience, and self-sacrifice. This card encourages the querent to pause, reflect, and gain new insights by viewing their situation from a different angle.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Hanged Man card typically features a man hanging upside down from a tree or a wooden structure, with one leg crossed over the other and a serene expression on his face. The man's calm demeanor suggests acceptance and inner peace. The tree or structure he hangs from symbolizes the world tree or the axis mundi, connecting the heavens and the earth. The Hanged Man's halo represents enlightenment and spiritual insight gained through his suspension.</p>

                <p>The Hanged Man's number, 12, signifies cycles, completion, and the inversion of perspective. This card's symbolism encourages the querent to embrace suspension, let go of control, and seek enlightenment through new perspectives and patience.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Hanged Man card appears in a reading, it signifies the importance of letting go, pausing, and seeing things from a different perspective. It suggests that the querent is in a situation where they need to surrender control and take time to reflect. The Hanged Man card encourages the querent to embrace patience, allow things to unfold naturally, and gain new insights through introspection.</p>

                <p>In the context of personal growth, the Hanged Man card can indicate a period of self-reflection and spiritual development. It may suggest that the querent is learning to let go of old patterns, surrender to the present moment, and gain new perspectives on their life. In relationships, the Hanged Man card can signify the need for patience, understanding, and the willingness to see things from the other person's point of view.</p>

                <p>In career and financial matters, the Hanged Man card can signal a time of pause, reassessment, and the need to let go of rigid plans. It advises the querent to take a step back, reflect on their goals, and consider alternative approaches. The Hanged Man card reminds the querent that by letting go and embracing new perspectives, they can gain valuable insights and achieve greater clarity.</p>

                <h2>Conclusion</h2>

                <p>The Hanged Man card in the Tarot deck is a powerful symbol of suspension, letting go, and seeing things from a different perspective. By understanding and embracing the energy it represents, individuals can pause, reflect, and gain new insights through patience and surrender. The Hanged Man card encourages the querent to let go of control, embrace new perspectives, and seek enlightenment through introspection and self-reflection. It is a reminder that by surrendering to the present moment and viewing situations from different angles, we can gain valuable insights and achieve greater clarity.</p>

                <Footer />
            </div>
        </>
    )
}
