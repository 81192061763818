import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function Contact(){

    useEffect(() => {
        document.title = 'Contact Us | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/contact",
        title:"contact",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />
    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
        <h1>Contact Us</h1>
        <br />
        <p>We would love to hear from you! If you have questions, feedback, or suggestions, feel free to reach out to us. Your input helps us improve and provide the best possible experience for our community.</p>
        <br />

        <p>You can contact us via email at <a href="mailto:cosmic.secrets.info@gmail.com">cosmic.secrets.info@gmail.com</a>. We strive to respond to all inquiries within 24-48 hours.</p>

        <p>Thank you for being a part of Cosmic Secrets. We look forward to connecting with you!</p>
        <br />
        <br />

    </div>

    
    <Footer />
    </>)}

