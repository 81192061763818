import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Tarot(){

    
    useEffect(() => {
        document.title = 'Tarot | Tarot Reading And Tarot Cards | Cosmic Secrets';
    }, []);
      

    ReactGA.send({
        hitType:"pageview",
        page:"/tarot",
        title:"tarot",
    })

    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);


    return(
        <>
        <Navbar />
        <div ref={topContainer} />
        <div>
        <div style={{maxWidth:'900px', margin:'auto'}}>
            <h1>What is Tarot</h1>
            <p>Tarot is a powerful tool for divination, self-discovery, and spiritual guidance. It consists of a deck of 78 cards, each with its own unique imagery, symbolism, and meaning. The tarot deck is divided into two main sections: the <Link to='/major_arcana'>Major Arcana</Link> and the <Link to='/minor_arcana'>Minor Arcana</Link>. The Major Arcana consists of 22 cards that represent significant life events and spiritual lessons, while the Minor Arcana consists of 56 cards that focus on everyday issues and experiences. Together, these cards provide a comprehensive overview of the human experience and the journey of the soul.</p>

            <p>The origins of tarot are shrouded in mystery, however, the tarot as we know it today emerged in medieval Europe, where it was initially used as a card game before evolving into a tool for divination and esoteric knowledge. Each tarot card is rich in symbolism, drawing from a wide range of cultural, religious, and spiritual traditions. The imagery on the cards often depicts archetypal figures, universal themes, and profound spiritual insights that resonate deeply with the human psyche.</p>

            <p>Using tarot involves drawing cards from the deck and interpreting their meanings in the context of a specific question or situation. Tarot readings can provide clarity, guidance, and insight into various aspects of life, including relationships, career, health, and personal growth. The cards serve as a mirror to the subconscious mind, revealing hidden truths and offering new perspectives. Whether used for self-reflection, decision-making, or spiritual exploration, tarot is a versatile and transformative tool that can help individuals navigate life's challenges and discover their true path.</p>

            <p>One of the most compelling aspects of tarot is its ability to connect with the intuition and inner wisdom of both the reader and the querent. By tapping into the intuitive mind, tarot can unlock deeper levels of understanding and awareness. Each reading is a unique and personal experience, offering a blend of ancient wisdom and contemporary insight. As you delve into the world of tarot, you'll find a rich tapestry of symbolism, mysticism, and spiritual guidance that can illuminate your journey and inspire personal growth.</p>
        
            <h2>Origins and History of Tarot</h2>
            
            <p>The origins of Tarot are steeped in mystery and intrigue, with various theories suggesting that it began in ancient Egypt, China, or India. Some historians believe that Tarot originated in ancient Egypt, where it was used as a tool for esoteric knowledge and mystical practices. Others propose that Tarot has connections to ancient Chinese or Indian divination methods. Despite these theories, the earliest known Tarot cards date back to medieval Europe, specifically to 15th-century Italy, where they were initially used for playing a card game called "tarocchi."</p>

            <p>During the Renaissance period, Tarot cards began to be associated with mysticism and the occult. This shift in perception was largely influenced by the Hermetic Order of the Golden Dawn, a secret society dedicated to the study and practice of the occult, metaphysics, and paranormal activities. Members of this society viewed the Tarot as a powerful tool for spiritual development and divination. They believed that the images on the cards held deep symbolic meanings that could be used to unlock the secrets of the universe and provide insights into the human psyche.</p>

            <p>By the 18th century, Tarot had evolved into a tool for fortune-telling and personal insight. This period saw the publication of several influential works on Tarot, including Jean-Baptiste Alliette's "Etteilla" and Antoine Court de Gébelin's "Le Monde Primitif." These texts popularized the idea that Tarot cards were more than just a game, but rather a means of accessing hidden knowledge and divine guidance. The symbolic imagery on the cards was seen as a reflection of universal archetypes and spiritual truths, resonating with the collective unconscious.</p>

            <p>The 20th century brought further developments in the world of Tarot, with the creation of the Rider-Waite-Smith deck by Arthur Edward Waite and artist Pamela Colman Smith in 1909. This deck, with its detailed and evocative illustrations, became one of the most widely used and recognized Tarot decks in the world. It set the standard for modern Tarot readings and inspired numerous other decks that followed. Today, Tarot continues to be a popular tool for divination, self-discovery, and spiritual exploration, offering insights and guidance to millions of people around the globe.</p>

            <h2>How Tarot Works and Types of Cards</h2>
 
            <p>Tarot works by tapping into the subconscious mind and the collective unconscious to provide insights and guidance on various aspects of life. A Tarot reading involves drawing cards from a deck and interpreting their meanings in the context of a specific question or situation. The process of shuffling and drawing the cards is believed to align with the energies of the querent (the person asking the question) and the reader, creating a connection that allows for intuitive and insightful interpretations. Each card in the Tarot deck has a unique symbolism and meaning, which, when combined with the reader's intuition, can offer profound revelations.</p>

            <p>The Tarot deck consists of 78 cards, divided into two main sections: the <Link to='/major_arcana'>Major Arcana</Link> and the <Link to='/minor_arcana'>Minor Arcana</Link>. The Major Arcana contains 22 cards, each representing significant life events, spiritual lessons, and major influences in a person's life. These cards include well-known figures such as <Link to='/fool_tarot'>The Fool</Link>, <Link to='/magician_tarot'>The Magician</Link>, The <Link to='/high_priestess_tarot'>High Priestess</Link>, and <Link to='/world_tarot'>The World</Link>. Each Major Arcana card carries deep symbolic meaning and often signifies a pivotal moment or important lesson in the querent's journey.</p>

            <p>The Minor Arcana is comprised of 56 cards that are divided into four suits: <Link to='/wands'>Wands</Link>, <Link to='/cups'>Cups</Link>, <Link to='/swords'>Swords</Link>, and <Link to='/pentacles'>Pentacles</Link>. Each suit represents different aspects of life and human experience. Wands are associated with creativity, passion, and action; Cups represent emotions, relationships, and intuition; Swords symbolize intellect, conflict, and decision-making; and Pentacles pertain to material aspects, such as finances, work, and physical health. Each suit contains ten numbered cards (Ace to Ten) and four Court Cards: Page, Knight, Queen, and King. The Minor Arcana cards provide insights into everyday events and situations, complementing the broader themes presented by the Major Arcana.</p>

            <h2>The Tarot Reading</h2>

            <p>A tarot reading is a ritual that involves drawing cards from a tarot deck and interpreting their meanings to gain insights into a specific question or situation. The process begins with the querent, the person seeking guidance, focusing on a question or issue they would like to explore. This focus helps to channel the querent's energy into the cards, creating a connection between their subconscious mind and the symbolism of the tarot.</p>

            <p>The next step is shuffling the tarot deck. The querent or the tarot reader shuffles the cards thoroughly to ensure that the deck is randomized and the cards are infused with the querent's energy. Some readers may also cut the deck or ask the querent to do so, further personalizing the reading. Once the deck is shuffled, the reader draws a specific number of cards and arranges them in a predetermined spread or layout. The spread chosen depends on the nature of the question and the depth of insight desired.</p>

            <p>Each card drawn is placed in a specific position within the spread, and each position represents a different aspect of the querent's question or situation. For example, in a three-card spread, the first card might represent the past, the second card the present, and the third card the future. In a more complex spread, like the Celtic Cross, each position has a unique meaning, such as the root cause of the issue, the immediate future, and the potential outcome. The position of each card, along with its upright or reversed orientation, provides additional context and layers of meaning.</p>

            <p>The tarot reader then interprets the cards based on their symbolism, position in the spread, and their interactions with each other. The reader uses their intuition and knowledge of the tarot to weave together a narrative that addresses the querent's question and offers guidance. The interpretation is not just about the individual meanings of the cards but also about how they relate to one another and to the querent's life. The goal of a tarot reading is to provide clarity, insight, and a new perspective on the querent's situation, helping them make informed decisions and navigate their path with greater awareness.</p>

            <div style={{marginTop:'75px', textAlign:'center'}}>
                See Also: 
                <Link to='/major_arcana' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Major Arcana</Link>
                <Link to='/minor_arcana' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Minor Arcana</Link>
                <Link to='/cups' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Cups</Link>
                <Link to='/wands' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Wands</Link>
                <Link to='/swords' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Swords</Link>
                <Link to='/pentacles' style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Pentacles</Link>
        </div>


        </div>
        <Footer />
        </div>
        </>
    )}
