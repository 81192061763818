export function post1()
      {
        const data = {
            number:'1',
            author:'Zara',
            date:'June 3, 2024',
            content:'AAAAAAAAAAXXXXXXXXXX', 
            title:'Astrology and Pop Culture: Which Zodiac Signs Are Your Favorite TV Characters?', 
            img:'https://d3zlw6prgce9n.cloudfront.net/tyrionlannister.png',
            teaserContent:'Ever wondered what zodiac signs your favorite TV characters might have?',
            content:`<div class="blog-article">
            <h1>Astrology and Pop Culture: Which Zodiac Signs Are Your Favorite TV Characters?</h1>
            <p>
                Ever wondered why some TV characters resonate with you more than others? It might have something to do with their zodiac signs! Just like us, fictional characters can be analyzed through the lens of astrology. Let's dive into the stars and discover which signs some of our favorite TV characters might belong to.
            </p>
            
            <h2>Aries: The Bold and Daring Leaders</h2>
            <p>
                Aries is known for their courage, determination, and leadership qualities. Think about characters like Buffy Summers from <em>Buffy the Vampire Slayer</em>. She's fearless, always ready to take on a challenge, and never backs down from a fight. Just like a true Aries, Buffy leads with confidence and inspires those around her.
            </p>
            
            <h2>Taurus: The Loyal and Grounded Friends</h2>
            <p>
                Taurus individuals are known for their loyalty, practicality, and love for the finer things in life. Look no further than Donna Paulsen from <em>Suits</em>. Donna is reliable, has impeccable taste, and is always there to support her friends and colleagues. Her steadfast nature and appreciation for luxury make her a quintessential Taurus.
            </p>
            
            <h2>Gemini: The Witty and Versatile Communicators</h2>
            <p>
                Geminis are known for their wit, adaptability, and communication skills. Who better represents these traits than Tyrion Lannister from <em>Game of Thrones</em>? Tyrion's quick thinking, clever wordplay, and ability to navigate complex social situations showcase the classic Gemini versatility and charm.
            </p>
            
            <h2>Cancer: The Nurturing and Emotional Caretakers</h2>
            <p>
                Cancer signs are nurturing, empathetic, and deeply emotional. Consider Meredith Grey from <em>Grey's Anatomy</em>. Meredith's caring nature, her dedication to her friends and family, and her emotional depth are all hallmarks of a Cancer. She often puts others' needs before her own, embodying the selfless and nurturing spirit of this sign.
            </p>
            
            <h2>Leo: The Charismatic and Confident Performers</h2>
            <p>
                Leos are known for their charisma, confidence, and love for the spotlight. Take Rachel Berry from <em>Glee</em>. Rachel's dramatic flair, her desire to be the center of attention, and her unwavering confidence in her talents make her a perfect Leo. She shines brightly and isn't afraid to show the world her true self.
            </p>
            
            <p>
                There you have it! Just a few examples of how our favorite TV characters can embody the traits of their respective zodiac signs. Whether you're an Aries leader or a Cancer caretaker, there's a character out there who shares your astrological DNA. Stay tuned for more fun insights as we continue to explore the cosmic connections in our beloved shows!
            </p>
        </div>`,
            
        }


    return data
    }

    export function post2()
      {
        const data = {
            number:'2',
            author:'Star Sage',
            date:'June 16, 2024',
            content:'', 
            title:'Gemini: How compatable are you with the other signs?', 
            img:'https://d3zlw6prgce9n.cloudfront.net/geminiclay2.png',
            teaserContent: "Greetings, celestial seekers! Today, we'll delve into the fascinating world of Gemini compatibility. As a mutable air sign, Geminis are known for their adaptability, intellect, and communication skills. Let's explore how Gemini connects with each of the other zodiac signs, both in friendships and romantic relationships.",
            content:`<div class="blog-article">
            <h1>Astrology and Pop Culture: Which Zodiac Signs Are Your Favorite TV Characters?</h1>
            <p>
                Ever wondered why some TV characters resonate with you more than others? It might have something to do with their zodiac signs! Just like us, fictional characters can be analyzed through the lens of astrology. Let's dive into the stars and discover which signs some of our favorite TV characters might belong to.
            </p>
            
            <h2>Aries: The Bold and Daring Leaders</h2>
            <p>
                Aries is known for their courage, determination, and leadership qualities. Think about characters like Buffy Summers from <em>Buffy the Vampire Slayer</em>. She's fearless, always ready to take on a challenge, and never backs down from a fight. Just like a true Aries, Buffy leads with confidence and inspires those around her.
            </p>
            
            <h2>Taurus: The Loyal and Grounded Friends</h2>
            <p>
                Taurus individuals are known for their loyalty, practicality, and love for the finer things in life. Look no further than Donna Paulsen from <em>Suits</em>. Donna is reliable, has impeccable taste, and is always there to support her friends and colleagues. Her steadfast nature and appreciation for luxury make her a quintessential Taurus.
            </p>
            
            <h2>Gemini: The Witty and Versatile Communicators</h2>
            <p>
                Geminis are known for their wit, adaptability, and communication skills. Who better represents these traits than Tyrion Lannister from <em>Game of Thrones</em>? Tyrion's quick thinking, clever wordplay, and ability to navigate complex social situations showcase the classic Gemini versatility and charm.
            </p>
            
            <h2>Cancer: The Nurturing and Emotional Caretakers</h2>
            <p>
                Cancer signs are nurturing, empathetic, and deeply emotional. Consider Meredith Grey from <em>Grey's Anatomy</em>. Meredith's caring nature, her dedication to her friends and family, and her emotional depth are all hallmarks of a Cancer. She often puts others' needs before her own, embodying the selfless and nurturing spirit of this sign.
            </p>
            
            <h2>Leo: The Charismatic and Confident Performers</h2>
            <p>
                Leos are known for their charisma, confidence, and love for the spotlight. Take Rachel Berry from <em>Glee</em>. Rachel's dramatic flair, her desire to be the center of attention, and her unwavering confidence in her talents make her a perfect Leo. She shines brightly and isn't afraid to show the world her true self.
            </p>
            
            <p>
                There you have it! Just a few examples of how our favorite TV characters can embody the traits of their respective zodiac signs. Whether you're an Aries leader or a Cancer caretaker, there's a character out there who shares your astrological DNA. Stay tuned for more fun insights as we continue to explore the cosmic connections in our beloved shows!
            </p>
        </div>`,
            
        }


    return data
    }

        

      

        

      