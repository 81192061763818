import './DateDropdown.css';

export default function DateDropdown({ month, setMonth, day, setDay, year, setYear }) {

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const getDaysInMonth = (month, year) => {
        return new Date(year, months.indexOf(month) + 1, 0).getDate();
    };

    const days = Array.from(
        { length: month ? getDaysInMonth(month, year || new Date().getFullYear()) : 31 },
        (_, i) => i + 1
    );

    const years = Array.from(
        { length: new Date().getFullYear() - 1899 },
        (_, i) => new Date().getFullYear() - i
    );

    return (
        <>
        <div className='dateDropdownOuterBox'>
            <div className= 'monthDropdownBox'>

                <select value={month} onChange={e => setMonth(e.target.value)} style={{borderRadius:'8px'}}>
                    <option value=""></option>
                    {months.map((month) => (
                        <option key={month} value={month}>{month}</option>
                    ))}
                </select>
            </div>
            <div className= 'dayDropdownBox'>

                <select value={day} onChange={e => setDay(e.target.value)} style={{borderRadius:'8px'}}>
                    <option value=""></option>
                    {days.map((day) => (
                        <option key={day} value={day}>{day}</option>
                    ))}
                </select>
            </div>
            <div className= 'yearDropdownBox'>
                <select value={year} onChange={e => setYear(e.target.value)} style={{borderRadius:'8px'}}>
                    <option value=""></option>
                    {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
        </div>
        </>
    );
}

