import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import PythagComponant from "../../componants/PythagComponant";
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function LifePath9() {

  useEffect(() => {
    document.title = 'Pythagorean Numerology | Life Path Number 9 | Cosmic Secrets';
}, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/life_path_9",
        title:"life_path_9",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>

      <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
        <h1>Life Path Number 9 - The Humanitarian</h1>
        <div style={{ margin:'auto', textAlign:'center'}}>
            <img src='https://d3zlw6prgce9n.cloudfront.net/LifePath9.png' alt='Woman representing The Humanitarian giving to outstretched hands with swirling cosmic background' style={{ width: '300px', height: '300px', borderRadius: '10px' }} />
        </div>
        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/life_path_8' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 8</Link>   
                Next:      <Link to='/life_path_1' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 1</Link>
             </div>
        <p>In Pythagorean numerology, Life Path Number 9 is known as the number of the humanitarian. People with this life path number are often seen as compassionate, altruistic, and idealistic individuals who are dedicated to making the world a better place. They are empathetic, generous, and have a deep sense of social responsibility.</p>
        
        <h2>Personality</h2>
        <p>Individuals with Life Path Number 9 possess a compassionate and understanding personality. They are deeply empathetic and often feel a strong connection to the suffering and needs of others. These individuals are driven by a desire to serve humanity and make a positive impact on the world. Their idealism and vision for a better future inspire them to work tirelessly for social justice and equality. They are often seen as wise and mature, with a deep understanding of the human condition.</p>

        <h2>Career</h2>
        <p>Career-wise, those with Life Path Number 9 excel in roles that involve helping others and promoting social change. They are well-suited for careers in social work, counseling, education, healthcare, non-profit organizations, and any field that involves humanitarian efforts. Their strong sense of ethics and justice makes them effective advocates and leaders in social causes. They thrive in environments that allow them to make a meaningful difference and work towards a greater good.</p>

        <h2>Relationships</h2>
        <p>In relationships, Life Path Number 9 individuals value compassion, understanding, and emotional connection. They are loving and supportive partners who seek to create a deep and meaningful bond with their loved ones. Their altruistic nature means they are often giving and selfless in relationships, always looking out for the well-being of their partner. However, they may need to work on setting boundaries and ensuring that their own needs are also met. Communication and mutual support are key to maintaining a healthy relationship with a Life Path Number 9 individual.</p>

        <h2>Strengths</h2>
        <p>The strengths of Life Path Number 9 include their compassion, empathy, and humanitarian spirit. They are highly intuitive and have a natural talent for understanding and helping others. Their ability to see the bigger picture and work towards a greater good makes them inspirational leaders and advocates for change. They are also wise and philosophical, often providing valuable insights and guidance to those around them.</p>

        <h2>Weaknesses</h2>
        <p>Despite their many strengths, Life Path Number 9 individuals can also have weaknesses. Their strong desire to help others can sometimes lead to self-neglect and a tendency to take on too much responsibility. They may struggle with letting go of past hurts and disappointments, finding it hard to move on and forgive. Additionally, their idealism can sometimes lead to unrealistic expectations and frustration when their high standards are not met. It is important for them to learn to balance their humanitarian pursuits with self-care and realistic expectations.</p>
        
        <h2>Conclusion</h2>
        <p>In conclusion, Life Path Number 9 is the path of the humanitarian and idealist. Those with this life path are driven by a desire to make a positive impact on the world and help those in need. By embracing their strengths and addressing their weaknesses, they can lead fulfilling and impactful lives, working towards a better and more compassionate world for all.</p>
        </div>


      
      </div>
      <Footer />
      </>
    )
}
