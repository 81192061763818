import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import PythagComponant from "../../componants/PythagComponant";
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function LifePath1() {

    useEffect(() => {
        document.title = 'Pythagorean Numerology | Life Path Number 1 | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/life_path_1",
        title:"life_path_1",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>

      <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
        <h1>Life Path Number 1 - The Leader</h1>
        <div style={{ margin:'auto', textAlign:'center'}}>
            <img src='https://d3zlw6prgce9n.cloudfront.net/LifePath1.png' alt='Man in a suit standing in front of a swirling background, representing The Leader' style={{ width: '300px', height: '300px', borderRadius: '10px' }} />
        </div>
        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/life_path_9' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 9</Link>   
                Next:      <Link to='/life_path_2' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 2</Link>
        </div>
        <p>In Pythagorean numerology, Life Path Number 1 is known as the number of the leader. People with this life path number are often seen as pioneers and innovators, driven by a strong desire to assert their individuality and carve their own path in life. They are ambitious, self-reliant, and have a natural ability to inspire and lead others.</p>
        
        <h2>Personality</h2>
        <p>Individuals with Life Path Number 1 possess a strong sense of self and are deeply motivated by personal goals. They are confident, assertive, and determined, often displaying a charismatic presence that draws others to them. These individuals thrive on competition and are not afraid to take risks to achieve their ambitions. Their independent nature means they prefer to work alone or be in control, and they often have a clear vision of what they want to accomplish.</p>

        <h2>Career</h2>
        <p>Career-wise, those with Life Path Number 1 are well-suited for leadership roles and entrepreneurial ventures. They excel in positions where they can make decisions and direct projects, such as CEOs, managers, or business owners. Their innovative thinking and drive make them natural leaders who are always looking for new opportunities and ways to improve. They are often drawn to careers that allow them to express their creativity and pioneering spirit.</p>

        <h2>Relationships</h2>
        <p>In relationships, Life Path Number 1 individuals value independence and may struggle with co-dependency. They need partners who understand their need for personal space and freedom to pursue their goals. When in a relationship, they are loyal and supportive but may need to work on being more attentive to their partner's needs and emotions. Communication and mutual respect are key to maintaining a healthy relationship with a Life Path Number 1 individual.</p>

        <h2>Strengths</h2>
        <p>The strengths of Life Path Number 1 include their leadership abilities, determination, and self-confidence. They are highly motivated and driven to succeed, often achieving great things through their sheer willpower and ambition. Their innovative thinking and ability to inspire others make them influential figures in their communities and workplaces.</p>

        <h2>Weaknesses</h2>
        <p>Despite their many strengths, Life Path Number 1 individuals can also have weaknesses. Their strong desire for independence and control can sometimes come across as stubbornness or arrogance. They may struggle with teamwork and compromise, finding it difficult to accept others' ideas or criticism. Additionally, their intense focus on personal goals can lead to a workaholic mentality, potentially neglecting relationships and self-care.</p>
        
        <h2>Conclusion</h2>
        <p>In conclusion, Life Path Number 1 is the path of the leader and innovator. Those with this life path are driven by a desire to achieve and make their mark on the world. By embracing their strengths and addressing their weaknesses, they can lead fulfilling and impactful lives, inspiring others along the way.</p>
    </div>


 

      </div>
      <Footer />
      </>
    )
}
