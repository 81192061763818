import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiac() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Chinese Zodiac Animals | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac",
        title: "chinese_zodiac",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop:'25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Chinese Zodiac</h1>
                    <Link to='/chinese_zodiac' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiac1.png' alt='Collage of Chinese Zodiac animals' style={{ width: '200px', height: '200px', borderRadius: '10px', margin: '3px', marginTop:'25px' }} />
                    </Link>
                </header>
                <div style={{textAlign:'center'}}>
                </div>
                
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>Overview of the Chinese Zodiac</h2>
                    <p>The Chinese Zodiac, also known as Shengxiao or the Chinese animal zodiac, is a classification scheme based on the lunar calendar that assigns an animal and its reputed attributes to each year in a repeating 12-year cycle. These twelve animals are the Rat, Ox, Tiger, Rabbit, Dragon, Snake, Horse, Goat, Monkey, Rooster, Dog, and Pig. Each zodiac animal has unique characteristics and traits, and people born under a particular sign are believed to exhibit these qualities.</p>
                </div>
                
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>History and Origins</h2>

                    <p>The origins of the Chinese Zodiac are deeply rooted in ancient Chinese mythology, folklore, and astronomy. The system is believed to have been established during the Han Dynasty (202 BC – 220 AD), although its exact beginnings are shrouded in mystery. One popular legend tells the story of the Jade Emperor, who invited all the animals in the kingdom to participate in a great race. The first twelve animals to cross the river would be assigned a place in the zodiac calendar.</p>

                    <p>The cunning Rat secured first place by riding on the back of the Ox and jumping ahead at the last moment. The Ox, being diligent and strong, took second place. The Tiger, known for its bravery and speed, came third, followed by the Rabbit, who used its agility to leap across stepping stones. The Dragon, despite its ability to fly, took fifth place because it paused to help others. The Snake, sly and stealthy, came sixth by hiding in the Horse's hoof. The Horse, startled by the Snake, finished seventh.</p>

                    <p>The Goat, Monkey, and Rooster worked together and helped each other across the river, securing eighth, ninth, and tenth places, respectively. The Dog, despite being an excellent swimmer, took eleventh place because it enjoyed playing in the water. Finally, the Pig, who had stopped to eat and nap, completed the zodiac as the twelfth and last animal. This story reflects the traits and characteristics traditionally associated with each animal in the zodiac.</p>

                    <p>Astronomically, the Chinese Zodiac is linked to the ancient Chinese calendar, which is lunisolar, meaning it is based on both the moon phase and the solar year. The twelve-year cycle of the zodiac corresponds with the orbit of Jupiter, which takes approximately twelve years to travel around the sun. Each year in the cycle is assigned an animal sign, influencing the personalities and destinies of individuals born under that sign.</p>

                    <p>The Chinese Zodiac also integrates the five elements theory—Wood, Fire, Earth, Metal, and Water—which further diversifies the characteristics of each sign. Each element is paired with an animal sign once in a 60-year cycle, creating a more complex and nuanced system of astrological interpretation. This combination of the twelve animals and the five elements reflects ancient Chinese philosophy and cosmology, emphasizing balance and harmony in the universe.</p>

                    <p>Throughout Chinese history, the zodiac has been used for various purposes, including determining auspicious dates for events, predicting fortunes, and understanding personality traits. It remains an integral part of Chinese culture and is celebrated with great enthusiasm during the Lunar New Year, where each year is welcomed with festivities honoring the new zodiac sign.</p>
                </div>
                
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>How It Works</h2>

                    <p>The Chinese Zodiac is based on the lunar calendar, which means that the dates of the Chinese New Year vary each year, typically falling between late January and mid-February. The Chinese Zodiac follows a twelve-year cycle, with each year associated with one of the twelve zodiac animals: Rat, Ox, Tiger, Rabbit, Dragon, Snake, Horse, Goat, Monkey, Rooster, Dog, and Pig. These animals rotate in a fixed order, and each year is governed by a different animal sign.</p>

                    <p>In addition to the twelve-year animal cycle, the Chinese Zodiac also incorporates the five elements theory: Wood, Fire, Earth, Metal, and Water. Each element has a two-year cycle and influences the characteristics of the animal signs it pairs with. This results in a 60-year cycle that combines both the twelve animals and the five elements, making each year unique. For example, a Wood Rat year is different from a Fire Rat year in terms of personality traits and influences.</p>

                    <p>The year in which a person is born determines their Chinese Zodiac sign, which is believed to influence their personality, behavior, and destiny. Each animal sign has its own set of attributes, strengths, and weaknesses. For instance, those born in the Year of the Rat are often seen as intelligent, resourceful, and quick-witted, while those born in the Year of the Ox are known for being hardworking, reliable, and determined.</p>

                    <p>Chinese astrology also places great emphasis on the concept of Yin and Yang, the fundamental forces that drive the universe and its natural rhythms. The twelve zodiac animals are divided into two groups: Yin and Yang. Yang animals (Rat, Tiger, Dragon, Horse, Monkey, and Dog) are associated with odd-numbered years and are considered active, extroverted, and dynamic. Yin animals (Ox, Rabbit, Snake, Goat, Rooster, and Pig) are linked with even-numbered years and are seen as passive, introverted, and calm.</p>

                    <p>Another critical aspect of the Chinese Zodiac is the compatibility between different animal signs. Some signs are believed to be more compatible with each other, leading to harmonious relationships, while others may clash and result in conflict. For example, the Rat and the Ox are considered highly compatible due to their complementary qualities, whereas the Rat and the Horse may experience challenges due to their differing natures. This compatibility analysis is often used in matchmaking and determining business partnerships.</p>

                    <p>The influence of the Chinese Zodiac extends beyond personal characteristics to encompass daily, monthly, and yearly predictions. Chinese astrologers use the zodiac to provide insights into various aspects of life, including career, health, relationships, and fortune. Each year, individuals can consult the zodiac to understand how the governing animal sign and element might affect their lives and to make informed decisions accordingly.</p>

                    <p>The Chinese Zodiac also plays a significant role in cultural practices and celebrations. During the Lunar New Year, each year is welcomed with festivities that honor the new zodiac sign. Decorations, clothing, and activities often feature the animal of the year, symbolizing its qualities and blessings. People may also give gifts and greetings that reflect the traits of the zodiac sign, such as wishing for prosperity during the Year of the Dragon or good health in the Year of the Rabbit.</p>
                </div>
                
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>Characteristics of the Zodiac Animals</h2>
                    <p style={{textAlign:'center'}}>Each animal in the Chinese Zodiac has specific traits and characteristics associated with it:</p>
                    
                    <Link to='/chinese_zodiac_rat' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Rat</h2></Link>
                    <p>The Rat is the first animal in the Chinese Zodiac cycle. Those born under this sign are known for their intelligence, resourcefulness, and quick wit. Rats are highly adaptable and can easily navigate through various challenges and situations. They are charming, sociable, and have a natural ability to attract others. However, they can also be perceived as opportunistic and may struggle with trust issues.</p>
                    <Link to='/chinese_zodiac_ox' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Ox</h2></Link>
                    <p>The Ox is known for its hardworking, reliable, and determined nature. People born under this sign are diligent and possess a strong sense of responsibility. They are methodical and patient, often achieving success through perseverance and consistent effort. Oxen are also known for their honesty and dependability. However, they can be stubborn and may find it difficult to adapt to change.</p>
                    <Link to='/chinese_zodiac_tiger' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Tiger</h2></Link>
                    <p>The Tiger is a symbol of bravery, confidence, and competitiveness. Individuals born under this sign are natural leaders who are not afraid to take risks. They are passionate and ambitious, often pursuing their goals with vigor and determination. Tigers are also known for their charm and charisma, making them popular in social settings. On the downside, they can be impulsive and may struggle with patience and planning.</p>
                    <Link to='/chinese_zodiac_rabbit' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Rabbit</h2></Link>
                    <p>The Rabbit is associated with gentleness, compassion, and good-naturedness. Those born under this sign are known for their kind and empathetic nature. Rabbits are often seen as peacemakers, valuing harmony and avoiding conflict. They are also artistic and have a refined sense of aesthetics. However, their sensitivity can sometimes make them prone to anxiety and indecision.</p>
                    <Link to='/chinese_zodiac_dragon' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Dragon</h2></Link>
                    <p>The Dragon is a symbol of ambition, energy, and charisma. Individuals born under this sign are often seen as dynamic and powerful leaders. They have a magnetic personality and are capable of inspiring others. Dragons are also known for their creativity and innovative thinking. However, their strong-willed nature can sometimes make them overbearing and prone to arrogance.</p>
                    <Link to='/chinese_zodiac_snake' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Snake</h2></Link>
                    <p>The Snake is associated with wisdom, enigma, and grace. People born under this sign are known for their deep thinking and intuitive nature. They are often seen as mysterious and are skilled at uncovering hidden truths. Snakes are also elegant and refined, valuing beauty and sophistication. However, they can be secretive and may struggle with trust and openness.</p>
                    <Link to='/chinese_zodiac_horse' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Horse</h2></Link>
                    <p>The Horse is a symbol of energy, independence, and enthusiasm. Those born under this sign are adventurous and love to explore new horizons. They are sociable and enjoy being in the company of others. Horses are also known for their resilience and ability to overcome obstacles. However, their restless nature can sometimes make them impulsive and prone to taking unnecessary risks.</p>
                    <Link to='/chinese_zodiac_goat' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Goat</h2></Link>
                    <p>The Goat is associated with calmness, gentleness, and sympathy. Individuals born under this sign are known for their compassionate and nurturing nature. They are often artistic and have a keen sense of aesthetics. Goats value harmony and seek to create a peaceful environment. However, they can be indecisive and may struggle with self-confidence and assertiveness.</p>
                    <Link to='/chinese_zodiac_monkey' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Monkey</h2></Link>
                    <p>The Monkey is a symbol of cleverness, curiosity, and liveliness. People born under this sign are known for their quick wit and problem-solving abilities. They are playful and enjoy engaging in creative activities. Monkeys are also adaptable and resourceful, often finding innovative solutions to challenges. However, their mischievous nature can sometimes lead to impulsiveness and a lack of focus.</p>
                    <Link to='/chinese_zodiac_rooster' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Rooster</h2></Link>
                    <p>The Rooster is associated with observance, hard work, and courage. Individuals born under this sign are diligent and detail-oriented, often excelling in tasks that require precision. They are confident and not afraid to express their opinions. Roosters are also known for their strong sense of duty and responsibility. However, their straightforward nature can sometimes come across as blunt or critical.</p>
                    <Link to='/chinese_zodiac_dog' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Dog</h2></Link>
                    <p>The Dog is a symbol of loyalty, honesty, and faithfulness. Those born under this sign are known for their strong sense of justice and moral integrity. They are reliable and trustworthy, often serving as protectors and advocates for others. Dogs value loyalty and are dedicated to their loved ones. However, their protective nature can sometimes make them overly cautious and wary of strangers.</p>
                    <Link to='/chinese_zodiac_pig' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}><h2 style={{marginTop:'25px'}}>Pig</h2></Link>
                    <p>The Pig is associated with generosity, diligence, and compassion. Individuals born under this sign are kind-hearted and always willing to help others. They are hardworking and often achieve success through their persistence and determination. Pigs are also known for their generosity and enjoy sharing their wealth and resources. However, their trusting nature can sometimes make them vulnerable to being taken advantage of.</p>
                    
                </div>               
                
                               
            </div>
            <Footer />
        </>
    )
}
