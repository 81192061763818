import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Sagittarius(){

    useEffect(() => {
        document.title = 'Zodiac | Sagittarius | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/sagittarius",
        title:"sagittarius",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Sagittarius: The Archer (November 22 - December 21)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/sagittarius1.png' alt='Sagittarius the Archer' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/scorpio' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Scorpio</Link>   
                    Next:      <Link to='/capricorn' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Capricorn</Link>
                </div>
                <p>Sagittarius is the ninth sign of the zodiac, symbolizing exploration, freedom, and adventure. Represented by the Archer, Sagittarius is ruled by Jupiter, the planet of expansion, growth, and optimism. People born under this sign are known for their adventurous spirit, love of learning, and enthusiastic approach to life.</p>

                <h2>Personality Traits</h2>
                <p>Sagittarius individuals are characterized by their optimistic and adventurous nature. They have a strong desire for knowledge and new experiences, often seeking out opportunities to explore the world and expand their horizons. Sagittarians are known for their honesty and straightforwardness, always speaking their mind and valuing truth above all else.</p>
                <p>Despite their adventurous and free-spirited demeanor, Sagittarians can sometimes be seen as restless and impatient. They thrive on change and may struggle with routine or confinement. While their honesty is one of their greatest strengths, it can also be perceived as bluntness, so they need to balance their directness with tact.</p>

                <h2>Career</h2>
                <p>In the professional realm, Sagittarius excels in roles that require exploration, creativity, and a broad perspective. They are well-suited for careers in travel, education, writing, and any field that involves sharing knowledge and experiences. Sagittarians' enthusiasm and ability to inspire others make them effective leaders and motivators.</p>
                <p>Sagittarius employees are known for their optimism and innovative ideas. They bring a sense of adventure and excitement to their work, often coming up with creative solutions and approaches. However, they may need to work on their attention to detail and ability to follow through, as their desire for new experiences can sometimes lead to unfinished projects.</p>

                <h2>Relationships</h2>
                <p>In relationships, Sagittarius is playful and passionate. They seek partners who can match their enthusiasm for life and share their love of adventure. Sagittarius values independence and freedom, and they appreciate partners who respect their need for personal space and growth. They are drawn to individuals who are open-minded and willing to explore new ideas and experiences.</p>
                <p>As friends, Sagittarians are generous and fun-loving. They are always ready for a new adventure and enjoy bringing excitement to their social circles. In romantic relationships, they are affectionate and enjoy spontaneous activities with their partners. However, their need for freedom can sometimes lead to commitment issues, so finding a balance between independence and togetherness is key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Sagittarius is a sign that embodies exploration, freedom, and adventure. Their optimistic nature, love of learning, and enthusiastic approach to life make them inspiring and dynamic individuals. By embracing their strengths and working on their challenges, Sagittarius individuals can lead fulfilling lives filled with adventure, growth, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}