import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function MajorArcana(){

    useEffect(() => {
        document.title = 'Tarot | The Major Arcana | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"major_arcana",
        title:"major_arcana",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>
    <div ref={topContainer} />
    <Navbar />

    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
    <header className="header" style={{backgroundColor:'rgb(46,45,41)'}} >
      <h1>The Major Arcana</h1>
      </header>
        <div style={{margin:'auto', textAlign:'center'}}>
            <Link to='/fool_tarot'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/00_Fool.jpg' alt='The Fool Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/magician_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/01_Magician.jpg' alt='The Magician Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/high_priestess_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/02_High_Priestess.jpg' alt='The High Priestess Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/empress_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/03_Empress.jpg' alt='The Empress Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/emperor_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/04_Emperor.jpg' alt='The Emperor Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/hierophant_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/05_Hierophant.jpg' alt='The Hierophant Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/lovers_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/06_Lovers.jpg' alt='The Lovers Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        
        </div>
        <div style={{margin:'auto', textAlign:'center'}}>
            <Link to='/chariot_tarot'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/07_Chariot.jpg' alt='The Chariot Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/strength_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/08_Strength.jpg' alt='The Strength Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/hermit_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/09_Hermit.jpg' alt='The Hermit Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/wheel_of_fortune_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/10_Wheel_of_Fortune.jpg' alt='The Wheel Of Fortune Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/justice_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/11_Justice.jpg' alt='Justice Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/hanged_man_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/12_Hanged_Man.jpg' alt='The Hanged Man Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/death_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/13_Death.jpg' alt='Death Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        
        </div>
        <div style={{margin:'auto', textAlign:'center'}}>
            <Link to='/temperance_tarot'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/14_Temperance.jpg' alt='The Temperance Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/devil_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/15_Devil.jpg' alt='The Devil Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/tower_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/16_Tower.jpg' alt='The Tower Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/star_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/17_Star.jpg' alt='The Star Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/moon_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/18_Moon.jpg' alt='The Moon Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/sun_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/19_Sun.jpg' alt='The Sun Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/judgement_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/20_Judgement.jpg' alt='The Judgement Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>   
        
        </div>  
        <div style={{margin:'auto', textAlign:'center'}}>
            <Link to='/world_tarot'>
                <img src='https://d3zlw6prgce9n.cloudfront.net/21_World.jpg' alt='The World Tarot Card' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>     
        <h2>What is the Major Arcana?</h2>
        <p>The Major Arcana is a set of 22 cards in the <Link to='/tarot'>Tarot</Link> deck, each carrying deep symbolic meanings and representing significant life events, spiritual lessons, and universal archetypes. These cards are often seen as the most powerful and impactful within the <Link to='/tarot'>Tarot</Link> deck, providing profound insights into the querent's life journey and spiritual path. Unlike the <Link to='/minor_arcana'>Minor Arcana</Link>, which deals with everyday events and experiences, the Major Arcana focuses on broader, more transformative themes that shape one's destiny and personal evolution.</p>

        <h2>Overview of the Major Arcana Cards</h2>

        <p>The Major Arcana cards are numbered from 0 to 21, starting with <Link to='/fool_tarot'>The Fool</Link> and ending with <Link to='/world_tarot'>The World</Link>. Each card represents a stage in the Fool's Journey, a metaphorical journey that reflects the human experience and the quest for self-discovery and enlightenment. The journey begins with <Link to='/fool_tarot'>The Fool</Link>, symbolizing innocence and new beginnings, and progresses through various stages of growth, challenge, and realization, culminating in the completion and fulfillment represented by <Link to='/world_tarot'>The World</Link>.</p>

        <p>The Major Arcana includes well-known cards such as <Link to='/magician_tarot'>The Magician</Link>, <Link to='/high_priestess_tarot'>The High Priestess</Link>, <Link to='/empress_tarot'>The Empress</Link>, <Link to='/emperor_tarot'>The Emperor</Link>, <Link to='/hierophant_tarot'>The Hierophant</Link>, <Link to='/lovers_tarot'>The Lovers</Link>, <Link to='/chariot_tarot'>The Chariot</Link>, <Link to='/strength_tarot'>Strength</Link>, <Link to='/hermit_tarot'>The Hermit</Link>, <Link to='/wheel_of_fortune_tarot'>Wheel of Fortune</Link>, <Link to='/justice_tarot'>Justice</Link>, <Link to='/hanged_man_tarot'>The Fool</Link>, <Link to='/death_tarot'>Death</Link>, <Link to='/temperance_tarot'>Temperance</Link>, <Link to='/devil_tarot'>The Devil</Link>, <Link to='/tower_tarot'>The Tower</Link>, <Link to='/star_tarot'>The Star</Link>, <Link to='/moon_tarot'>The Moon</Link>, <Link to='/sun_tarot'>The Sun</Link>, <Link to='/judgement_tarot'>Judgement</Link>, and <Link to='/world_tarot'>The World</Link>. Each card carries its own unique symbolism and meaning, offering insights into different aspects of life and spiritual growth. For example, <Link to='/magician_tarot'>The Magician</Link> represents mastery and manifestation, while <Link to='/hith_priestess_tarot'>The High Priestess</Link> symbolizes intuition and hidden knowledge.</p>

        <h2>Symbolism and Interpretation</h2>

        <p>The symbolism of the Major Arcana cards is rich and multifaceted, drawing from a wide range of cultural, religious, and spiritual traditions. Each card's imagery is carefully designed to convey specific messages and lessons, using symbols, colors, and figures that resonate with the querent's subconscious mind. When interpreting the Major Arcana, tarot readers consider both the individual meanings of each card and their positions within a spread, as well as the relationships and interactions between the cards.</p>

        <p>The Major Arcana cards are often seen as representing the "big picture" themes and influences in a reading. When these cards appear in a spread, they highlight important turning points, transformative experiences, and significant spiritual lessons that the querent is encountering or will encounter. For example, <Link to='/tower_tarot'>The Tower</Link> card, with its image of a lightning-struck tower, signifies sudden upheaval and the breaking down of old structures, paving the way for new growth and change. In contrast, <Link to='/star_tarot'>The Star</Link> card, with its serene depiction of a starry night, represents hope, inspiration, and spiritual guidance.</p>

        <h2>The Fool's Journey</h2>

        <p>The concept of <Link to='/fools_journey'>The Fool's Journey</Link> is central to understanding the Major Arcana. This journey is a symbolic narrative that illustrates the Fool's progression through the various stages of life, personal growth, and spiritual development. <Link to='/fool_tarot'>The Fool</Link>, represented by the card numbered 0, embarks on a path of discovery, encountering mentors, challenges, and revelations along the way. Each Major Arcana card represents a step in this journey, offering insights into the experiences and lessons that shape <Link to='/fool_tarot'>The Fool's</Link> evolution.</p>

        <p>As <Link to='/fool_tarot'>The Fool</Link> travels through the Major Arcana, they learn important truths about themselves and the world around them. This journey is not linear but cyclical, reflecting the continuous process of growth, transformation, and renewal that characterizes human life. The Fool's Journey serves as a powerful metaphor for the querent's own life path, encouraging them to embrace change, seek wisdom, and pursue their highest potential.</p>

        <h2>Conclusion</h2>

        <p>The Major Arcana in Tarot is a profound and powerful tool for self-discovery, spiritual growth, and understanding the deeper patterns and influences in one's life. By exploring the rich symbolism and meanings of these 22 cards, individuals can gain valuable insights into their life journey, uncover hidden truths, and connect with their higher selves. Whether used for personal reflection, guidance, or divination, the Major Arcana offers a transformative and enlightening experience for those who seek its wisdom.</p>
        <div style={{marginTop:'75px', textAlign:'center'}}>
                See Also: <strong><Link to='/tarot' style={{margin:'20px'}}>Tarot</Link><Link to='/minor_arcana'>Minor Arcana</Link></strong>
        </div>
        <Footer />
    </div>
    </>
  )}

