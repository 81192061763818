import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TwoOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Two of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "two_of_pentacles",
        title: "two_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Two of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/two_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents02.jpg' alt='Two of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ace_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ace of Pentacles</Link>   
                    <Link to='/three_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Three of Pentacles</Link>
                </div>
                <h2>Overview of the Two of Pentacles</h2>

                <p>The Two of Pentacles is a card in the Minor Arcana of the Tarot deck that represents balance, adaptability, and the management of multiple responsibilities. It signifies a period of juggling various aspects of life, making decisions, and maintaining equilibrium. This card encourages the querent to stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Two of Pentacles typically features a figure juggling two pentacles, with an infinity symbol connecting them, and a turbulent sea in the background. The scene depicts the balancing act of managing multiple responsibilities and adapting to changing circumstances. The overall imagery emphasizes themes of balance, adaptability, and the management of various aspects of life.</p>

                <p>The Two of Pentacles' symbolism emphasizes themes of balance, adaptability, and the management of multiple responsibilities. This card encourages the querent to stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Two of Pentacles appears in a reading, it signifies balance, adaptability, and the management of multiple responsibilities. It suggests that the querent is in or will soon enter a period of juggling various aspects of life, making decisions, and maintaining equilibrium. The Two of Pentacles encourages the querent to stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life.</p>

                <p>In the context of personal growth, the Two of Pentacles can indicate a period of self-discovery and the development of adaptability and balance. It may suggest that the querent is learning to manage multiple responsibilities and finding ways to maintain equilibrium. In relationships, the Two of Pentacles can signify the need to address imbalances, prioritize shared goals, and find harmony amidst the demands of the partnership.</p>

                <p>In career and financial matters, the Two of Pentacles can signal opportunities for growth and success through the effective management of responsibilities and adaptability. It advises the querent to stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life. The Two of Pentacles reminds the querent that with balance, adaptability, and the management of multiple responsibilities, they can achieve their goals and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Two of Pentacles in the Tarot deck is a powerful symbol of balance, adaptability, and the management of multiple responsibilities. By understanding and embracing the energy it represents, individuals can stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life. The Two of Pentacles encourages the querent to stay flexible, prioritize their tasks, and find harmony amidst the demands of daily life. It is a reminder that with balance, adaptability, and the management of multiple responsibilities, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
