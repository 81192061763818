import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Leo(){

    useEffect(() => {
        document.title = 'Zodiac | Leo | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/leo",
        title:"leo",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Leo: The Lion (July 23 - August 22)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/leo1.png' alt='Leo the Lion' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/cancer' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Cancer</Link>   
                    Next:      <Link to='/virgo' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Virgo</Link>
                </div>
                <p>Leo is the fifth sign of the zodiac, symbolizing creativity, passion, and leadership. Represented by the Lion, Leo is ruled by the Sun, which governs self-expression, vitality, and individuality. People born under this sign are known for their confidence, generosity, and charismatic presence.</p>

                <h2>Personality Traits</h2>
                <p>Leo individuals are characterized by their vibrant and outgoing nature. They are natural-born leaders who thrive in the spotlight and enjoy being the center of attention. Leos are known for their warmth and generosity, often going out of their way to help others and make them feel special. They possess a strong sense of self and are driven by a desire to achieve greatness.</p>
                <p>Despite their sometimes dramatic and attention-seeking behavior, Leos have a heart of gold. They are fiercely loyal to their friends and loved ones, and their enthusiasm for life is contagious. However, their strong personality can sometimes come off as domineering or overly self-centered, so it's important for Leos to balance their confidence with humility.</p>

                <h2>Career</h2>
                <p>In the professional realm, Leo excels in roles that require leadership, creativity, and a dynamic presence. They are well-suited for careers in entertainment, arts, politics, and any field that allows them to express their talents and take charge. Leos' charisma and ability to inspire others make them effective leaders and motivators.</p>
                <p>Leo employees are known for their enthusiasm and dedication. They bring a sense of passion and creativity to their work, often coming up with innovative solutions and ideas. However, they may need to work on their ability to collaborate and listen to others, as their strong opinions and desire for control can sometimes create conflicts.</p>

                <h2>Relationships</h2>
                <p>In relationships, Leo is passionate and affectionate. They seek partners who can match their energy and appreciate their need for admiration and attention. Leo values loyalty and honesty, and they appreciate partners who are supportive and willing to share in their adventures. They are drawn to individuals who can provide both excitement and stability.</p>
                <p>As friends, Leos are generous and protective. They are always ready to offer encouragement and support, and their vibrant energy makes them fun to be around. In romantic relationships, they are devoted and enjoy showering their partners with love and affection. However, their strong personality can sometimes lead to clashes, so finding a balance between giving and receiving is key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Leo is a sign that embodies creativity, passion, and leadership. Their confidence, generosity, and charismatic nature make them inspiring and influential individuals. By embracing their strengths and working on their challenges, Leo individuals can lead fulfilling lives filled with love, success, and personal growth.</p>
            </div>

            <Footer />
            </>
    )}