import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SevenOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Seven of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "seven_of_cups",
        title: "seven_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Seven of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/seven_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups07.jpg' alt='Seven of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/six_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Six of Cups</Link>   
                    <Link to='/eight_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Eight of Cups</Link>
                </div>
                <h2>Overview of the Seven of Cups</h2>

                <p>The Seven of Cups is a card in the Minor Arcana of the Tarot deck that represents choices, fantasies, and illusions. It signifies a period of decision-making, where one is faced with multiple options and possibilities, often leading to confusion and indecision. This card encourages the querent to carefully evaluate their choices, distinguish between reality and illusion, and make informed decisions based on clarity and insight.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Seven of Cups typically features a figure standing before seven cups, each containing different objects or symbols. These objects represent various fantasies, desires, and illusions, highlighting the multitude of choices available. The overall imagery emphasizes themes of choice, fantasy, and the need to distinguish between reality and illusion.</p>

                <p>The Seven of Cups' symbolism emphasizes themes of choices, fantasies, and illusions. This card encourages the querent to carefully evaluate their choices, distinguish between reality and illusion, and make informed decisions based on clarity and insight.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Seven of Cups appears in a reading, it signifies choices, fantasies, and illusions. It suggests that the querent is in a period of decision-making, faced with multiple options and possibilities that may be leading to confusion and indecision. The Seven of Cups encourages the querent to carefully evaluate their choices, distinguish between reality and illusion, and make informed decisions based on clarity and insight.</p>

                <p>In the context of personal growth, the Seven of Cups can indicate a period of introspection and the need to clarify one's desires and goals. It may suggest that the querent is exploring various possibilities and is learning to distinguish between realistic aspirations and mere fantasies. In relationships, the Seven of Cups can signify the presence of multiple romantic interests or choices, encouraging the querent to make thoughtful and informed decisions.</p>

                <p>In career and financial matters, the Seven of Cups can signal opportunities for growth through careful decision-making and evaluation of options. It advises the querent to weigh their choices carefully, avoid being swayed by illusions or unrealistic fantasies, and make informed decisions based on clarity and insight. The Seven of Cups reminds the querent that with careful evaluation and informed decision-making, they can achieve their goals and find success in their endeavors.</p>

                <h3>Conclusion</h3>

                <p>The Seven of Cups in the Tarot deck is a powerful symbol of choices, fantasies, and illusions. By understanding and embracing the energy it represents, individuals can carefully evaluate their choices, distinguish between reality and illusion, and make informed decisions based on clarity and insight. The Seven of Cups encourages the querent to carefully evaluate their choices, distinguish between reality and illusion, and make informed decisions based on clarity and insight. It is a reminder that with careful evaluation and informed decision-making, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
