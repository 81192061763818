import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function PageOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Page of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "page_of_pentacles",
        title: "page_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Page of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/page_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents11.jpg' alt='Page of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ten_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ten of Pentacles</Link>   
                    <Link to='/knight_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Knight of Pentacles</Link>
                </div>
                <h2>Overview of the Page of Pentacles</h2>

                <p>The Page of Pentacles is a card in the Minor Arcana of the Tarot deck that represents new beginnings, opportunities, and the pursuit of goals. It signifies a period of curiosity, ambition, and the willingness to learn and grow. This card encourages the querent to seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Page of Pentacles typically features a young person holding a pentacle, standing in a lush field. The scene depicts a sense of curiosity, ambition, and the potential for growth and success. The overall imagery emphasizes themes of new beginnings, opportunities, and the pursuit of goals.</p>

                <p>The Page of Pentacles' symbolism emphasizes themes of new beginnings, opportunities, and the pursuit of goals. This card encourages the querent to seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Page of Pentacles appears in a reading, it signifies new beginnings, opportunities, and the pursuit of goals. It suggests that the querent is in or will soon enter a period of curiosity, ambition, and the willingness to learn and grow. The Page of Pentacles encourages the querent to seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination.</p>

                <p>In the context of personal growth, the Page of Pentacles can indicate a period of self-discovery and the development of skills through learning and exploration. It may suggest that the querent is learning to appreciate their abilities, explore new interests, and invest in their future. In relationships, the Page of Pentacles can signify the potential for growth, the pursuit of shared goals, and the importance of curiosity and ambition within the partnership.</p>

                <p>In career and financial matters, the Page of Pentacles can signal opportunities for growth and success through new ventures, investments, and the pursuit of goals. It advises the querent to seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination. The Page of Pentacles reminds the querent that with new beginnings, opportunities, and the pursuit of goals, they can achieve their dreams and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Page of Pentacles in the Tarot deck is a powerful symbol of new beginnings, opportunities, and the pursuit of goals. By understanding and embracing the energy it represents, individuals can seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination. The Page of Pentacles encourages the querent to seize new opportunities, invest in their future, and pursue their ambitions with enthusiasm and determination. It is a reminder that with new beginnings, opportunities, and the pursuit of goals, we can achieve our dreams and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
