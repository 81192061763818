import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function About(){

    useEffect(() => {
        document.title = 'About | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/about",
        title:"about",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>
    <Navbar />
        <div ref={topContainer} />
        <div style={{maxWidth:'800px', margin:'auto'}}>
            <h1>About Us</h1>
            <p>Welcome to Cosmic Secrets! We are passionate about the mystical arts and dedicated to providing you with comprehensive insights into the world of astrology, numerology, tarot, and more.</p>

            <h2>Our Mission</h2>
            <p>Our mission is to guide you on your spiritual journey by offering accurate and insightful information. We believe that understanding the cosmic influences on our lives can lead to greater self-awareness and personal growth.</p>

            <h2>What We Offer</h2>
            <p>Our website covers a variety of topics, including:</p>
            <ul>
                <li><strong>Zodiac Signs:</strong> Detailed descriptions of each zodiac sign, their characteristics, and compatibility.</li>
                <li><strong>Numerology:</strong> Insights into the mystical meanings of numbers and how they influence our lives.</li>
                <li><strong>Tarot:</strong> Comprehensive guides to tarot card meanings, spreads, and readings.</li>
                <li><strong>Horoscopes:</strong> Daily, weekly, and monthly horoscopes to help you navigate your life’s journey.</li>
                <li><strong>Astrological Events:</strong> Updates on significant astrological events and their potential impacts.</li>
            </ul>

            <h2>Our Team</h2>
            <p>We are a team of experienced astrologers, numerologists, and tarot readers who are dedicated to sharing our knowledge and helping you discover the hidden influences in your life. Our goal is to create a welcoming and informative space for everyone, from beginners to seasoned enthusiasts.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions, suggestions, or feedback, please don't hesitate to <a href="mailto:cosmic.secrets.info@gmail.com">contact us</a>. We love hearing from our community and are always here to help!</p>

            <p>Thank you for visiting Cosmic Secrets. We hope you find the insights and guidance you are looking for and enjoy exploring the mystical world of astrology with us!</p>
        </div>

    
    <Footer />
    </>)}