import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function MagicianTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Magician Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "magician_tarot",
        title: "magician_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Magician</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/magician_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/01_Magician.jpg' alt='Magician Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of the Magician</h2>

                <p>The Magician is the second card in the Major Arcana of the Tarot deck, numbered as 1. It represents manifestation, resourcefulness, and the ability to turn visions into reality. The Magician is a symbol of skill, power, and the creative potential that lies within each individual. This card encourages the querent to harness their talents and resources to achieve their goals and create the life they desire.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Magician card typically features a figure standing behind a table, holding a wand raised towards the sky. The Magician's other hand points towards the ground, symbolizing the connection between the spiritual and the material realms. On the table before him are the symbols of the four suits of the Tarot: a cup, a pentacle, a sword, and a wand, representing the elements of water, earth, air, and fire. These symbols signify the tools and resources available to the Magician to manifest his desires.</p>

                <p>The Magician's number, 1, signifies new beginnings, individuality, and the power of creation. The infinity symbol above his head represents infinite potential and the continuous flow of energy. The Magician card's symbolism encourages the querent to tap into their inner power, use their skills and resources wisely, and take inspired action towards their goals.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Magician card appears in a reading, it signifies the ability to manifest one's desires and the importance of using one's skills and resources effectively. It suggests that the querent has the potential to achieve their goals and create positive change in their life. The Magician card encourages the querent to believe in their abilities, take action, and use their talents to bring their visions to fruition.</p>

                <p>In the context of personal growth, the Magician card can indicate a period of self-discovery and empowerment. It may suggest that the querent is becoming more aware of their potential and is ready to take charge of their life. In relationships, the Magician card can signify the beginning of a new and dynamic connection, or it may highlight the importance of communication and resourcefulness in maintaining a healthy relationship.</p>

                <p>In career and financial matters, the Magician card can signal new opportunities, innovative ideas, and the ability to achieve success through skill and determination. It advises the querent to be proactive, use their talents, and take advantage of the resources available to them. The Magician card reminds the querent that they have the power to shape their reality and achieve their aspirations through focused effort and creativity.</p>

                <h2>Conclusion</h2>

                <p>The Magician card in the Tarot deck is a powerful symbol of manifestation, resourcefulness, and the ability to create one's reality. By understanding and embracing the energy it represents, individuals can harness their inner power and talents to achieve their goals and bring their visions to life. The Magician card encourages the querent to take inspired action, use their skills and resources wisely, and trust in their ability to manifest their desires. It is a reminder that with focus, determination, and creativity, anything is possible.</p>

                <Footer />
            </div>
        </>
    )
}
