import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Scorpio(){

    useEffect(() => {
        document.title = 'Zodiac | Scorpio | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/scorpio",
        title:"scorpio",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Scorpio: The Scorpion (October 23 - November 21)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/scorpio1.png' alt='Scorpio the Scorpion' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/libra' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Libra</Link>   
                    Next:      <Link to='/sagittarius' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Sagittarius</Link>
                </div>
                <p>Scorpio is the eighth sign of the zodiac, symbolizing intensity, transformation, and depth. Represented by the Scorpion, Scorpio is ruled by Pluto, the planet of power, rebirth, and regeneration. People born under this sign are known for their passionate nature, emotional depth, and strong will.</p>

                <h2>Personality Traits</h2>
                <p>Scorpio individuals are characterized by their intense and magnetic personality. They possess a deep and mysterious nature, often keeping their true feelings hidden beneath the surface. Scorpios are known for their determination and resourcefulness, and they have a knack for uncovering the truth and delving into the unknown.</p>
                <p>Despite their sometimes secretive and enigmatic demeanor, Scorpios are incredibly loyal and protective of their loved ones. They have a powerful presence and are not afraid to confront difficult situations head-on. While their intensity can be intimidating to some, it is also one of their greatest strengths, driving them to achieve their goals with unwavering focus.</p>

                <h2>Career</h2>
                <p>In the professional realm, Scorpio excels in roles that require investigation, analysis, and a strategic approach. They are well-suited for careers in research, psychology, detective work, and any field that involves uncovering hidden truths and navigating complex situations. Scorpios' determination and ability to stay focused make them invaluable assets in high-stakes environments.</p>
                <p>Scorpio employees are known for their dedication and ability to handle intense and challenging tasks. They bring a sense of determination and depth to their work, often going above and beyond to achieve their goals. However, they may need to work on their ability to delegate and trust others, as their desire for control can sometimes lead to isolation.</p>

                <h2>Relationships</h2>
                <p>In relationships, Scorpio is passionate and deeply committed. They seek partners who can match their emotional intensity and provide a sense of loyalty and trust. Scorpio values honesty and emotional depth, and they appreciate partners who are willing to share their innermost thoughts and feelings. They are drawn to individuals who can handle their intensity and provide a sense of security.</p>
                <p>As friends, Scorpios are loyal and protective. They are always ready to offer support and stand by their friends through thick and thin. In romantic relationships, they are deeply affectionate and enjoy exploring the depths of their partner's emotions. However, their intensity can sometimes lead to possessiveness, so finding a balance between closeness and independence is key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Scorpio is a sign that embodies intensity, transformation, and depth. Their passionate nature, emotional depth, and strong will make them powerful and resilient individuals. By embracing their strengths and working on their challenges, Scorpio individuals can lead fulfilling lives filled with purpose, transformation, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}