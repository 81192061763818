import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import PythagComponant from "../../componants/PythagComponant";
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function LifePath6() {

  useEffect(() => {
    document.title = 'Pythagorean Numerology | Life Path Number 6 | Cosmic Secrets';
}, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/life_path_6",
        title:"life_path_6",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>
      <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
        <h1>Life Path Number 6 - The Nurturer</h1>
        <div style={{ margin:'auto', textAlign:'center'}}>
            <img src='https://d3zlw6prgce9n.cloudfront.net/LifePath6.png' alt='Woman representing The Nurturer looking down at hands with swirling cosmic background' style={{ width: '300px', height: '300px', borderRadius: '10px' }} />
        </div>
        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/life_path_5' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 5</Link>   
                Next:      <Link to='/life_path_7' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 7</Link>
             </div>
        <p>In Pythagorean numerology, Life Path Number 6 is known as the number of the nurturer. People with this life path number are often seen as compassionate, responsible, and harmonious individuals who are dedicated to caring for others. They are nurturing, supportive, and have a strong sense of duty to their family and community.</p>
        
        <h2>Personality</h2>
        <p>Individuals with Life Path Number 6 possess a warm and caring personality. They are empathetic, loving, and often put the needs of others before their own. These individuals thrive on creating a harmonious environment and are often seen as peacemakers. Their strong sense of responsibility drives them to take care of their loved ones and ensure everyone's well-being. They are also highly intuitive and can easily sense the emotions and needs of others.</p>

        <h2>Career</h2>
        <p>Career-wise, those with Life Path Number 6 excel in roles that involve helping and nurturing others. They are well-suited for careers in healthcare, education, counseling, social work, and any field that involves caregiving and support. Their compassionate nature and ability to connect with people make them effective in roles that require empathy and understanding. They thrive in environments that allow them to make a positive impact on others' lives.</p>

        <h2>Relationships</h2>
        <p>In relationships, Life Path Number 6 individuals value love, harmony, and commitment. They are devoted and loyal partners who seek to build a stable and nurturing relationship. Their caring nature means they are attentive and supportive, often going out of their way to ensure their partner's happiness. However, they may need to work on setting boundaries and ensuring that their own needs are also met. Communication and mutual respect are key to maintaining a healthy relationship with a Life Path Number 6 individual.</p>

        <h2>Strengths</h2>
        <p>The strengths of Life Path Number 6 include their nurturing abilities, empathy, and strong sense of responsibility. They are highly compassionate and have a natural talent for caregiving and support. Their ability to create harmony and bring people together makes them valuable members of their community. They are also patient and reliable, often serving as the emotional backbone for their loved ones.</p>

        <h2>Weaknesses</h2>
        <p>Despite their many strengths, Life Path Number 6 individuals can also have weaknesses. Their strong desire to care for others can sometimes lead to self-neglect and a tendency to take on too much responsibility. They may struggle with letting go and allowing others to take care of themselves, often feeling the need to control and manage everything. Additionally, their sensitivity can make them vulnerable to emotional stress and burnout. It is important for them to learn to set boundaries and practice self-care.</p>
        
        <h2>Conclusion</h2>
        <p>In conclusion, Life Path Number 6 is the path of the nurturer and caregiver. Those with this life path are driven by a desire to create a harmonious and loving environment for themselves and others. By embracing their strengths and addressing their weaknesses, they can lead fulfilling and impactful lives, providing support and care to those around them.</p>
        </div>


      
      </div>
      <Footer />
      </>
    )
}
