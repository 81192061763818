import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function PageOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Page of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "page_of_cups",
        title: "page_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Page of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/page_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups11.jpg' alt='Page of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ten_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ten of Cups</Link>   
                    <Link to='/knight_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Knight of Cups</Link>
                </div>
                <h2>Overview of the Page of Cups</h2>

                <p>The Page of Cups is a card in the Minor Arcana of the Tarot deck that represents new beginnings in emotions, creativity, and intuition. It signifies a youthful, open-hearted approach to life and a willingness to explore one's feelings and artistic talents. This card encourages the querent to embrace their inner child, be open to new emotional experiences, and express their creativity and intuition freely.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Page of Cups typically features a young person holding a cup from which a fish emerges, symbolizing unexpected inspiration and intuitive insights. The figure's attire and the calm sea in the background suggest a sense of openness, creativity, and emotional balance. The overall imagery emphasizes themes of new emotional beginnings, creativity, and intuitive exploration.</p>

                <p>The Page of Cups' symbolism emphasizes themes of new beginnings in emotions, creativity, and intuition. This card encourages the querent to embrace their inner child, be open to new emotional experiences, and express their creativity and intuition freely.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Page of Cups appears in a reading, it signifies new beginnings in emotions, creativity, and intuition. It suggests that the querent is in or will soon enter a period of youthful enthusiasm and openness to new emotional experiences. The Page of Cups encourages the querent to embrace their inner child, be open to new emotional experiences, and express their creativity and intuition freely.</p>

                <p>In the context of personal growth, the Page of Cups can indicate a period of emotional exploration and creative expression. It may suggest that the querent is discovering new aspects of their emotions and creativity, and is learning to trust their intuition. In relationships, the Page of Cups can signify the start of a new romance, a deepening of emotional connections, or a renewal of youthful enthusiasm in an existing relationship.</p>

                <p>In career and financial matters, the Page of Cups can signal opportunities for creative expression and intuitive insights. It advises the querent to embrace their creative talents, trust their intuition, and be open to new emotional experiences that can lead to personal and professional growth. The Page of Cups reminds the querent that with openness, creativity, and intuitive exploration, they can achieve their goals and find joy and fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Page of Cups in the Tarot deck is a powerful symbol of new beginnings in emotions, creativity, and intuition. By understanding and embracing the energy it represents, individuals can embrace their inner child, be open to new emotional experiences, and express their creativity and intuition freely. The Page of Cups encourages the querent to embrace their inner child, be open to new emotional experiences, and express their creativity and intuition freely. It is a reminder that with openness, creativity, and intuitive exploration, we can achieve our goals and find joy and fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
