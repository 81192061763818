import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function PrivacyPolicy(){

    useEffect(() => {
        document.title = 'Privacy Policy | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/privacy_policy",
        title:"privacy policy",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />
    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>

        <h1>Privacy Policy</h1>
        <p>Welcome to Cosmic Secrets. We are committed to protecting your privacy and ensuring that your personal information is handled responsibly. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website.</p>

        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
            <li><strong>Personal Information:</strong> This includes your name, email address, and any other information you provide when you subscribe to our newsletter, contact us, or participate in any interactive features of our website.</li>
            <li><strong>Non-Personal Information:</strong> This includes information that does not personally identify you, such as your browser type, operating system, IP address, and browsing behavior on our site.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
            <li>To provide and improve our services.</li>
            <li>To respond to your inquiries and fulfill your requests.</li>
            <li>To send you newsletters, updates, and promotional materials.</li>
            <li>To analyze website usage and improve our content and layout.</li>
            <li>To protect the security and integrity of our website.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>

        <h2>Cookies</h2>
        <p>We use cookies to enhance your experience on our website. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.</p>

        <h2>Your Consent</h2>
        <p>By using our site, you consent to our privacy policy.</p>

        <h2>Changes to Our Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:cosmic.secrets.info@gmail.com">cosmic.secrets.info@gmail.com</a>.</p>
    </div>
<Footer />
</>)}
