import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function Virgo(){

    useEffect(() => {
        document.title = 'Zodiac | Virgo | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/virgo",
        title:"virgo",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <div /><h1>Virgo: The Maiden (August 23 - September 22)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/virgo1.png' alt='Virgo the Maiden' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/leo' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Leo</Link>   
                    Next:      <Link to='/libra' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Libra</Link>
                </div>
                <p>Virgo is the sixth sign of the zodiac, symbolizing precision, service, and meticulousness. Represented by the Maiden (or Virgin Maiden), Virgo is ruled by Mercury, the planet of communication, intellect, and analysis. People born under this sign are known for their attention to detail, practicality, and strong analytical skills.</p>

                <h2>Personality Traits</h2>
                <p>Virgo individuals are characterized by their analytical and methodical nature. They have a keen eye for detail and a strong desire for perfection, often excelling in tasks that require precision and organization. Virgos are known for their practicality and reliability, often serving as the backbone of their social and professional circles. They take a thoughtful and systematic approach to life, ensuring that everything is in its proper place.</p>
                <p>Despite their sometimes critical and perfectionistic demeanor, Virgos have a deep sense of duty and a desire to help others. They are often modest and humble, preferring to work behind the scenes rather than seeking the spotlight. While their meticulous nature is a strength, it can also lead to overthinking and self-criticism, so they benefit from learning to accept imperfections and practice self-compassion.</p>

                <h2>Career</h2>
                <p>In the professional realm, Virgo excels in roles that require organization, attention to detail, and analytical thinking. They are well-suited for careers in healthcare, research, education, and any field that involves data analysis and meticulous planning. Virgos' reliability and strong work ethic make them valuable assets in any workplace.</p>
                <p>Virgo employees are known for their dedication and ability to produce high-quality work. They bring a sense of order and efficiency to their tasks, often becoming the go-to person for problem-solving and project management. However, they may need to work on their flexibility and ability to delegate, as their desire for perfection can sometimes lead to micromanagement.</p>

                <h2>Relationships</h2>
                <p>In relationships, Virgo is loyal and supportive. They seek partners who can appreciate their attention to detail and practical approach to life. Virgo values honesty and reliability, and they appreciate partners who are communicative and understanding. They are drawn to individuals who can provide stability and share their commitment to personal growth and improvement.</p>
                <p>As friends, Virgos are dependable and thoughtful. They are always ready to offer practical advice and help others with their organizational skills. In romantic relationships, they are caring and attentive, often showing their love through acts of service and attention to their partner's needs. However, their critical nature can sometimes lead to misunderstandings, so open communication and mutual respect are key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Virgo is a sign that embodies precision, service, and meticulousness. Their analytical nature, practicality, and strong sense of duty make them reliable and hardworking individuals. By embracing their strengths and working on their challenges, Virgo individuals can lead fulfilling lives filled with purpose, organization, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}