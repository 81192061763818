import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TwoOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Two of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "two_of_wands",
        title: "two_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Two of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/two_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands02.jpg' alt='Two of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ace_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ace of Wands</Link>   
                    <Link to='/three_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Three of Wands</Link>
                </div>
                <h2>Overview of the Two of Wands</h2>

                <p>The Two of Wands is a card in the Minor Arcana of the Tarot deck that represents planning, decision-making, and the exploration of possibilities. It signifies a period of contemplation and strategic thinking, where one is considering their options and making plans for the future. This card encourages the querent to evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Two of Wands typically features a figure standing on a castle wall, holding a globe in one hand and a wand in the other. The figure gazes out over a vast landscape, symbolizing the exploration of new opportunities and the consideration of future possibilities. The overall imagery emphasizes themes of planning, decision-making, and the strategic evaluation of one's path.</p>

                <p>The Two of Wands' symbolism emphasizes themes of planning, decision-making, and the exploration of possibilities. This card encourages the querent to evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Two of Wands appears in a reading, it signifies planning, decision-making, and the exploration of possibilities. It suggests that the querent is in or will soon enter a period of contemplation and strategic thinking, where they are considering their options and making plans for the future. The Two of Wands encourages the querent to evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight.</p>

                <p>In the context of personal growth, the Two of Wands can indicate a period of self-discovery and the pursuit of long-term goals. It may suggest that the querent is exploring new opportunities, assessing their potential, and making plans for their personal development. In relationships, the Two of Wands can signify the need for mutual planning and decision-making, encouraging the querent to work with their partner to set goals and explore future possibilities together.</p>

                <p>In career and financial matters, the Two of Wands can signal opportunities for growth and success through careful planning and strategic thinking. It advises the querent to evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight. The Two of Wands reminds the querent that with planning, decision-making, and the exploration of possibilities, they can achieve their ambitions and navigate their path with clarity and purpose.</p>

                <h2>Conclusion</h2>

                <p>The Two of Wands in the Tarot deck is a powerful symbol of planning, decision-making, and the exploration of possibilities. By understanding and embracing the energy it represents, individuals can evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight. The Two of Wands encourages the querent to evaluate their goals, weigh their choices, and prepare for the journey ahead with confidence and foresight. It is a reminder that with planning, decision-making, and the exploration of possibilities, we can achieve our ambitions and navigate our path with clarity and purpose.</p>

                <Footer />
            </div>
        </>
    )
}
