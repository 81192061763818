import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SixOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Six of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "six_of_wands",
        title: "six_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Six of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/six_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands06.jpg' alt='Six of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/five_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Five of Wands</Link>   
                    <Link to='/seven_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Seven of Wands</Link>
                </div>
                <h2>Overview of the Six of Wands</h2>

                <p>The Six of Wands is a card in the Minor Arcana of the Tarot deck that represents victory, recognition, and success. It signifies a period of triumph, public acknowledgment, and the achievement of one's goals. This card encourages the querent to celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Six of Wands typically features a figure riding a horse, holding a wand adorned with a laurel wreath, and being celebrated by a crowd. The scene depicts a sense of triumph, recognition, and public acknowledgment. The overall imagery emphasizes themes of victory, recognition, and the successful achievement of goals.</p>

                <p>The Six of Wands' symbolism emphasizes themes of victory, recognition, and success. This card encourages the querent to celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Six of Wands appears in a reading, it signifies victory, recognition, and success. It suggests that the querent is in or will soon enter a period of triumph, public acknowledgment, and the achievement of their goals. The Six of Wands encourages the querent to celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success.</p>

                <p>In the context of personal growth, the Six of Wands can indicate a period of self-discovery and the celebration of personal achievements. It may suggest that the querent is experiencing a sense of accomplishment and is ready to celebrate their successes. In relationships, the Six of Wands can signify a time of harmony, joy, and the recognition of mutual achievements and strengths.</p>

                <p>In career and financial matters, the Six of Wands can signal opportunities for growth and success through the recognition of achievements and the celebration of victories. It advises the querent to celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success. The Six of Wands reminds the querent that with victory, recognition, and success, they can achieve their goals and find joy and fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Six of Wands in the Tarot deck is a powerful symbol of victory, recognition, and success. By understanding and embracing the energy it represents, individuals can celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success. The Six of Wands encourages the querent to celebrate their victories, embrace their accomplishments, and enjoy the recognition and admiration that come with success. It is a reminder that with victory, recognition, and success, we can achieve our goals and find joy and fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
