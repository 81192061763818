import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function PageOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Page of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "page_of_wands",
        title: "page_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Page of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/page_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands11.jpg' alt='Page of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ten_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ten of Wands</Link>   
                    <Link to='/knight_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Knight of Wands</Link>
                </div>
                <h2>Overview of the Page of Wands</h2>

                <p>The Page of Wands is a card in the Minor Arcana of the Tarot deck that represents enthusiasm, exploration, and a youthful spirit. It signifies a period of curiosity, adventure, and the willingness to take on new challenges with excitement and energy. This card encourages the querent to embrace their inner child, be open to new experiences, and pursue their passions with enthusiasm and confidence.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Page of Wands typically features a young person holding a wand, standing in a desert landscape. The figure's attire and posture suggest a sense of adventure and eagerness to explore new horizons. The overall imagery emphasizes themes of enthusiasm, exploration, and a youthful spirit.</p>

                <p>The Page of Wands' symbolism emphasizes themes of enthusiasm, exploration, and a youthful spirit. This card encourages the querent to embrace their inner child, be open to new experiences, and pursue their passions with enthusiasm and confidence.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Page of Wands appears in a reading, it signifies enthusiasm, exploration, and a youthful spirit. It suggests that the querent is in or will soon enter a period of curiosity, adventure, and the willingness to take on new challenges with excitement and energy. The Page of Wands encourages the querent to embrace their inner child, be open to new experiences, and pursue their passions with enthusiasm and confidence.</p>

                <p>In the context of personal growth, the Page of Wands can indicate a period of self-discovery and the pursuit of new interests and passions. It may suggest that the querent is exploring new opportunities, embracing their creativity, and learning to trust their instincts. In relationships, the Page of Wands can signify the start of a new romance, the rekindling of excitement in a current relationship, or the introduction of playful and adventurous dynamics.</p>

                <p>In career and financial matters, the Page of Wands can signal opportunities for growth and success through enthusiasm and a willingness to take on new challenges. It advises the querent to embrace their creativity, be open to new experiences, and pursue their goals with enthusiasm and confidence. The Page of Wands reminds the querent that with enthusiasm, exploration, and a youthful spirit, they can achieve their goals and find joy and fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Page of Wands in the Tarot deck is a powerful symbol of enthusiasm, exploration, and a youthful spirit. By understanding and embracing the energy it represents, individuals can embrace their inner child, be open to new experiences, and pursue their passions with enthusiasm and confidence. The Page of Wands encourages the querent to embrace their inner child, be open to new experiences, and pursue their passions with enthusiasm and confidence. It is a reminder that with enthusiasm, exploration, and a youthful spirit, we can achieve our goals and find joy and fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
