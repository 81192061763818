import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacTiger() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Tiger | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_tiger",
        title: "chinese_zodiac_tiger",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Tiger</h1>
                
                    <Link to='/chinese_zodiac_tiger' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacTiger.png' alt='A tiger in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_ox' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Ox</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rabbit' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rabbit</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Tiger</h2>
                    <p>The Tiger is the third animal in the Chinese Zodiac cycle. Those born under this sign are known for their bravery, confidence, and competitiveness. Tigers are natural leaders who are not afraid to take risks. They are passionate and ambitious, often pursuing their goals with vigor and determination. Tigers are also known for their charm and charisma, making them popular in social settings. On the downside, they can be impulsive and may struggle with patience and planning.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Tiger</h2>
                    <p>The Tiger is the third sign in the Chinese Zodiac, and individuals born under this sign are known for their courage, confidence, and adventurous spirit. Tigers are natural leaders who are not afraid to take risks and face challenges head-on. Their boldness and determination make them formidable opponents and inspiring figures.</p>

                    <p>Tigers are also known for their passion and enthusiasm. They approach life with a sense of excitement and vigor, often pursuing their goals with relentless energy. This dynamic personality attracts others to them, and Tigers often find themselves at the center of attention. However, their intense nature can sometimes lead to impulsiveness and a lack of patience.</p>

                    <p>Despite their fearless exterior, Tigers have a sensitive and compassionate side. They care deeply about their loved ones and are always willing to stand up for those they believe in. Their strong sense of justice and fairness drives them to fight for the underdog and advocate for positive change. However, their protective nature can sometimes make them appear overbearing or domineering.</p>

                    <p>Tigers are also known for their independence and self-reliance. They value their freedom and often prefer to carve their own path rather than follow the crowd. While this makes them strong and resilient, it can also lead to a tendency to be overly self-reliant and resistant to seeking help from others. Learning to accept support and collaborate can help Tigers achieve even greater success and fulfillment.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Tiger</h2>
                <p>In their careers, Tigers excel in roles that require leadership, creativity, and courage. They are often successful in fields such as politics, entertainment, and sports, where their boldness and determination can shine. Tigers are natural motivators who inspire others with their passion and enthusiasm. Their adventurous spirit and willingness to take risks make them well-suited for careers that involve innovation and exploration.</p>

                <p>Tigers are known for their dynamic and energetic personalities. They thrive in fast-paced environments where they can take initiative and make an impact. Their ability to think outside the box and pursue ambitious goals often leads to groundbreaking achievements. However, their impulsiveness and tendency to act on instinct can sometimes lead to hasty decisions. It's important for Tigers to balance their drive with careful planning and consideration.</p>

                <p>When it comes to compatibility, Tigers are most compatible with the Dragon, Horse, and Pig. These signs complement the Tiger's traits and help to balance their dynamic nature. The Dragon offers inspiration and excitement, the Horse shares the Tiger's adventurous spirit, and the Pig provides compassion and understanding. Tigers may experience challenges in relationships with the Monkey and Snake, as these signs may clash with the Tiger's personality and create conflict. To maintain harmony, Tigers need to practice patience and empathy in their relationships.</p>
                 </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Tiger</h2>
                <p>Tigers are known for their energetic and dynamic nature, which generally helps them maintain good physical health. They enjoy engaging in physical activities and sports, which keep them fit and active. However, their adventurous spirit and tendency to take risks can sometimes lead to accidents or injuries. It's important for Tigers to practice caution and take care of their bodies to avoid unnecessary harm.</p>

                <p>Tigers can also be prone to stress and anxiety due to their ambitious and competitive nature. They need to find effective ways to manage stress, such as through relaxation techniques, meditation, or engaging in creative activities that allow them to express themselves. Tigers benefit from a balanced lifestyle that includes time for both work and leisure.</p>

                <p>In terms of lifestyle, Tigers enjoy excitement and novelty. They are often drawn to activities that challenge them both physically and mentally, such as travel, adventure sports, or exploring new hobbies. Tigers also value their independence and enjoy having the freedom to pursue their interests. However, it's important for them to maintain a healthy balance and avoid overextending themselves.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_ox' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Ox</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rabbit' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rabbit</Link>
                </div>
                </div>
                <Footer />
        </>
    )
}
