import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function Chald6() {
    
    useEffect(() => {
        document.title = 'Chaldean Numerology | Chaldean Number 6 | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/chaldean_number_6",
        title:"chaldean_number_6",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>
        <ChaldComponant chNum = {6}/>

        <div style={{maxWidth:'900px', margin:'auto'}}>
            <h1>Chaldean Number 6: The Nurturer</h1>
            <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/chaldean_number_5' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 5</Link>   
                Next:      <Link to='/chaldean_number_7' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 7</Link>
             </div>
            <p>Individuals with a Chaldean number of 6 are compassionate and responsible, symbolizing love, harmony, and balance. The number 6 is associated with Venus, representing beauty, harmony, and nurturing. People with this number are known for their caring nature, strong sense of duty, and ability to create harmonious environments.</p>

            <h2>Personality Traits</h2>
            <p>People with a Chaldean number of 6 are characterized by their nurturing and empathetic nature. They have a strong desire to care for others and often take on the role of protector and caregiver. These individuals are highly compassionate and value relationships, always striving to maintain harmony and balance in their interactions. Their kindness and reliability make them beloved friends and partners.</p>
            <p>Despite their sometimes overprotective and self-sacrificing demeanor, number 6 individuals have a deep appreciation for beauty and aesthetics. They are often creative and enjoy expressing themselves through art, music, or design. While their nurturing nature is a strength, it can also lead to neglecting their own needs, so they benefit from practicing self-care and setting healthy boundaries.</p>

            <h2>Career</h2>
            <p>In the professional realm, individuals with a Chaldean number of 6 excel in roles that require empathy, responsibility, and a harmonious approach. They are well-suited for careers in healthcare, education, counseling, and any field that involves helping others and creating positive environments. Their ability to connect with people and provide support makes them valuable assets in any team.</p>
            <p>Number 6 employees are known for their dedication and ability to create a supportive work atmosphere. They bring a sense of harmony and balance to their tasks, often becoming the go-to person for resolving conflicts and offering assistance. However, they may need to work on their ability to delegate and prioritize their own well-being, as their desire to help others can sometimes lead to burnout.</p>

            <h2>Relationships</h2>
            <p>In relationships, individuals with a Chaldean number of 6 are loving and devoted. They seek partners who can provide emotional security and appreciate their nurturing nature. Number 6 values loyalty and trust, and they appreciate partners who are caring and dependable. They are drawn to individuals who can share their commitment to creating a harmonious and loving relationship.</p>
            <p>As friends, number 6 individuals are supportive and reliable. They are always ready to offer a listening ear and help others in times of need. In romantic relationships, they are affectionate and enjoy creating a comfortable and nurturing environment for their partners. However, their tendency to prioritize others can sometimes lead to neglecting their own needs, so finding a balance between giving and receiving is key to maintaining healthy relationships.</p>

            <h2>Conclusion</h2>
            <p>Overall, individuals with a Chaldean number of 6 embody love, harmony, and responsibility. Their nurturing nature, strong sense of duty, and ability to create harmonious environments make them compassionate and reliable individuals. By embracing their strengths and working on their challenges, number 6 individuals can lead fulfilling lives filled with love, beauty, and meaningful connections.</p>
        </div>


     

      </div>
      <Footer />
      </>
    )
}
