import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Pisces(){

    useEffect(() => {
        document.title = 'Zodiac | Pisces | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/pisces",
        title:"pisces",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Pisces: The Fish (February 19 - March 20)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/pisces1.png' alt='Pisces the Fish' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/aquarius' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Aquarius</Link>   
                    Next:      <Link to='/aries' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Aries</Link>
                </div>
                <p>Pisces is the twelfth sign of the zodiac, symbolizing intuition, empathy, and spirituality. Represented by the Fish, Pisces is ruled by Neptune, the planet of dreams, imagination, and mysticism. People born under this sign are known for their compassionate nature, artistic talents, and deep emotional sensitivity.</p>

                <h2>Personality Traits</h2>
                <p>Pisces individuals are characterized by their empathetic and intuitive nature. They are highly sensitive to the emotions of others and often possess a deep understanding of the human condition. Pisces are known for their creativity and imagination, often excelling in artistic and musical pursuits. They have a natural ability to connect with others on an emotional level, making them compassionate and caring friends.</p>
                <p>Despite their gentle and dreamy demeanor, Pisces can sometimes struggle with boundaries and may be prone to escapism. They are highly idealistic and may find it challenging to face harsh realities. While their empathy is one of their greatest strengths, it can also make them vulnerable to emotional overwhelm.</p>

                <h2>Career</h2>
                <p>In the professional realm, Pisces excels in roles that require creativity, compassion, and intuition. They are well-suited for careers in the arts, music, healing professions, and any field that allows them to express their creativity and help others. Pisces' empathetic nature makes them excellent counselors, therapists, and caregivers.</p>
                <p>Pisces employees are known for their adaptability and willingness to help others. They bring a sense of creativity and emotional intelligence to their work, often coming up with innovative solutions and ideas. However, they may need to work on their assertiveness and ability to set boundaries, as their desire to please others can sometimes lead to burnout.</p>

                <h2>Relationships</h2>
                <p>In relationships, Pisces is affectionate and deeply loving. They seek partners who can understand and appreciate their emotional depth and sensitivity. Pisces values emotional connection and intimacy, and they appreciate partners who are kind, compassionate, and supportive. They are drawn to individuals who can provide stability and security in their lives.</p>
                <p>As friends, Pisces are loyal and supportive. They are always ready to lend a listening ear and offer comfort to those in need. In romantic relationships, they are nurturing and devoted, often putting their partner's needs before their own. However, their sensitivity can sometimes lead to emotional fluctuations, so open communication and mutual understanding are key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Pisces is a sign that embodies intuition, empathy, and spirituality. Their compassionate nature, artistic talents, and emotional depth make them inspiring and understanding individuals. By embracing their strengths and working on their challenges, Pisces individuals can lead fulfilling lives filled with love, creativity, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}