import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SixOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Six of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "six_of_cups",
        title: "six_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Six of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/six_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups06.jpg' alt='Six of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/five_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Five of Cups</Link>   
                    <Link to='/seven_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Seven of Cups</Link>
                </div>
                <h2>Overview of the Six of Cups</h2>

                <p>The Six of Cups is a card in the Minor Arcana of the Tarot deck that represents nostalgia, childhood, and past memories. It signifies a period of reflection and sentimental longing for the past. This card encourages the querent to embrace their memories, learn from past experiences, and find joy in the simple pleasures of life. It also suggests reconnecting with old friends or revisiting places that hold sentimental value.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Six of Cups typically features two children exchanging cups filled with flowers in a garden. The scene evokes a sense of innocence, joy, and the simple pleasures of childhood. In the background, there is often a house, symbolizing security and comfort. The overall imagery emphasizes themes of nostalgia, childhood, and the joy found in simple, heartfelt moments.</p>

                <p>The Six of Cups' symbolism emphasizes themes of nostalgia, childhood, and past memories. This card encourages the querent to embrace their memories, learn from past experiences, and find joy in the simple pleasures of life. It also suggests reconnecting with old friends or revisiting places that hold sentimental value.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Six of Cups appears in a reading, it signifies nostalgia, childhood, and past memories. It suggests that the querent is in a period of reflection, feeling sentimental and longing for the simplicity and joy of the past. The Six of Cups encourages the querent to embrace their memories, learn from past experiences, and find joy in the simple pleasures of life. It also suggests reconnecting with old friends or revisiting places that hold sentimental value.</p>

                <p>In the context of personal growth, the Six of Cups can indicate a period of reflection and the need to reconnect with one's roots. It may suggest that the querent is drawing on past experiences and memories to find comfort and guidance. In relationships, the Six of Cups can signify the rekindling of old friendships, the deepening of existing relationships through shared memories, or the return of a past lover.</p>

                <p>In career and financial matters, the Six of Cups can signal opportunities for growth through learning from past experiences and embracing the joy of one's work. It advises the querent to draw on their past successes and experiences to find inspiration and motivation. The Six of Cups reminds the querent that with reflection, nostalgia, and the joy of simple pleasures, they can find fulfillment and success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Six of Cups in the Tarot deck is a powerful symbol of nostalgia, childhood, and past memories. By understanding and embracing the energy it represents, individuals can find comfort in their memories, learn from past experiences, and find joy in the simple pleasures of life. The Six of Cups encourages the querent to embrace their memories, learn from past experiences, and find joy in the simple pleasures of life. It is a reminder that with reflection, nostalgia, and the joy of simple pleasures, we can find fulfillment and success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
