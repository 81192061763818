import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SevenOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Seven of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "seven_of_pentacles",
        title: "seven_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Seven of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/seven_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents07.jpg' alt='Seven of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/six_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Six of Pentacles</Link>   
                    <Link to='/eight_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Eight of Pentacles</Link>
                </div>
                <h2>Overview of the Seven of Pentacles</h2>

                <p>The Seven of Pentacles is a card in the Minor Arcana of the Tarot deck that represents patience, perseverance, and long-term planning. It signifies a period of assessment, reflection, and the evaluation of one's progress and efforts. This card encourages the querent to be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort.</p>

                <h3>Symbolism and Imagery</h3>

                <p>The imagery on the Seven of Pentacles typically features a figure standing and contemplating a bush with seven pentacles growing on it. The scene depicts a sense of assessment, reflection, and the results of one's hard work. The overall imagery emphasizes themes of patience, perseverance, and the evaluation of progress and efforts.</p>

                <p>The Seven of Pentacles' symbolism emphasizes themes of patience, perseverance, and long-term planning. This card encourages the querent to be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Seven of Pentacles appears in a reading, it signifies patience, perseverance, and long-term planning. It suggests that the querent is in or will soon enter a period of assessment, reflection, and the evaluation of their progress and efforts. The Seven of Pentacles encourages the querent to be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort.</p>

                <p>In the context of personal growth, the Seven of Pentacles can indicate a period of self-discovery and the development of patience and perseverance. It may suggest that the querent is learning to assess their progress, reflect on their efforts, and plan for the future. In relationships, the Seven of Pentacles can signify the need to evaluate the progress of the partnership, invest in long-term goals, and be patient in the face of challenges.</p>

                <p>In career and financial matters, the Seven of Pentacles can signal opportunities for growth and success through patience, perseverance, and long-term planning. It advises the querent to be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort. The Seven of Pentacles reminds the querent that with patience, perseverance, and long-term planning, they can achieve their goals and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Seven of Pentacles in the Tarot deck is a powerful symbol of patience, perseverance, and long-term planning. By understanding and embracing the energy it represents, individuals can be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort. The Seven of Pentacles encourages the querent to be patient, continue working towards their goals, and recognize the importance of long-term planning and sustained effort. It is a reminder that with patience, perseverance, and long-term planning, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
