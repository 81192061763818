import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacPig() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Pig | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_pig",
        title: "chinese_zodiac_pig",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Pig</h1>
                    <Link to='/chinese_zodiac_pig' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacPig.png' alt='A pig in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_dog' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Dog</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rat</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Pig</h2>
                    <p>The Pig is the twelfth and final animal in the Chinese Zodiac cycle. Those born under this sign are known for their generosity, diligence, and kindness. Pigs are compassionate and enjoy helping others. They are also hardworking and determined, often seeing tasks through to completion. However, their trusting nature can sometimes make them vulnerable to deception and exploitation.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Pig</h2>
                    <p>The Pig is the twelfth and final sign in the Chinese Zodiac, and those born under this sign are known for their generosity, diligence, and kindness. Pigs are compassionate and enjoy helping others. They are also hardworking and determined, often seeing tasks through to completion. However, their trusting nature can sometimes make them vulnerable to deception and exploitation.</p>

                    <p>Pigs are also known for their empathy and understanding. They are excellent listeners and often provide emotional support to those in need. Their gentle and caring nature makes them cherished friends and partners. However, their sensitivity can sometimes make them prone to indecision and self-doubt.</p>

                    <p>Despite their gentle nature, Pigs have a strong sense of determination and resilience. They are capable of working diligently towards their goals and achieving success through perseverance. However, their aversion to conflict can sometimes lead them to avoid difficult situations or decisions. It is important for Pigs to develop assertiveness and confidence in expressing their needs and opinions.</p>

                    <p>Pigs are also known for their love of beauty and aesthetics. They have a keen eye for detail and enjoy surrounding themselves with art and nature. This appreciation for the finer things in life often translates into a refined and elegant lifestyle. However, their desire for comfort and security can sometimes make them reluctant to step out of their comfort zones and take risks.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Career and Compatibility of the Pig</h2>
                    <p>In their careers, Pigs excel in roles that require diligence, compassion, and a strong work ethic. They are often successful in fields such as healthcare, education, and social work, where their compassionate and nurturing nature can shine. Pigs are excellent team players who can build strong, supportive relationships with colleagues. Their ability to work hard and stay determined often leads to successful and fulfilling careers.</p>

                    <p>Pigs are known for their empathy and understanding. They thrive in environments that allow them to help and support others. Their dedication and commitment often lead to significant contributions and achievements in their fields. However, their trusting nature can sometimes make them vulnerable to deception and exploitation. It's important for Pigs to develop discernment and protect themselves from potential harm.</p>

                    <p>When it comes to compatibility, Pigs are most compatible with the Rabbit, Goat, and Tiger. These signs complement the Pig's traits and help to balance their generous nature. The Rabbit offers empathy and understanding, the Goat provides compassion and kindness, and the Tiger shares the Pig's determination and enthusiasm. Pigs may experience challenges in relationships with the Snake and Monkey, as these signs may clash with the Pig's personality and create conflict. To maintain harmony, Pigs need to practice clear communication and assertiveness in their relationships.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Pig</h2>
                <p>Pigs generally enjoy good health, thanks to their gentle and calm nature. They are usually mindful of their well-being and take care to maintain a balanced lifestyle. However, their sensitivity and tendency to worry can sometimes lead to stress and anxiety. It's important for Pigs to find ways to relax and unwind, such as through meditation, yoga, or spending time in nature.</p>

                <p>Pigs are prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Pigs maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Pigs enjoy peaceful and harmonious environments. They are often drawn to activities that promote relaxation and creativity, such as reading, gardening, or engaging in artistic pursuits. Pigs also value comfort and enjoy creating cozy, welcoming homes. Surrounding themselves with beauty and tranquility helps them maintain a positive and balanced outlook on life.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_dog' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Dog</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rat</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
