import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function KingOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | King of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "king_of_swords",
        title: "king_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>King of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/king_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords14.jpg' alt='King of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/queen_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Queen of Swords</Link>   
                    <Link to='/ace_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Ace of Swords</Link>
                </div>
                <h2>Overview of the King of Swords</h2>

                <p>The King of Swords is a card in the Minor Arcana of the Tarot deck that represents authority, integrity, and intellectual power. It signifies a period of clarity, decisiveness, and the ability to lead with wisdom and justice. This card encourages the querent to embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the King of Swords typically features a regal figure seated on a throne, holding a raised sword, and looking forward with a confident and authoritative expression. The scene depicts a sense of authority, integrity, and intellectual power. The overall imagery emphasizes themes of clarity, decisiveness, and the pursuit of wisdom and justice.</p>

                <p>The King of Swords' symbolism emphasizes themes of authority, integrity, and intellectual power. This card encourages the querent to embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the King of Swords appears in a reading, it signifies authority, integrity, and intellectual power. It suggests that the querent is in or will soon enter a period of clarity, decisiveness, and the ability to lead with wisdom and justice. The King of Swords encourages the querent to embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others.</p>

                <p>In the context of personal growth, the King of Swords can indicate a period of self-discovery and the development of authority and integrity. It may suggest that the querent is learning to appreciate the value of clarity, decisiveness, and the pursuit of wisdom and justice. In relationships, the King of Swords can signify the need for clear communication, mutual respect, and the importance of integrity and fairness within the partnership.</p>

                <p>In career and financial matters, the King of Swords can signal opportunities for growth and success through authority, integrity, and intellectual power. It advises the querent to embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others. The King of Swords reminds the querent that with authority, integrity, and intellectual power, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The King of Swords in the Tarot deck is a powerful symbol of authority, integrity, and intellectual power. By understanding and embracing the energy it represents, individuals can embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others. The King of Swords encourages the querent to embrace their authority, make fair and just decisions, and use their intellectual power to guide and inspire others. It is a reminder that with authority, integrity, and intellectual power, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
