import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Gemini(){

    useEffect(() => {
        document.title = 'Zodiac | Gemini | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/gemini",
        title:"gemini",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Gemini: The Twins (May 21 - June 20)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/gemini1.png' alt='Gemini the Twins' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/taurus' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Taurus</Link>   
                    Next:      <Link to='/cancer' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Cancer</Link>
                </div>
                <p>Gemini is the third sign of the zodiac, symbolizing communication, adaptability, and curiosity. Represented by the Twins, Gemini is ruled by Mercury, the planet of intellect, communication, and travel. People born under this sign are known for their versatility, quick wit, and vibrant social energy.</p>

                <h2>Personality Traits</h2>
                <p>Gemini individuals are characterized by their dual nature, reflecting the sign of the Twins. They are known for their intellectual curiosity and love of learning. Geminis thrive on variety and change, often juggling multiple interests and projects at once. Their adaptability and flexibility make them excellent problem solvers and communicators.</p>
                <p>Despite their playful and energetic demeanor, Geminis can also be deeply thoughtful and reflective. They possess a natural charm and can easily engage in conversations with people from all walks of life. However, their dual nature can sometimes lead to inconsistency or indecision, as they may struggle to focus on one thing for too long.</p>

                <h2>Career</h2>
                <p>In the professional realm, Gemini excels in roles that require communication, creativity, and adaptability. They are well-suited for careers in journalism, teaching, marketing, and any field that involves interaction with others. Geminis' quick thinking and ability to multitask make them valuable assets in dynamic and fast-paced environments.</p>
                <p>Gemini employees are known for their enthusiasm and innovative ideas. They bring a fresh perspective to their work and are often the ones who introduce new concepts and approaches. However, they may need to work on their focus and consistency, as their tendency to shift between interests can sometimes hinder long-term projects.</p>

                <h2>Relationships</h2>
                <p>In relationships, Gemini is lively and engaging. They seek partners who can stimulate them intellectually and share their love of adventure. Gemini values communication and enjoys discussing a wide range of topics with their partners. They appreciate relationships that offer both mental and emotional connection.</p>
                <p>As friends, Geminis are entertaining and supportive. They are always up for new experiences and enjoy bringing people together. In romantic relationships, they are affectionate and enjoy spontaneity. However, their need for variety and excitement can sometimes lead to restlessness, so finding a balance between novelty and stability is key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Gemini is a sign that embodies communication, adaptability, and curiosity. Their intellectual energy, versatility, and charm make them engaging and dynamic individuals. By embracing their strengths and working on their challenges, Gemini individuals can lead fulfilling lives filled with exploration, learning, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}