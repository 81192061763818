import './Navbar.css';
import BellIcon from '../icons/bell.png';
import MessengerIcon from '../icons/messenger.png';
import CaretIcon from '../icons/caret.png';
import PlusIcon from '../icons/plus.png';
import CogIcon from '../icons/cog.png';
import ChevronIcon from '../icons/chevron.png';
import ArrowIcon from '../icons/arrow.png';
import BoltIcon from '../icons/bolt.png'

import {useNavigate, Link} from 'react-router-dom';
//import{UserContext} from '../../../context/userContext';
//import { useStyle } from '../../../context/styleContext';

import React, { useState, useEffect, useContext, useRef, Button} from 'react';
import { CSSTransition } from 'react-transition-group';



export default function NavBar(){

  //const{user} = useContext(UserContext);
  //const { style, setStyle } = useStyle();


  //document.body.style.backgroundColor = style.color2;
  //document.body.style.color = style.textColor1;


  const [isHovered, setIsHovered] = useState(false);

  const hoveredColors = {
    //textColor1: 'orange', // Replace with your dynamic value
  };




  return (


      <header className = 'navbarOuterContainer'>
        <nav className = 'navbarInnerContainer'>
        <div className = 'navbarHome'>          
        <div className = 'homeLinkContainer'>
          <Link to='/' className= 'headerLinkText'>Home</Link>
        </div>
      </div>

      <NavItem label="Tarot">
        <TarotDropdown></TarotDropdown>
      </NavItem>
      <NavItem label="Zodiac">
        <ZodiacDropdown></ZodiacDropdown>
      </NavItem>
      <NavItem label="Numerology">
        <NumerologyDropdown></NumerologyDropdown>
      </NavItem>
        </nav>
      </header>

  );
}

// function Navbar(props) {
//   return (
//     <nav className="navbar">
//       <ul className="navbar-nav">{props.children}</ul>
//     </nav>
//   );
// }

// function NavItem(props) {
//   const [open, setOpen] = useState(false);

//   return (
//     <li className="nav-item">
//       <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
//         {props.icon}
//       </a>

//       {open && props.children}
//     </li>
//   );
// }
function NavItem(props) {
  const [open, setOpen] = useState(false);
  const ref = useRef(); // Reference to the NavItem component

  // Event handler to close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Attach event listener when component mounts and remove on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li className="nav-item" ref={ref} style={{display:'flex', flex:1}}>
      <a href="#" className="icon-button" onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior
        setOpen(!open);
      }}>

            {props.label}
            {props.icon}
      </a>

      {open && props.children}
    </li>
  );
}

function TarotDropdown() {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate() 

  //const { style, setStyle } = useStyle();

  const tarotLink = () => {
    navigate('/tarot')
  };
  const majorArcanaLink = () => {
    navigate('/major_arcana')
  };
  const minorArcanaLink = () => {
    navigate('/minor_arcana')
  };
  const foolsJourneyLink = () => {
    navigate('/fools_journey')
  };
  const swordsLink = () => {
    navigate('/swords')
  };
  const cupsLink = () => {
    navigate('/cups')
  };
  const wandsLink = () => {
    navigate('/wands')
  };
  const pentaclesLink = () => {
    navigate('/pentacles')
  };


  

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight + 50;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>

      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu" style={{ justifyContent:'center' , alignItems:'center'}}>
            
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={tarotLink} >Tarot Overview </button>
            </div>   
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={majorArcanaLink} >Major Arcana </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={minorArcanaLink} >Minor Arcana </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={foolsJourneyLink} >The Fool's Journey </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={wandsLink} >Wands </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={swordsLink} >Swords </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={cupsLink} >Cups </button>
            </div>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={pentaclesLink} >Pentacles </button>
            </div>
     
        </div>

      </CSSTransition>
      


    </div>
  );
}

function NumerologyDropdown() {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate() 

  //const { style, setStyle } = useStyle();

  const numerologyLink = () => {
    navigate('/numerology')
  };
  const chaldeanNumerologyLink = () => {
    navigate('/chaldean_numerology')
  };
  const pythagoreanNumerologyLink = () => {
    navigate('/pythagorean_numerology')
  };
  const kabNumerologyLink = () => {
    navigate('/kabbalistic_numerology')
  };
  const chineseNumerologyLink = () => {
    navigate('/chinese_numerology')
  };

  const settingsLink = () => {
    navigate('/settings')
  };
  const logoutLink = async (e) => {
    e.preventDefault()

    console.log("Requesting to logout..")
    const response = await fetch('http://localhost:5000/logout', {
        method: 'GET',
        credentials: 'include' // to ensure cookies are sent
    });
    const data = await response.json();
    console.log(data.message); // Or handle redirect to login page
    //setUser(null);
    navigate('/login')

    };


  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight + 50;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>

      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu" style={{ justifyContent:'center' , alignItems:'center'}}>
            <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={numerologyLink} >Numerology Overview </button>
            </div>   

        
            <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={pythagoreanNumerologyLink} >Pythagorean Numerology</button>
            </div>

            <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={chaldeanNumerologyLink} >Chaldean Numerology</button>
            </div>
            <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={kabNumerologyLink} >Kabbalistic Numerology</button>
            </div>
            <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
            <button className = "dropdownButton" onClick={chineseNumerologyLink} >Chinese Numerology</button>
            </div>     
        </div>

      </CSSTransition>
      


    </div>
  );
}

function ZodiacDropdown() {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate() 
  
    //const { style, setStyle } = useStyle();
  
    const zodiacLink = () => {
      navigate('/zodiac')
    };

    const chineseZodiacLink = () => {
      navigate('/chinese_zodiac')
    };

    const ariesLink = () => {
      navigate('/aries')
    };

    const taurusLink = () => {
        navigate('/taurus')
    }; 
    const geminiLink = () => {
        navigate('/gemini')
    };  

    const cancerLink = () => {
        navigate('/cancer')
    };

    const leoLink = () => {
        navigate('/leo')
    };

    const virgoLink = () => {
        navigate('/virgo')
    };
    const libraLink = () => {
        navigate('/libra')
    };
    const scorpioLink = () => {
        navigate('/scorpio')
    };
    const sagittariusLink = () => {
        navigate('/sagittarius')
    };
    const capricornLink = () => {
        navigate('/capricorn')
    };

    const aquariusLink = () => {
        navigate('/aquarius')
    };

    const piscesLink = () => {
        navigate('/pisces')
    };

    

  
  
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    function calcHeight(el) {
      const height = el.offsetHeight + 50;
      setMenuHeight(height);
    }
  
    // function DropdownItem(props) {
    //   return (
    //     <a className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
    //       <span className="icon-button">{props.leftIcon}</span>
    //       {props.children}
    //       <span className="icon-right">{props.rightIcon}</span>
    //     </a>
    //   );
    // }
  
    return (
      <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
  
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu" style={{ justifyContent:'center' , alignItems:'center', backgroundColor:'rgb(0,0,0)'}}>
              <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={zodiacLink} >Zodiac</button>
              </div> 

              <div className="dropdownRow" style={{ display:'flex', flex:1, justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={chineseZodiacLink} >Chinese Zodiac</button>
              </div>   
  
          
              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={ariesLink} >Aries</button>
              </div>
  
              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={taurusLink} >Taurus</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={geminiLink} >Gemini</button>
              </div>  

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={cancerLink} >Cancer</button>
              </div>  

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={leoLink} >Leo</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={virgoLink} >Virgo</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={libraLink} >Libra</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={scorpioLink} >Scorpio</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={sagittariusLink} >Sagittarius</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={capricornLink} >Capricorn</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={aquariusLink} >Aquarius</button>
              </div>

              <div className="dropdownRow" style={{ justifyContent:'center' , alignItems:'center'}}>
              <button className = "dropdownButton" onClick={piscesLink} >Pisces</button>
              </div>     
          </div>
  
        </CSSTransition>
        
  
  
      </div>
    );
  }








// import {useNavigate, Link} from 'react-router-dom';


// export default function Navbar(){

//     return(
//         <>
//         <div style={{display:'flex', flexDirection:'row', width:'100%', margin:'auto', justifyContent:'center', backgroundColor:'rgb(46,45,41)', minHeight:'40px'}}>
//             <div style={{display:'flex', flex:1}}>
//                 <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center'}}>
//                     <Link to='/' style={{fontSize:'1em', textDecoration:'none', fontWeight:'bold', textAlign:'center'}}>Home</Link>  
//                 </div>
//                 <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', }}>
//                     <Link to='/numerology' style={{fontSize:'1em', textDecoration:'none', fontWeight:'bold', alignContent:'center', textAlign:'center'}}>Numerology</Link>
                    
//                 </div>
//                 <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', }}>
//                     <Link to='/zodiac' style={{fontSize:'1em', textDecoration:'none', fontWeight:'bold', textAlign:'center'}}>Zodiac</Link>                  
//                 </div>
//                 <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', }}>
//                     <Link to='/tarot' style={{fontSize:'1em', textDecoration:'none', fontWeight:'bold', textAlign:'center'}}>Tarot</Link>                  
//                 </div>
//                 <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', }}>
//                     <dropdown style={{backgroundColor:'red'}}>
//                         <option style={{marginBotom:'25px', backgroundColor:'blue'}}><button>yo </button></option>
//                         <option>Option2</option>
//                         bawitaba
//                         <option>Option3</option>
//                     </dropdown>
//                 </div>
//             </div>
//         </div>
//         </>
//     )

// }