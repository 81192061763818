import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TowerTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Tower Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "tower_tarot",
        title: "tower_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Tower</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/tower_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/16_Tower.jpg' alt='Tower Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Tower</h2>

                <p>The Tower is the sixteenth card in the Major Arcana of the Tarot deck, numbered as 16. It represents sudden upheaval, chaos, and revelation. The Tower is a symbol of unexpected change, destruction of false beliefs, and the breaking down of old structures. This card encourages the querent to embrace change, recognize the necessity of breaking down the old to make way for the new, and understand that from chaos comes clarity and new beginnings.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Tower card typically features a tall tower being struck by lightning, with flames erupting from its top and figures falling from it. The lightning bolt represents sudden and divine intervention, bringing illumination and destruction to false structures. The falling figures symbolize the breaking down of old beliefs and the chaos that ensues. The overall imagery emphasizes themes of sudden upheaval, destruction, and the revelation of truth.</p>

                <p>The Tower's number, 16, signifies transformation, upheaval, and the breaking down of old structures. This card's symbolism encourages the querent to embrace change, recognize the necessity of breaking down the old to make way for the new, and understand that from chaos comes clarity and new beginnings.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Tower card appears in a reading, it signifies sudden upheaval, chaos, and revelation. It suggests that the querent is experiencing or will soon experience a major change or disruption in their life. The Tower card encourages the querent to embrace this change, recognize the necessity of breaking down old structures and beliefs, and understand that from chaos comes clarity and new beginnings.</p>

                <p>In the context of personal growth, the Tower card can indicate a period of transformation and the breaking down of old beliefs. It may suggest that the querent is undergoing a significant upheaval that will lead to greater clarity and self-awareness. In relationships, the Tower card can signify the need to confront issues, break down false beliefs, and embrace change to build a stronger foundation.</p>

                <p>In career and financial matters, the Tower card can signal sudden changes, disruptions, and the need to adapt. It advises the querent to embrace these changes, recognize the opportunity to break down old structures, and build anew. The Tower card reminds the querent that from chaos comes clarity and new beginnings, and that embracing change can lead to greater growth and success.</p>

                <h2>Conclusion</h2>

                <p>The Tower card in the Tarot deck is a powerful symbol of sudden upheaval, chaos, and revelation. By understanding and embracing the energy it represents, individuals can recognize the necessity of breaking down old structures, embrace change, and understand that from chaos comes clarity and new beginnings. The Tower card encourages the querent to embrace change, recognize the opportunity to break down old structures, and build anew. It is a reminder that from chaos comes clarity and new beginnings, and that embracing change can lead to greater growth and success.</p>

                <Footer />
            </div>
        </>
    )
}
