import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacRat() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Rat | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_rat",
        title: "chinese_zodiac_rat",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop:'25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                <h1><Link to='/chinese_zodiac' style={{ textDecoration:'none', color:'white', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Chinese Zodiac </Link>- Rat</h1>
                    <Link to='/chinese_zodiac_rat' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacRat.png' alt='A rat in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop:'25px' }}>
                
                    <Link to='/chinese_zodiac_pig' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'75px'}}>Previous: Pig</Link>
                    <Link to='/chinese_zodiac' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'75x', marginRight:'75px'}}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_ox' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Next: Ox</Link>

                </div>
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>Overview of the Rat</h2>
                    <p>The Rat is the first animal in the Chinese Zodiac cycle. Those born under this sign are known for their intelligence, resourcefulness, and quick wit. Rats are highly adaptable and can easily navigate through various challenges and situations. They are charming, sociable, and have a natural ability to attract others. However, they can also be perceived as opportunistic and may struggle with trust issues.</p>
                </div>
                
                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                    <h2>Personality Traits of the Rat</h2>
                    <p>The Rat is the first sign in the Chinese Zodiac, and individuals born under this sign are known for their intelligence, charm, and quick-witted nature. Rats are highly resourceful and adaptable, making them excellent problem solvers who can navigate through life's challenges with ease. They are curious and always eager to learn, which contributes to their broad knowledge and ability to think on their feet.</p>

                    <p>Rats are also known for their sociability and ability to make friends easily. Their charm and charisma often attract others, and they enjoy being surrounded by people. However, their sociable nature can sometimes mask a more cautious and secretive side. Rats are careful about whom they trust and often keep their true feelings and thoughts guarded, revealing them only to those they trust deeply.</p>

                    <p>Despite their positive traits, Rats can be overly ambitious and competitive. Their desire to achieve success and recognition can sometimes lead them to take risks or make hasty decisions. Additionally, they may struggle with stress and anxiety due to their high expectations and perfectionist tendencies. Nevertheless, their resilience and determination often see them through difficult times, allowing them to bounce back and continue pursuing their goals.</p>

                    <p>Rats are also known for their loyalty and dedication, especially towards their family and close friends. They value their relationships deeply and are always willing to lend a helping hand or offer support. However, their protective nature can sometimes make them appear possessive or controlling. It is important for Rats to balance their loyalty with respect for others' independence and individuality.</p>
                </div>

                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                <h2>Career and Compatibility of the Rat</h2>
                <p>In their careers, Rats excel due to their intelligence, adaptability, and resourcefulness. They are often successful in fields that require quick thinking and problem-solving skills, such as business, research, and technology. Rats are natural leaders who can motivate and inspire others with their charm and charisma. Their ability to think on their feet and navigate complex situations makes them valuable assets in any professional environment.</p>

                <p>Rats are known for their analytical skills and strategic thinking. They have a knack for identifying opportunities and making sound decisions that lead to success. Their curiosity and eagerness to learn keep them at the forefront of their fields, constantly seeking new knowledge and skills. However, their ambition and drive can sometimes lead to stress and burnout, so it's essential for Rats to find a balance between work and relaxation.</p>

                <p>When it comes to compatibility, Rats are most compatible with the Ox, Dragon, and Monkey. These signs complement the Rat's traits and help to balance their ambitious nature. The Ox provides stability and reliability, the Dragon offers inspiration and excitement, and the Monkey shares the Rat's quick wit and resourcefulness. Rats may experience challenges in relationships with the Horse and Rooster, as these signs may clash with the Rat's personality and create conflict. To maintain harmony, Rats need to practice patience and understanding in their relationships.</p>
                 </div>

                <div style={{padding:'20px', borderRadius:'12px', borderColor:'#646cff', borderWidth:'2px', borderStyle:'solid', marginBottom:'50px', marginTop:'50px'}}>
                <h2>Health and Lifestyle of the Rat</h2>
                <p>Rats generally enjoy good health, thanks to their active and energetic nature. They are always on the go, which helps them maintain a good level of physical fitness. However, their ambitious and hardworking nature can sometimes lead to stress and burnout. Rats need to ensure they balance their work with relaxation and leisure activities to maintain their overall well-being. Regular exercise, a balanced diet, and sufficient sleep are essential for Rats to stay healthy and energized.</p>

                <p>Rats are also prone to overthinking and anxiety, which can affect their mental health. It's important for them to find effective ways to manage stress and anxiety, such as through meditation, yoga, or other relaxation techniques. Engaging in hobbies and activities they enjoy can also help Rats unwind and find joy outside of their professional lives.</p>

                <p>In terms of lifestyle, Rats enjoy socializing and engaging in activities that stimulate their minds. They are often drawn to hobbies that involve problem-solving and strategic thinking, such as puzzles, games, or learning new skills. Rats also appreciate the finer things in life and enjoy indulging in luxury and comfort when possible. However, it's important for them to strike a balance and avoid overindulgence to maintain their health and well-being.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop:'25px' }}>
                
                    <Link to='/chinese_zodiac_pig' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'75px'}}>Previous: Pig</Link>
                    <Link to='/chinese_zodiac' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'75x', marginRight:'75px'}}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_ox' style={{textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>Next: Ox</Link>

                </div>

                </div>
            <Footer />
        </>
    )
}
