import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChariotTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Chariot Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chariot_tarot",
        title: "chariot_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Chariot</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/chariot_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/07_Chariot.jpg' alt='Chariot Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of the Chariot</h2>

                <p>The Chariot is the eighth card in the Major Arcana of the Tarot deck, numbered as 7. It represents determination, willpower, and triumph over adversity. The Chariot is a symbol of control, victory, and the ability to overcome obstacles through focused effort and perseverance. This card encourages the querent to stay determined, harness their inner strength, and confidently move forward towards their goals.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Chariot card typically features a warrior standing in a chariot, holding a scepter or wand, and being pulled by two sphinxes or horses of contrasting colors. The charioteer wears armor adorned with symbols of protection and victory, and a crown or helmet symbolizing authority and control. The sphinxes or horses represent opposing forces that must be balanced and directed by the charioteer to achieve success. In the background, there is often a city or landscape, symbolizing the destination or goals the charioteer is striving to reach.</p>

                <p>The Chariot's number, 7, signifies introspection, spirituality, and the quest for truth. This card's symbolism encourages the querent to harness their willpower, stay focused on their goals, and overcome challenges through determination and self-discipline.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Chariot card appears in a reading, it signifies the need for determination, focus, and the ability to overcome obstacles. It suggests that the querent is on a path to success and victory, but must remain disciplined and in control of their emotions and actions. The Chariot card encourages the querent to stay focused on their goals, harness their inner strength, and confidently move forward despite any challenges they may face.</p>

                <p>In the context of personal growth, the Chariot card can indicate a period of self-empowerment and the development of inner strength. It may suggest that the querent is learning to take control of their life and is determined to achieve their goals. In relationships, the Chariot card can signify the need for balance, communication, and the ability to navigate challenges together as a united force.</p>

                <p>In career and financial matters, the Chariot card can signal opportunities for advancement, success, and the achievement of goals through hard work and determination. It advises the querent to stay focused on their career path, remain disciplined, and use their willpower to overcome any obstacles. The Chariot card reminds the querent that with determination, focus, and perseverance, they can achieve their ambitions and reach their desired destination.</p>

                <h2>Conclusion</h2>

                <p>The Chariot card in the Tarot deck is a powerful symbol of determination, willpower, and triumph over adversity. By understanding and embracing the energy it represents, individuals can harness their inner strength, stay focused on their goals, and confidently move forward towards success. The Chariot card encourages the querent to remain disciplined, overcome challenges, and achieve their ambitions through focused effort and perseverance. It is a reminder that with determination and control, victory is within reach.</p>

                <Footer />
            </div>
        </>
    )
}
