import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function LunaBlogger(){

    useEffect(() => {
        document.title = 'Luna Mystic | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/blog/luna",
        title:"/blog/luna",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />


    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
        <h1>Luna Mystic</h1>
        <p>
            Hello, dear readers! I'm Luna Mystic, your guide through the celestial wonders of the night sky. My journey is deeply intertwined with the phases of the moon, and I find peace and inspiration in its gentle glow. Intuition and empathy are my companions as I explore the spiritual realm and meditate on life's mysteries.
        </p>
        <p>
            Together, we will delve into the phases of the moon, explore the depths of emotional healing, and uncover the secrets of intuitive readings and dream interpretation. Let's embark on this mystical journey together, embracing the wisdom of the cosmos and the serenity of the night.
        </p>

    </div>
    <Footer />
    </>
  )}

