import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacRooster() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Rooster | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_rooster",
        title: "chinese_zodiac_rooster",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Rooster</h1>
                    <Link to='/chinese_zodiac_rooster' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacRooster.png' alt='A rooster in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_monkey' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Monkey</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_dog' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Dog</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Rooster</h2>
                    <p>The Rooster is the tenth animal in the Chinese Zodiac cycle. Those born under this sign are known for their confidence, diligence, and resourcefulness. Roosters are hardworking and highly organized, often excelling in roles that require attention to detail and meticulous planning. They are also charismatic and enjoy being the center of attention. However, their perfectionist tendencies can sometimes make them critical and demanding of themselves and others.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits</h2>
                        <h2>Personality Traits of the Rooster</h2>
                        <p>The Rooster is the tenth sign in the Chinese Zodiac, and those born under this sign are known for their confidence, diligence, and resourcefulness. Roosters are hardworking and highly organized, often excelling in roles that require attention to detail and meticulous planning. They are also charismatic and enjoy being the center of attention.</p>

                        <p>Roosters are also known for their honesty and integrity. They value truth and fairness, and their straightforward nature makes them trustworthy companions. While they may not be the most outgoing or sociable, their loyalty and dependability make them cherished friends and partners. Roosters take their commitments seriously and are always ready to lend support to their loved ones.</p>

                        <p>Despite their positive traits, Roosters can be perfectionists and may struggle with being overly critical. Their high standards can sometimes lead to stress and frustration, both for themselves and those around them. It is important for Roosters to learn to balance their drive for excellence with patience and understanding.</p>

                        <p>Roosters are also known for their practicality and grounded nature. They prefer stability and security, often seeking out environments that provide them with a sense of order and predictability. While this can make them excellent planners and organizers, it can also lead to a reluctance to take risks or explore new opportunities. Embracing a more adventurous spirit can help Roosters enrich their lives and broaden their horizons.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Rooster</h2>
                <p>In their careers, Roosters excel in roles that require precision, organization, and leadership. They are often successful in fields such as administration, finance, and public relations, where their diligence and attention to detail can shine. Roosters are excellent planners and managers, capable of executing complex tasks efficiently. Their practical and grounded approach makes them valuable assets in any professional environment.</p>

                <p>Roosters are known for their meticulous nature and strong work ethic. They thrive in environments that require attention to detail and high standards of excellence. Their ability to organize and manage tasks effectively often leads to successful projects and achievements. However, their perfectionist tendencies can sometimes lead to stress and frustration. It's important for Roosters to practice self-care and find a balance between their professional and personal lives.</p>

                <p>When it comes to compatibility, Roosters are most compatible with the Ox, Snake, and Dragon. These signs complement the Rooster's traits and help to balance their hardworking nature. The Ox offers stability and reliability, the Snake provides wisdom and insight, and the Dragon shares the Rooster's ambition and vision. Roosters may experience challenges in relationships with the Rabbit and Dog, as these signs may clash with the Rooster's personality and create conflict. To maintain harmony, Roosters need to practice patience and understanding in their relationships.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Rooster</h2>
                <p>Roosters generally enjoy good health, thanks to their disciplined and organized nature. They are mindful of their well-being and often take a structured approach to health, incorporating regular exercise and a balanced diet into their routines. However, their perfectionist tendencies and high expectations can sometimes lead to stress and anxiety. It's important for Roosters to find effective ways to relax and unwind, such as through meditation, yoga, or engaging in hobbies they enjoy.</p>

                <p>Roosters are prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Roosters maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Roosters enjoy orderly and harmonious environments. They are often drawn to activities that promote structure and organization, such as planning, organizing, or engaging in creative pursuits. Roosters also value comfort and enjoy creating clean, well-organized homes. Surrounding themselves with beauty and tranquility helps them maintain a positive and balanced outlook on life.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_monkey' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Monkey</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_dog' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Dog</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
