import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function FiveOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Five of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "five_of_cups",
        title: "five_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Five of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/five_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups05.jpg' alt='Five of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/four_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Four of Cups</Link>   
                    <Link to='/six_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Six of Cups</Link>
                </div>
                <h2>Overview of the Five of Cups</h2>

                <p>The Five of Cups is a card in the Minor Arcana of the Tarot deck that represents loss, grief, and disappointment. It signifies a period of mourning and emotional turmoil, where one may be focused on what has been lost rather than what remains. This card encourages the querent to acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that are still available to them.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Five of Cups typically features a figure cloaked in black, standing by a river with their head bowed in sorrow. Three cups are spilled on the ground before them, symbolizing loss and disappointment, while two cups remain upright behind them, representing what still remains. The overall imagery emphasizes themes of grief, loss, and the need to shift focus from what has been lost to what remains.</p>

                <p>The Five of Cups' symbolism emphasizes themes of loss, grief, and disappointment. This card encourages the querent to acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that are still available to them.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Five of Cups appears in a reading, it signifies loss, grief, and disappointment. It suggests that the querent is experiencing or will soon experience a period of mourning and emotional turmoil, where they may be focused on what has been lost rather than what remains. The Five of Cups encourages the querent to acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that are still available to them.</p>

                <p>In the context of personal growth, the Five of Cups can indicate a period of emotional healing and the need to process feelings of loss and disappointment. It may suggest that the querent is learning to acknowledge their grief and is working towards healing and recovery. In relationships, the Five of Cups can signify a period of mourning and the need to address unresolved emotions, encouraging the querent to process their grief and focus on the support and opportunities that remain.</p>

                <p>In career and financial matters, the Five of Cups can signal a period of setback and disappointment. It advises the querent to acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that are still available to them. The Five of Cups reminds the querent that with acknowledgment, healing, and a shift in focus, they can overcome their setbacks and find new paths to success.</p>

                <h2>Conclusion</h2>

                <p>The Five of Cups in the Tarot deck is a powerful symbol of loss, grief, and disappointment. By understanding and embracing the energy it represents, individuals can acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that are still available to them. The Five of Cups encourages the querent to acknowledge their feelings of loss, process their grief, and eventually shift their focus to the opportunities and support that remain. It is a reminder that with acknowledgment, healing, and a shift in focus, we can overcome our setbacks and find new paths to success and fulfillment.</p>

                <Footer />
            </div>
        </>
    )
}
