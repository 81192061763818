import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function HighPriestessTarot() {

    useEffect(() => {
        document.title = 'Tarot | The High Priestess Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "high_priestess_tarot",
        title: "high_priestess_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The High Priestess</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/high_priestess_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/02_High_Priestess.jpg' alt='High Priestess Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of the High Priestess</h2>

                <p>The High Priestess is the third card in the Major Arcana of the Tarot deck, numbered as 2. It represents intuition, mystery, and the subconscious mind. The High Priestess is a symbol of inner knowledge, spiritual enlightenment, and the power of the unconscious. This card encourages the querent to listen to their inner voice, trust their intuition, and seek deeper understanding and wisdom.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the High Priestess card typically features a serene and enigmatic woman seated between two pillars, one black and one white, representing duality and balance. She wears a crown and holds a scroll, symbolizing hidden knowledge and esoteric wisdom. At her feet is a crescent moon, signifying the subconscious and the cycles of nature. Behind her, a veil decorated with pomegranates suggests the mysteries that lie beyond the physical world.</p>

                <p>The High Priestess's number, 2, signifies balance, duality, and the union of opposites. This card's symbolism encourages the querent to explore their inner world, embrace their intuitive insights, and seek harmony between their conscious and subconscious minds.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the High Priestess card appears in a reading, it signifies the importance of intuition, introspection, and inner wisdom. It suggests that the querent should trust their instincts and pay attention to the subtle messages from their subconscious. The High Priestess card encourages the querent to seek knowledge from within, meditate, and reflect on their inner truths.</p>

                <p>In the context of personal growth, the High Priestess card can indicate a period of spiritual awakening and heightened intuition. It may suggest that the querent is developing a deeper connection with their inner self and is gaining access to hidden knowledge. In relationships, the High Priestess card can signify the need for understanding and empathy, as well as the importance of listening to one's inner voice and being true to oneself.</p>

                <p>In career and financial matters, the High Priestess card can signal the need for careful consideration and the use of intuition in decision-making. It advises the querent to trust their inner guidance and seek answers from within rather than relying solely on external sources. The High Priestess card reminds the querent that true wisdom comes from a deep understanding of oneself and the ability to see beyond the surface.</p>

                <h2>Conclusion</h2>

                <p>The High Priestess card in the Tarot deck is a powerful symbol of intuition, mystery, and the subconscious mind. By understanding and embracing the energy it represents, individuals can tap into their inner wisdom and gain profound insights into their lives. The High Priestess card encourages the querent to trust their intuition, seek deeper understanding, and find harmony between their conscious and subconscious minds. It is a reminder that true knowledge and enlightenment come from within and that the journey of self-discovery is an essential part of personal and spiritual growth.</p>

                <Footer />
            </div>
        </>
    )
}
