import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function FiveOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Five of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "five_of_swords",
        title: "five_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Five of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/five_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords05.jpg' alt='Five of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/four_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Four of Swords</Link>   
                    <Link to='/six_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Six of Swords</Link>
                </div>
                <h2>Overview of the Five of Swords</h2>

                <p>The Five of Swords is a card in the Minor Arcana of the Tarot deck that represents conflict, tension, and betrayal. It signifies a period of discord, dishonesty, and the potential for negative outcomes. This card encourages the querent to navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Five of Swords typically features a figure holding three swords, with two swords lying on the ground and two defeated figures walking away. The scene depicts a sense of conflict, victory at a cost, and the aftermath of a dispute. The overall imagery emphasizes themes of discord, tension, and the consequences of conflict.</p>

                <p>The Five of Swords' symbolism emphasizes themes of conflict, tension, and betrayal. This card encourages the querent to navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Five of Swords appears in a reading, it signifies conflict, tension, and betrayal. It suggests that the querent is in or will soon enter a period of discord, dishonesty, and the potential for negative outcomes. The Five of Swords encourages the querent to navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations.</p>

                <p>In the context of personal growth, the Five of Swords can indicate a period of self-discovery and the development of resilience through conflict. It may suggest that the querent is learning to navigate challenging situations, maintain their integrity, and grow from their experiences. In relationships, the Five of Swords can signify the need for honest communication, the resolution of conflicts, and the importance of avoiding unnecessary disputes.</p>

                <p>In career and financial matters, the Five of Swords can signal challenges and setbacks that may cause tension and conflict. It advises the querent to navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations. The Five of Swords reminds the querent that with conflict, tension, and betrayal, they can still find a way to learn and grow.</p>

                <h2>Conclusion</h2>

                <p>The Five of Swords in the Tarot deck is a powerful symbol of conflict, tension, and betrayal. By understanding and embracing the energy it represents, individuals can navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations. The Five of Swords encourages the querent to navigate conflicts with integrity, avoid unnecessary disputes, and learn from challenging situations. It is a reminder that with conflict, tension, and betrayal, we can find a way to learn and grow.</p>

                <Footer />
            </div>
        </>
    )
}
