import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function MoonTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Moon Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "moon_tarot",
        title: "moon_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Moon</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/moon_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/18_Moon.jpg' alt='Moon Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Moon</h2>

                <p>The Moon is the eighteenth card in the Major Arcana of the Tarot deck, numbered as 18. It represents intuition, illusion, and the subconscious mind. The Moon is a symbol of mystery, dreams, and the unknown. This card encourages the querent to trust their intuition, explore their subconscious, and navigate through the illusions and uncertainties of life.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Moon card typically features a full moon shining in the night sky, with a path leading into the distance. On either side of the path are a wolf and a dog, representing the wild and domestic aspects of the mind. In the foreground, a crayfish emerges from a pool of water, symbolizing the subconscious mind and the emergence of hidden fears and desires. The overall imagery emphasizes themes of intuition, illusion, and the exploration of the subconscious.</p>

                <p>The Moon's number, 18, signifies intuition, dreams, and the exploration of the unknown. This card's symbolism encourages the querent to trust their intuition, explore their subconscious, and navigate through the illusions and uncertainties of life.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Moon card appears in a reading, it signifies intuition, illusion, and the subconscious mind. It suggests that the querent is in a period of uncertainty and may be facing illusions or hidden fears. The Moon card encourages the querent to trust their intuition, explore their subconscious, and navigate through the uncertainties of life with confidence and insight.</p>

                <p>In the context of personal growth, the Moon card can indicate a period of introspection and the exploration of the subconscious mind. It may suggest that the querent is becoming aware of hidden fears and desires and is learning to trust their intuition. In relationships, the Moon card can signify the need for honesty, openness, and the exploration of hidden emotions and desires.</p>

                <p>In career and financial matters, the Moon card can signal opportunities for growth through intuition and the exploration of the unknown. It advises the querent to trust their instincts, navigate through uncertainties, and explore new possibilities. The Moon card reminds the querent that with intuition, insight, and the exploration of the subconscious, they can achieve their goals and navigate through the uncertainties of life.</p>

                <h2>Conclusion</h2>

                <p>The Moon card in the Tarot deck is a powerful symbol of intuition, illusion, and the subconscious mind. By understanding and embracing the energy it represents, individuals can trust their intuition, explore their subconscious, and navigate through the illusions and uncertainties of life. The Moon card encourages the querent to trust their intuition, explore their subconscious, and navigate through the uncertainties of life with confidence and insight. It is a reminder that with intuition, insight, and the exploration of the subconscious, we can achieve our goals and navigate through the uncertainties of life.</p>

                <Footer />
            </div>
        </>
    )
}
