import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacRabbit() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Rabbit | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_rabbit",
        title: "chinese_zodiac_rabbit",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Rabbit</h1>
                    <Link to='/chinese_zodiac_rabbit' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacRabbit.png' alt='A rabbit in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>
                    

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_tiger' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Tiger</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_dragon' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Dragon</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Rabbit</h2>
                    <p>The Rabbit is the fourth animal in the Chinese Zodiac cycle. Those born under this sign are known for their gentleness, compassion, and good-naturedness. Rabbits are kind-hearted and empathetic, often seeking harmony in their relationships and surroundings. They are also artistic and have a refined sense of aesthetics. However, their sensitivity can sometimes make them prone to anxiety and indecision.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Rabbit</h2>
                    <p>The Rabbit is the fourth sign in the Chinese Zodiac, and those born under this sign are known for their gentle, kind, and diplomatic nature. Rabbits are compassionate and empathetic, often going out of their way to help others and create harmony in their surroundings. Their peaceful disposition makes them excellent mediators and friends.</p>

                    <p>Rabbits are also known for their intelligence and quick thinking. They are resourceful and adaptable, often finding creative solutions to problems. Their keen intuition allows them to read situations and people effectively, helping them navigate social interactions with ease. However, their sensitivity can sometimes make them prone to anxiety and overthinking.</p>

                    <p>Despite their gentle nature, Rabbits have a strong sense of determination and resilience. They are capable of working diligently towards their goals and achieving success through perseverance. However, their aversion to conflict can sometimes lead them to avoid difficult situations or decisions. It is important for Rabbits to develop assertiveness and confidence in expressing their needs and opinions.</p>

                    <p>Rabbits are also known for their love of beauty and aesthetics. They have a keen eye for detail and enjoy surrounding themselves with art and nature. This appreciation for the finer things in life often translates into a refined and elegant lifestyle. However, their desire for comfort and security can sometimes make them reluctant to step out of their comfort zones and take risks.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Rabbit</h2>
                <p>In their careers, Rabbits excel in roles that require diplomacy, creativity, and empathy. They are often successful in fields such as art, education, and counseling, where their gentle and compassionate nature can shine. Rabbits are excellent mediators who can navigate complex social interactions with ease. Their keen intuition and ability to read situations and people effectively make them valuable assets in any professional environment.</p>

                <p>Rabbits are known for their creativity and artistic talents. They have a natural ability to see beauty in the world and often express themselves through various forms of art. Their sensitivity and understanding of human emotions make them effective counselors and therapists. However, their aversion to conflict and tendency to avoid difficult situations can sometimes hinder their professional growth. It's important for Rabbits to develop assertiveness and confidence in their abilities.</p>

                <p>When it comes to compatibility, Rabbits are most compatible with the Sheep, Pig, and Dog. These signs complement the Rabbit's traits and help to balance their gentle nature. The Sheep offers empathy and understanding, the Pig provides compassion and kindness, and the Dog shares the Rabbit's loyalty and sense of duty. Rabbits may experience challenges in relationships with the Rooster and Dragon, as these signs may clash with the Rabbit's personality and create conflict. To maintain harmony, Rabbits need to practice clear communication and assertiveness in their relationships.</p>
                  </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Rabbit</h2>
                <p>Rabbits generally have a gentle and calm nature, which contributes to their overall well-being. They are usually mindful of their health and take care to maintain a balanced lifestyle. However, their sensitivity and tendency to worry can sometimes lead to stress and anxiety. It's important for Rabbits to find ways to relax and unwind, such as through meditation, yoga, or spending time in nature.</p>

                <p>Rabbits are prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Rabbits maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Rabbits enjoy peaceful and harmonious environments. They are often drawn to activities that promote relaxation and creativity, such as reading, gardening, or engaging in artistic pursuits. Rabbits also value comfort and enjoy creating cozy, welcoming homes. Surrounding themselves with beauty and tranquility helps them maintain a positive and balanced outlook on life.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_tiger' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Tiger</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_dragon' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Dragon</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
