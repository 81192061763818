import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Zodiac(){

    useEffect(() => {
        document.title = 'Zodiac | Zodiac | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/zodiac",
        title:"zodiac",
    })

    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);


    return(
        <>
        <Navbar />
        <div ref={topContainer} />
        <header className="header"style={{backgroundColor:'rgb(18,17,16)'}} >
        <h1 style={{color:'rgb(250,250,248)'}}>Zodiac</h1>
        <p>What is your sign?</p>
      </header>
        <div style={{justifyContent:'center', textAlign:'center', maxWidth:'800px', margin:'auto'}}>
            <div style={{display:'flex', flex:1, flexDirection:'row'}}>
            
            
            <Link to='/aries' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center', color:'white'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/aries1.png' alt={"Aries, the Ram, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Aries
                    
                </div>
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Mar 21 - Apr 19
                </div>
            
            </Link>
            
            <Link to='/taurus' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/taurus1.png' alt={"Taurus, the Bull, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Taurus
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Apr 20 - May 20
                </div>           
            </Link>
            <Link to='/gemini' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/gemini1.png' alt={"Gemini, the Twins, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Gemini
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    May 21 - Jun 20
                </div>           
            </Link>
            <Link to='/cancer' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/cancer1.png' alt={"Cancer, the Crab, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Cancer
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Jun 21 - Jul 22
                </div>           
            </Link>
            </div>

            <div style={{display:'flex', flex:1, flexDirection:'row'}}>

            <Link to='/leo' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/leo1.png' alt={"Leo, the Lion, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Leo
                </div>
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Jul 23 - Aug 22
                </div>
            
            </Link>
            <Link to='/virgo' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/virgo1.png' alt={"Virgo, the Virgin, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Virgo
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Aug 23 - Sep 22
                </div>           
            </Link>
            <Link to='/libra' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/libra2.png' alt={"Libra, the Scales, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Libra
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Sep 23 - Oct 22
                </div>           
            </Link>
            <Link to='/scorpio' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/scorpio1.png' alt={"Scorpio, the Scorpion, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Scorpio
                </div>
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Oct 23 - Nov 21
                </div>            
            </Link>
            </div>



            <div style={{display:'flex', flex:1, flexDirection:'row'}}>

            <Link to='/sagittarius' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/sagittarius1.png' alt={"Sagittarius, the Archer, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Sagittarius
                </div>
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Nov 22 - Dec 21
                </div>
            
            </Link>
            <Link to='/capricorn' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/capricorn1.png' alt={"Capricorn, the Goat, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Capricorn
                </div>    
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Dec 22 - Jan 19
                </div>        
            </Link>
            <Link to='/aquarius' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/aquarius1.png' alt={"Aquarius, the Waterbearer, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Aquarius
                </div>  
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Jan 20 - Feb 18
                </div>          
            </Link>
            <Link to='/pisces' style={{display:'flex', flex:1, flexDirection:'column', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <div>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/pisces1.png' alt={"Pisces, the Fish, in front of a cosmic background"} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                </div>
                <div style={{fontSize:'12pt', fontWeight:'bold', color:'rgb(183,182,180)'}}>
                    Pisces
                </div> 
                <div style={{color:'rgb(183,182,180)', fontSize:'12pt'}}>
                    Feb 19 - Mar 20
                </div>           
            </Link>
            </div>


           
            <div style={{textAlign:'left'}}>
                <h2>Understanding the Zodiac</h2>
                <p style={{textAlign:'left'}}>
                The zodiac is a fundamental concept in astrology, representing a celestial coordinate system that charts the positions of the sun, moon, and planets. It consists of twelve signs, each associated with specific traits, behaviors, and influences. These signs are divided among the four classical elements: fire, earth, air, and water, each adding unique characteristics to the signs within it.
                </p>
                <h2>Origins and Structure</h2>
                <p>                
                    The origins of the zodiac date back to ancient Babylonian astronomy and astrology, later refined by the Greeks and Romans. The term "zodiac" comes from the Greek word "zōidiakos," meaning "circle of animals," as most of the zodiac signs are represented by animals or mythological figures. The twelve signs are:
                </p>
                <li>
                    Aries (March 21 - April 19) - The Ram
                </li>
                <li>
                    Taurus (April 20 - May 20) - The Bull
                </li>
                <li>
                    Gemini (May 21 - June 20) - The Twins
                </li>
                <li>
                    Cancer (June 21 - July 22) - The Crab
                </li>
                <li>
                    Leo (July 23 - August 22) - The Lion
                </li>
                <li>
                    Virgo (August 23 - September 22) - The Virgin
                </li>
                <li>
                    Libra (September 23 - October 22) - The Scales
                </li>
                <li>
                    Scorpio (October 23 - November 21) - The Scorpion
                </li>
                <li>
                    Sagittarius (November 22 - December 21) - The Archer
                </li>
                <li>
                    Capricorn (December 22 - January 19) - The Goat
                </li>
                <li>
                    Aquarius (January 20 - February 18) - The Water Bearer
                </li>
                <li>
                    Pisces (February 19 - March 20) - The Fish
                </li>

                <h2>The History of Zodiac Astrology</h2>
                <p>
                The history of zodiac astrology is a fascinating journey through time, beginning with the ancient civilizations that first gazed at the stars and saw patterns that they believed influenced human lives. The earliest known records of astrology date back to the Babylonian Empire around the 5th century BCE. The Babylonians developed the first system of horoscopic astrology, dividing the sky into twelve equal parts, which laid the groundwork for the zodiac signs we recognize today.
                </p>
                <h3>Ancient Beginnings</h3>
                <p>
                The Babylonians observed the movements of the sun, moon, and planets, and correlated these celestial events with terrestrial events. They identified twelve constellations along the ecliptic (the apparent path of the sun across the sky) and attributed specific meanings to each. These twelve sections became the zodiac signs, each associated with a particular time of the year.
                </p>
                <p>
                The influence of Babylonian astrology spread to ancient Egypt, where it was integrated with the local astronomical and astrological traditions. The Egyptians contributed significantly by refining the system and emphasizing the importance of the sun's annual journey through the zodiac.
                </p>
                <h3>Greek and Roman Contributions</h3>
                <p>
                Astrology gained considerable momentum in ancient Greece. The Greeks adopted the Babylonian zodiac system, but they added their own interpretations and philosophical underpinnings. The most notable figure in Greek astrology was Claudius Ptolemy, whose work "Tetrabiblos" became a cornerstone of Western astrology. Ptolemy's work synthesized and codified astrological knowledge, making it more accessible and systematically organized.
                </p>
                <p>
                The Romans further propagated astrology as they expanded their empire. Roman society embraced astrology, incorporating it into their culture and daily life. Astrology was used to make decisions about everything from politics to personal relationships. The names of the zodiac signs as we know them today—Aries, Taurus, Gemini, and so on—are derived from Latin, reflecting the Roman influence.
                </p>
                <h3>Medieval and Renaissance Periods</h3>
                <p>
                During the Middle Ages, astrology was intertwined with medieval scholarship, including medicine, astronomy, and alchemy. Astrologers often held prominent positions in royal courts and advised rulers based on celestial readings. Despite occasional opposition from religious authorities, astrology continued to thrive.
                </p>
                <p>
                The Renaissance brought a renewed interest in classical knowledge, including astrology. Scholars revisited ancient texts and expanded upon them. This period saw the development of more sophisticated astrological techniques and tools, such as ephemerides (tables of planetary positions) and the astrolabe, an ancient instrument used for solving problems related to time and the position of the stars.
                </p>
                <h3>Modern Era</h3>
                <p>
                    In the modern era, astrology has evolved into a more personalized and psychological practice. The advent of the printing press in the 15th century and the subsequent rise of mass media allowed for the widespread dissemination of astrological knowledge. Newspapers and magazines began publishing horoscopes, making astrology accessible to the general public.
                </p>
                <p>
                Today, astrology enjoys a resurgence in popularity, with people seeking personal insights and guidance through the interpretation of their birth charts. The internet has further democratized access to astrological information, creating a vibrant global community of enthusiasts and practitioners.
                </p>
                <p>
                From its ancient origins in Babylon to its current global presence, the history of zodiac astrology is a testament to humanity's enduring fascination with the stars and the belief that our destinies are written in the heavens.
                </p>
                <h2>The Significance of the Zodiac</h2>
                <p>
                In astrology, the zodiac serves as a map of the heavens, reflecting the cosmic influences at the time of one's birth. Each sign is associated with specific personality traits, strengths, weaknesses, and potential life paths. For example, Aries is known for its boldness and leadership qualities, while Pisces is often linked to empathy and artistic inclinations.
                </p>
                <p>
                Astrologers use the zodiac to create horoscopes, which provide insights into individuals' futures and personalities based on the positions of celestial bodies at their birth. These horoscopes can offer guidance on various aspects of life, including relationships, career, and personal growth.
                </p>
                <h2>Elements and Modalities</h2>
                <p>
                The zodiac signs are further classified by their elements and modalities. The four elements — fire, earth, air, and water — represent fundamental characteristics:
                </p>
                <p>
                <li>
                    Fire Signs (Aries, Leo, Sagittarius): Energetic, passionate, and dynamic.
                </li>
                <li>
                    Earth Signs (Taurus, Virgo, Capricorn): Practical, reliable, and grounded.
                </li>
                <li>
                    Air Signs (Gemini, Libra, Aquarius): Intellectual, communicative, and social.
                </li>
                <li>
                    Water Signs (Cancer, Scorpio, Pisces): Emotional, intuitive, and nurturing.
                </li>
                </p>
                <p>
                Each sign also belongs to one of three modalities: cardinal, fixed, and mutable, indicating how they interact with the world:
                </p>
                <p>
                <li>
                    Cardinal Signs (Aries, Cancer, Libra, Capricorn): Initiators and leaders.
                </li>
                <li>
                    Fixed Signs (Taurus, Leo, Scorpio, Aquarius): Stubborn and resistant to change.
                </li>
                <li>
                    Mutable Signs (Gemini, Virgo, Sagittarius, Pisces): Adaptable and flexible.
                </li>
                </p>
                <p>
                Understanding the zodiac provides a deeper insight into the astrological influences that shape our personalities and lives. Whether you seek guidance or a deeper understanding of yourself and others, the zodiac serves as a valuable tool in the realm of astrology.
                </p>

                
            </div>   
            </div>     



        <Footer />
        </>
    )
}
