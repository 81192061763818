import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function NineOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Nine of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "nine_of_pentacles",
        title: "nine_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Nine of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/nine_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents09.jpg' alt='Nine of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/eight_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Eight of Pentacles</Link>   
                    <Link to='/ten_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Ten of Pentacles</Link>
                </div>
                <h2>Overview of the Nine of Pentacles</h2>

                <p>The Nine of Pentacles is a card in the Minor Arcana of the Tarot deck that represents self-sufficiency, financial independence, and the enjoyment of life's luxuries. It signifies a period of abundance, confidence, and the rewards of hard work. This card encourages the querent to appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Nine of Pentacles typically features a well-dressed woman standing in a lush garden, surrounded by pentacles and holding a bird of prey. The scene depicts a sense of abundance, luxury, and self-sufficiency. The overall imagery emphasizes themes of financial independence, confidence, and the enjoyment of life's pleasures.</p>

                <p>The Nine of Pentacles' symbolism emphasizes themes of self-sufficiency, financial independence, and the enjoyment of life's luxuries. This card encourages the querent to appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Nine of Pentacles appears in a reading, it signifies self-sufficiency, financial independence, and the enjoyment of life's luxuries. It suggests that the querent is in or will soon enter a period of abundance, confidence, and the rewards of hard work. The Nine of Pentacles encourages the querent to appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance.</p>

                <p>In the context of personal growth, the Nine of Pentacles can indicate a period of self-discovery and the development of confidence and independence. It may suggest that the querent is learning to appreciate their abilities, enjoy their accomplishments, and maintain a sense of self-reliance. In relationships, the Nine of Pentacles can signify the importance of maintaining individuality, enjoying shared success, and appreciating the luxuries of life together.</p>

                <p>In career and financial matters, the Nine of Pentacles can signal opportunities for growth and success through hard work and self-sufficiency. It advises the querent to appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance. The Nine of Pentacles reminds the querent that with self-sufficiency, financial independence, and the enjoyment of life's luxuries, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Nine of Pentacles in the Tarot deck is a powerful symbol of self-sufficiency, financial independence, and the enjoyment of life's luxuries. By understanding and embracing the energy it represents, individuals can appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance. The Nine of Pentacles encourages the querent to appreciate their achievements, enjoy the fruits of their labor, and maintain a sense of independence and self-reliance. It is a reminder that with self-sufficiency, financial independence, and the enjoyment of life's luxuries, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
