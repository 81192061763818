import { React, useState} from "react";
import {Link} from 'react-router-dom';

export default function BlogTile(props){

    const data = props.teaserContent || "";
    console.log(data)
    var contentSnippent = data.substring(0,75)
    var linkAddress;
    var postNumber = props.number

    const postAddress= props.postAddress
    var authorLinkAddress = ""
    var author = props.author

    if(author === 'Zara')
        {
            authorLinkAddress = '/blog/zara/'
        }
    if(author === 'Star Sage')
        {
            authorLinkAddress = '/blog/star/'
        }

    return(

        


        <>
            <div class="blog-entry" style={{maxWidth:'250px', borderWidth:'2px', borderColor:'#535bf2', borderStyle:'solid', borderRadius:'8px', paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center', color:'white', margin:'10px'}}>
                <img src={props.img} alt={props.altText} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}} />
                <a href={postAddress}><h3>{props.title}</h3></a>
                By:<Link to={authorLinkAddress} style={{fontSize:'.9em', textDecoration:'none', fontWeight:'bold', textAlign:'center', marginLeft:'10px', marginRight:'10px'}}>{props.author}</Link>
                <p><small><strong>Date: {props.date}</strong></small></p>
                <p>{contentSnippent}... <a href={postAddress}>Read more</a></p>
            </div>

        </>

    

    )
}