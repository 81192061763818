import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function NineOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Nine of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "nine_of_wands",
        title: "nine_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Nine of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/nine_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands09.jpg' alt='Nine of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/eight_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Eight of Wands</Link>   
                    <Link to='/ten_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Ten of Wands</Link>
                </div>
                <h2>Overview of the Nine of Wands</h2>

                <p>The Nine of Wands is a card in the Minor Arcana of the Tarot deck that represents resilience, perseverance, and the final push toward achieving a goal. It signifies a period of strength, determination, and the ability to overcome obstacles despite previous struggles. This card encourages the querent to remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Nine of Wands typically features a figure standing guard with a bandaged head, holding a wand, and looking cautiously over their shoulder. Eight other wands stand upright in the background, symbolizing previous battles and challenges. The overall imagery emphasizes themes of resilience, perseverance, and the final push toward achieving a goal.</p>

                <p>The Nine of Wands' symbolism emphasizes themes of resilience, perseverance, and the final push toward achieving a goal. This card encourages the querent to remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Nine of Wands appears in a reading, it signifies resilience, perseverance, and the final push toward achieving a goal. It suggests that the querent is in or will soon enter a period of strength, determination, and the ability to overcome obstacles despite previous struggles. The Nine of Wands encourages the querent to remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives.</p>

                <p>In the context of personal growth, the Nine of Wands can indicate a period of self-discovery and the development of resilience through challenges. It may suggest that the querent is learning to draw on their inner strength and persevere through difficult times. In relationships, the Nine of Wands can signify the need to address ongoing challenges and maintain a resilient and determined attitude to overcome obstacles.</p>

                <p>In career and financial matters, the Nine of Wands can signal opportunities for growth and success through resilience and perseverance. It advises the querent to remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives. The Nine of Wands reminds the querent that with resilience, perseverance, and the final push toward achieving a goal, they can overcome obstacles and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Nine of Wands in the Tarot deck is a powerful symbol of resilience, perseverance, and the final push toward achieving a goal. By understanding and embracing the energy it represents, individuals can remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives. The Nine of Wands encourages the querent to remain steadfast, draw on their inner strength, and continue pushing forward to achieve their objectives. It is a reminder that with resilience, perseverance, and the final push toward achieving a goal, we can overcome obstacles and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
