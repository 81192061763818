import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function EightOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Eight of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "eight_of_pentacles",
        title: "eight_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Eight of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/eight_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents08.jpg' alt='Eight of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/seven_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Seven of Pentacles</Link>   
                    <Link to='/nine_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Nine of Pentacles</Link>
                </div>
                <h2>Overview of the Eight of Pentacles</h2>

                <p>The Eight of Pentacles is a card in the Minor Arcana of the Tarot deck that represents hard work, dedication, and the pursuit of mastery. It signifies a period of focused effort, skill development, and the commitment to achieving excellence. This card encourages the querent to dedicate themselves to their tasks, hone their skills, and strive for mastery in their chosen field.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Eight of Pentacles typically features a figure diligently working on carving pentacles, with several completed pentacles displayed nearby. The scene depicts a sense of dedication, craftsmanship, and the pursuit of excellence. The overall imagery emphasizes themes of hard work, dedication, and the commitment to achieving mastery.</p>

                <p>The Eight of Pentacles' symbolism emphasizes themes of hard work, dedication, and the pursuit of mastery. This card encourages the querent to dedicate themselves to their tasks, hone their skills, and strive for excellence in their chosen field.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Eight of Pentacles appears in a reading, it signifies hard work, dedication, and the pursuit of mastery. It suggests that the querent is in or will soon enter a period of focused effort, skill development, and the commitment to achieving excellence. The Eight of Pentacles encourages the querent to dedicate themselves to their tasks, hone their skills, and strive for mastery in their chosen field.</p>

                <p>In the context of personal growth, the Eight of Pentacles can indicate a period of self-discovery and the development of skills through hard work and dedication. It may suggest that the querent is learning to focus their efforts, improve their abilities, and commit to achieving their goals. In relationships, the Eight of Pentacles can signify the need for mutual effort, dedication, and the continuous improvement of the partnership.</p>

                <p>In career and financial matters, the Eight of Pentacles can signal opportunities for growth and success through hard work and skill development. It advises the querent to focus on their tasks, improve their abilities, and commit to achieving their goals. The Eight of Pentacles reminds the querent that with hard work, dedication, and the pursuit of mastery, they can achieve their goals and find success in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Eight of Pentacles in the Tarot deck is a powerful symbol of hard work, dedication, and the pursuit of mastery. By understanding and embracing the energy it represents, individuals can dedicate themselves to their tasks, hone their skills, and strive for excellence in their chosen field. The Eight of Pentacles encourages the querent to dedicate themselves to their tasks, hone their skills, and strive for mastery in their chosen field. It is a reminder that with hard work, dedication, and the pursuit of mastery, we can achieve our goals and find success in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
