import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function FiveOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Five of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "five_of_pentacles",
        title: "five_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Five of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/five_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents05.jpg' alt='Five of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/four_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Four of Pentacles</Link>   
                    <Link to='/six_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Six of Pentacles</Link>
                </div>
                <h2>Overview of the Five of Pentacles</h2>

                <p>The Five of Pentacles is a card in the Minor Arcana of the Tarot deck that represents hardship, financial loss, and feelings of isolation. It signifies a period of difficulty, where one might face material challenges, health issues, or emotional struggles. This card encourages the querent to seek support, remain hopeful, and find ways to overcome adversity despite the hardships they are facing.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Five of Pentacles typically features two figures walking through snow outside a church, one of them on crutches. The scene depicts a sense of hardship, poverty, and isolation, with the warm light of the church symbolizing hope and refuge. The overall imagery emphasizes themes of difficulty, financial loss, and the need to seek support and maintain hope.</p>

                <p>The Five of Pentacles' symbolism emphasizes themes of hardship, financial loss, and feelings of isolation. This card encourages the querent to seek support, remain hopeful, and find ways to overcome adversity despite the hardships they are facing.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Five of Pentacles appears in a reading, it signifies hardship, financial loss, and feelings of isolation. It suggests that the querent is in or will soon enter a period of difficulty, where they might face material challenges, health issues, or emotional struggles. The Five of Pentacles encourages the querent to seek support, remain hopeful, and find ways to overcome adversity despite the hardships they are facing.</p>

                <p>In the context of personal growth, the Five of Pentacles can indicate a period of self-discovery and resilience through hardship. It may suggest that the querent is learning to cope with difficulties and is finding inner strength and support from others. In relationships, the Five of Pentacles can signify the need for mutual support, understanding, and the importance of standing by each other during tough times.</p>

                <p>In career and financial matters, the Five of Pentacles can signal challenges and setbacks. It advises the querent to seek support, remain hopeful, and find ways to overcome adversity. The Five of Pentacles reminds the querent that with hardship, financial loss, and feelings of isolation, they can still find a way to persevere and eventually achieve stability and success.</p>

                <h2>Conclusion</h2>

                <p>The Five of Pentacles in the Tarot deck is a powerful symbol of hardship, financial loss, and feelings of isolation. By understanding and embracing the energy it represents, individuals can seek support, remain hopeful, and find ways to overcome adversity despite the hardships they are facing. The Five of Pentacles encourages the querent to seek support, remain hopeful, and find ways to overcome adversity despite the hardships they are facing. It is a reminder that with resilience and determination, we can navigate through difficult times and find hope and support even in the most challenging circumstances.</p>

                <Footer />
            </div>
        </>
    )
}
