import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function FAQ(){

    useEffect(() => {
        document.title = 'FAQ | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/faq",
        title:"faq",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />
    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>

        <h1>Frequently Asked Questions (FAQ)</h1>

        <h2>What is Cosmic Secrets?</h2>
        <p>Cosmic Secrets is an astrology website that provides comprehensive insights into the world of astrology, numerology, tarot, and more. Our goal is to guide you on your spiritual journey by offering accurate and insightful information.</p>

        <h2>How can I contact you?</h2>
        <p>You can contact us via email at <a href="mailto:cosmic.secrets.info@gmail.com">cosmic.secrets.info@gmail.com</a>. We strive to respond to all inquiries within 24-48 hours.</p>

        <h2>Do you offer personalized readings?</h2>
        <p>No, unfortunately at this time we do not offer personlized readings.</p>

        <h2>Is my personal information safe with you?</h2>
        <p>Yes, we are committed to protecting your privacy. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for more information on how we handle your personal information.</p>
      

        <p>We hope we have answered your questions. If you need further assistance, please don't hesitate to <a href="mailto:cosmic.secrets.info@gmail.com">contact us</a>.</p>
    </div>
<Footer />
</>)}
