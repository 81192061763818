import { React} from "react";
import Aries from './../icons/Aries.svg'
import Taurus from './../icons/Taurus.svg'
import Gemini from './../icons/Gemini.svg'
import Cancer from './../icons/Cancer.svg'
import Leo from './../icons/Leo.svg'
import Virgo from './../icons/Virgo.svg'
import Libra from './../icons/Libra.svg'
import Scorpio from './../icons/Scorpio.svg'
import Sagittarius from './../icons/Sagittarius.svg'
import Capricorn from './../icons/Capricorn.svg'
import Aquarius from './../icons/Aquarius.svg'
import Pisces from './../icons/Pisces.svg'
import './ZodiacIcon.css';



export default function ZodiacIcon(props){

    const number = props.zodiacSign

    var icon = Aquarius;

    if (props.zodiacSign === 'Aries'){
            icon = Aries;
        }
    if (props.zodiacSign === 'Taurus'){
            icon = Taurus;
        }
    if (props.zodiacSign === 'Gemini'){
        icon = Gemini;
    }
    if (props.zodiacSign === 'Cancer'){
        icon = Cancer;
    }
    if (props.zodiacSign === 'Leo'){
        icon = Leo;
    }
    if (props.zodiacSign === 'Virgo'){
        icon = Virgo;
    }
    if (props.zodiacSign === 'Libra'){
        icon = Libra;
    }
    if (props.zodiacSign === 'Scorpio'){
        icon = Scorpio;
    }
    if (props.zodiacSign === 'Sagittarius'){
        icon = Sagittarius;
    }
    if (props.zodiacSign === 'Capricorn'){
        icon = Capricorn;
    }
    if (props.zodiacSign === 'Aquarius'){
        icon = Aquarius;
    }
    if (props.zodiacSign === 'Pisces'){
        icon = Pisces;
    }





    return(
        <>
        <div className='iconLink' style={{display:'flex', flexDirection:'column', width:props.size, heigh:props.size, margin:props.margin}}>
            <div style={{display:'flex', flex:1}}>                
                <img src={icon} style={{ flex:1, maxWidth:'100%', maxHeight:'100%'}}></img>
            </div>
            <div style={{textAlign:'center', fontWeight:'bold', color:'#646cff', fontSize:'8pt'}}>
                    {props.zodiacSign}
            </div>
        </div>

        </>   

    )
}