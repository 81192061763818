import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacHorse() {


    useEffect(() => {
        document.title = 'Chinese Zodiac | Horse | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_horse",
        title: "chinese_zodiac_horse",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Horse</h1>
                    <Link to='/chinese_zodiac_horse' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacHorse.png' alt='A horse in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_snake' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Snake</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_goat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Goat</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Horse</h2>
                    <p>The Horse is the seventh animal in the Chinese Zodiac cycle. Those born under this sign are known for their energy, independence, and enthusiasm. Horses are adventurous and love to explore new horizons. They are sociable and enjoy being in the company of others. Horses are also resilient and able to overcome obstacles with determination. However, their restless nature can sometimes make them impulsive and prone to taking unnecessary risks.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Horse</h2>
                    <p>The Horse is the seventh sign in the Chinese Zodiac, and individuals born under this sign are known for their energy, independence, and enthusiasm. Horses are adventurous and love to explore new horizons. They are sociable and enjoy being in the company of others. Horses are also resilient and able to overcome obstacles with determination.</p>

                    <p>Horses are also known for their confidence and charisma. They have a natural ability to attract others and often find themselves at the center of attention. Their dynamic and outgoing personality makes them excellent leaders and motivators. However, their restless nature can sometimes make them impulsive and prone to taking unnecessary risks.</p>

                    <p>Despite their adventurous spirit, Horses have a compassionate and caring side. They value their relationships deeply and are always willing to lend a helping hand. Their loyalty and dedication make them reliable friends and partners. However, their independent nature can sometimes make them appear distant or aloof.</p>

                    <p>Horses are also known for their love of freedom and independence. They value their autonomy and often prefer to carve their own path rather than follow the crowd. While this makes them strong and resilient, it can also lead to a tendency to be overly self-reliant and resistant to seeking help from others. Learning to accept support and collaborate can help Horses achieve even greater success and fulfillment.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                 <h2>Career and Compatibility of the Horse</h2>
                 <p>In their careers, Horses excel in roles that require energy, creativity, and independence. They are often successful in fields such as travel, sports, and entertainment, where their dynamic and adventurous nature can shine. Horses are excellent motivators who inspire others with their enthusiasm and passion. Their ability to think on their feet and take risks makes them valuable assets in any professional environment.</p>

                 <p>Horses are known for their energetic and dynamic personalities. They thrive in fast-paced environments where they can take initiative and make an impact. Their confidence and charisma often lead to leadership roles and significant achievements. However, their restless nature can sometimes make them impulsive and prone to taking unnecessary risks. It's important for Horses to balance their adventurous spirit with careful planning and consideration.</p>

                <p>When it comes to compatibility, Horses are most compatible with the Tiger, Dog, and Goat. These signs complement the Horse's traits and help to balance their energetic nature. The Tiger offers boldness and excitement, the Dog provides loyalty and reliability, and the Goat shares the Horse's gentle and compassionate nature. Horses may experience challenges in relationships with the Rat and Ox, as these signs may clash with the Horse's personality and create conflict. To maintain harmony, Horses need to practice patience and understanding in their relationships.</p>
                            </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Horse</h2>
                <p>Horses are known for their energetic and dynamic nature, which generally helps them maintain good physical health. They enjoy engaging in physical activities and sports, which keep them fit and active. However, their adventurous spirit and tendency to take risks can sometimes lead to accidents or injuries. It's important for Horses to practice caution and take care of their bodies to avoid unnecessary harm.</p>

                <p>Horses can also be prone to stress and anxiety due to their ambitious and competitive nature. They need to find effective ways to manage stress, such as through relaxation techniques, meditation, or engaging in creative activities that allow them to express themselves. Horses benefit from a balanced lifestyle that includes time for both work and leisure.</p>

                <p>In terms of lifestyle, Horses enjoy excitement and novelty. They are often drawn to activities that challenge them both physically and mentally, such as travel, adventure sports, or exploring new hobbies. Horses also value their independence and enjoy having the freedom to pursue their interests. However, it's important for them to maintain a healthy balance and avoid overextending themselves.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_snake' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Snake</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_goat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Goat</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
