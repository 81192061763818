import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function StarTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Star Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "star_tarot",
        title: "star_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Star</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/star_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/17_Star.jpg' alt='Star Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Star</h2>

                <p>The Star is the seventeenth card in the Major Arcana of the Tarot deck, numbered as 17. It represents hope, inspiration, and renewal. The Star is a symbol of healing, spiritual insight, and the promise of a brighter future. This card encourages the querent to have faith in themselves, embrace their inner light, and trust that they are on the right path towards fulfillment and happiness.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Star card typically features a serene woman kneeling by a pool of water, with one foot in the water and the other on land. She pours water from two jugs, one into the pool and the other onto the land, symbolizing the flow of spiritual and material nourishment. Above her shines a large star, surrounded by seven smaller stars, representing the chakras and spiritual awakening. The overall imagery emphasizes themes of hope, inspiration, and spiritual renewal.</p>

                <p>The Star's number, 17, signifies spiritual insight, renewal, and the promise of a brighter future. This card's symbolism encourages the querent to have faith in themselves, embrace their inner light, and trust that they are on the right path towards fulfillment and happiness.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Star card appears in a reading, it signifies hope, inspiration, and renewal. It suggests that the querent is in a period of healing and spiritual insight, where they are able to see the possibilities of a brighter future. The Star card encourages the querent to have faith in themselves, embrace their inner light, and trust that they are on the right path towards fulfillment and happiness.</p>

                <p>In the context of personal growth, the Star card can indicate a period of spiritual awakening and renewal. It may suggest that the querent is experiencing a deep sense of hope and inspiration, and is able to see the possibilities of a brighter future. In relationships, the Star card can signify the healing and renewal of connections, and the importance of faith and trust in building a brighter future together.</p>

                <p>In career and financial matters, the Star card can signal opportunities for growth and success through hope and inspiration. It advises the querent to have faith in their abilities, embrace their inner light, and trust that they are on the right path towards fulfillment and success. The Star card reminds the querent that with hope, inspiration, and spiritual insight, they can achieve their goals and build a brighter future.</p>

                <h2>Conclusion</h2>

                <p>The Star card in the Tarot deck is a powerful symbol of hope, inspiration, and renewal. By understanding and embracing the energy it represents, individuals can have faith in themselves, embrace their inner light, and trust that they are on the right path towards fulfillment and happiness. The Star card encourages the querent to have faith in themselves, embrace their inner light, and trust that they are on the right path towards fulfillment and happiness. It is a reminder that with hope, inspiration, and spiritual insight, we can achieve our goals and build a brighter future.</p>

                <Footer />
            </div>
        </>
    )
}
