import {React, useState} from "react"
import {Link} from 'react-router-dom';

export default function ZodiacComponant(props){

    
    const sign = props.sign
    const dispLink = props.dispLink
    const[data,setData] = useState({
        zodiacSign:props.sign,
        title:'',
        link:'',

        image:'',
        imageAltText:'' 
    })

    console.log("props.sign: ", props.sign)

    if(sign==='Aries')
        {
            data.zodiacSign = 'Aries'
            data.title = 'The Ram'
            data.link = '/aries'
            data.image = 'https://d3zlw6prgce9n.cloudfront.net/aries1.png'
            data.imageAltText = 'Aries, The Ram, in front of a cosmic background'

        }
    if(sign==='Taurus')
            {
                data.zodiacSign = 'Taurus'
                data.title = 'The Bull'
                data.link = '/taurus'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/taurus1.png'
                data.imageAltText = 'Taurus, The Bull, in front of a cosmic background'
            }
        if(sign==='Gemini')
            {
                data.zodiacSign = 'Gemini'
                data.title = 'The Twins'
                data.link = '/gemini'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/gemini1.png'
                data.imageAltText = 'Gemini, The Twins, in front of a cosmic background'
            }
        if(sign==='Cancer')
            {
                data.zodiacSign = 'Cancer'
                data.title = 'The Crab'
                data.link = '/cancer'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/cancer1.png'
                data.imageAltText = 'Cancer, The Crab, in front of a cosmic background'
            }
        if(sign==='Leo')
            {
                data.lifePathTitle = 'Leo'
                data.title = 'The Lion'
                data.link = '/leo'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/leo1.png'
                data.imageAltText = 'Leo, The Lion, in front of a cosmic background'
            }
        if(sign==='Virgo')
            {
                data.lifePathTitle = 'Virgo'
                data.title = 'The Maiden'
                data.link = '/virgo'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/virgo1.png'
                data.imageAltText = 'Virgo, The Maiden, in front of a cosmic background'
            }
        if(sign==='Libra')
            {
                data.lifePathTitle = 'Libra'
                data.title = 'The Scales'
                data.link = '/libra'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/libra2.png'
                data.imageAltText = 'Set of scales representing Libra, The Scales, in front of a cosmic background'
            }
        if(sign==='Scorpio')
            {
                data.lifePathTitle = 'Scorpio'
                data.title = 'The Scorpion'
                data.link = '/scorpio'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/scorpio1.png'
                data.imageAltText = 'Scorpio, The Scorpion, in front of a cosmic background'
            }
        if(sign==='Sagittarius')
                {
                    data.lifePathTitle = 'Sagittarius'
                    data.title = 'The Archer'
                    data.link = '/sagittarius'
                    data.image = 'https://d3zlw6prgce9n.cloudfront.net/sagittarius1.png'
                    data.imageAltText = 'Sagittarius, The Archer, in front of a cosmic background'
                }
        if(sign==='Capricorn')
            {
                data.zodiacSign = 'Capricorn'
                data.title = 'The Goat'
                data.link = '/capricorn'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/capricorn1.png'
                data.imageAltText = 'Capricorn, The Goat, in front of a cosmic background'
            
            }
        if(sign==='Aquarius')
            {
                data.zodiacSign = 'Aquarius'
                data.title = 'The Water Bearer'
                data.link = '/aquarius'
                
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/aquarius1.png'
                data.imageAltText = 'Aquarius, the Water Bearer, in front of a cosmic background'
                
            }
        if(sign==='Pisces')
            {
                data.zodiacSign = 'Pisces'
                data.title = 'The Fish'
                data.link = '/pisces'
                    
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/pisces1.png'
                data.imageAltText = 'Pisces, the Fish, in front of a cosmic background'
                    
            }




return(
    <>
        <div style={{display:'flex', flexDirection:'column', alignContent:'center', backgroundColor:'rgb(46,45,41)', width:'95%', maxWidth:'800px', margin:'auto', borderRadius:'20px', borderColor:'#646cff', borderWidth:'3px', borderStyle:'solid', marginBottom:'25px'}}>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{display:'flex', flex:'2', flexDirection:'column', margin:'auto', justifyContent:'center', textAlign:'center', marginTop:'0px'}}>
           <div> 
            <h1>
                Zodiac Sign: {data.sign}
            </h1>
            </div>
            <div>
                <h2>
                    {data.zodiacSign}
                </h2>                
            </div>
            <div>
                <h3>
                    {data.title}
                </h3>                
            </div>


        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{maxWidth:'600px'}}>


            </div>
            </div>
            </div>
            <div style={{display:'flex', flex:1, paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <img src={data.image} alt={data.imageAltText} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}}></img>
            </div>
            
        </div> 
        <div style={{margin:'auto'}}>
            {dispLink ? <Link to={data.link}> <h3>Learn More</h3></Link>:null}
        </div>
        </div>



    </>
    )

}