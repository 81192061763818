import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function JusticeTarot() {

    useEffect(() => {
        document.title = 'Tarot | Justice Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "justice_tarot",
        title: "justice_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Justice</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/justice_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/11_Justice.jpg' alt='Justice Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of Justice</h2>

                <p>Justice is the twelfth card in the Major Arcana of the Tarot deck, numbered as 11. It represents fairness, truth, and the law. The Justice card is a symbol of balance, integrity, and the consequences of our actions. This card encourages the querent to seek truth, act with integrity, and understand that justice will prevail in the end.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Justice card typically features a figure holding a sword in one hand and a set of scales in the other. The sword represents the power and clarity of truth, while the scales symbolize balance and fairness. The figure often sits between two pillars, symbolizing law and structure, and wears a crown, representing authority and knowledge. The overall imagery emphasizes the importance of truth, balance, and justice.</p>

                <p>Justice's number, 11, signifies balance, duality, and the principles of fairness and equality. This card's symbolism encourages the querent to seek truth, maintain integrity, and act with fairness in all matters.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Justice card appears in a reading, it signifies the importance of fairness, truth, and law. It suggests that the querent is facing a situation where justice and fairness are paramount. The Justice card encourages the querent to act with integrity, seek the truth, and ensure that their actions are fair and just. It is a reminder that justice will prevail, and that actions have consequences.</p>

                <p>In the context of personal growth, the Justice card can indicate a period of self-reflection and the need to evaluate one's actions and decisions. It may suggest that the querent is seeking to balance their life and ensure that they are acting in accordance with their values and principles. In relationships, the Justice card can signify the importance of honesty, fairness, and the need to resolve conflicts in a just and balanced manner.</p>

                <p>In career and financial matters, the Justice card can signal opportunities for legal matters, contracts, and decisions that require fairness and integrity. It advises the querent to act with honesty, ensure that all agreements are fair, and seek legal or professional advice if needed. The Justice card reminds the querent that fairness, truth, and integrity are essential for long-term success and balance.</p>

                <h2>Conclusion</h2>

                <p>The Justice card in the Tarot deck is a powerful symbol of fairness, truth, and the law. By understanding and embracing the energy it represents, individuals can seek truth, act with integrity, and understand that justice will prevail. The Justice card encourages the querent to maintain balance, act with fairness, and understand the consequences of their actions. It is a reminder that fairness, truth, and integrity are essential for long-term success and balance in all aspects of life.</p>

                <Footer />
            </div>
        </>
    )
}
