import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SixOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Six of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "six_of_swords",
        title: "six_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Six of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/six_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords06.jpg' alt='Six of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/five_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Five of Swords</Link>   
                    <Link to='/seven_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Seven of Swords</Link>
                </div>
                <h2>Overview of the Six of Swords</h2>

                <p>The Six of Swords is a card in the Minor Arcana of the Tarot deck that represents transition, recovery, and moving forward. It signifies a period of leaving behind difficulties, healing from past wounds, and embarking on a journey towards a better future. This card encourages the querent to embrace change, seek new horizons, and trust in the process of recovery and growth.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Six of Swords typically features a boat carrying passengers across calm waters, with swords standing upright in the boat. The scene depicts a sense of transition, movement, and the journey towards a new beginning. The overall imagery emphasizes themes of recovery, moving forward, and the process of healing and growth.</p>

                <p>The Six of Swords' symbolism emphasizes themes of transition, recovery, and moving forward. This card encourages the querent to embrace change, seek new horizons, and trust in the process of recovery and growth.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Six of Swords appears in a reading, it signifies transition, recovery, and moving forward. It suggests that the querent is in or will soon enter a period of leaving behind difficulties, healing from past wounds, and embarking on a journey towards a better future. The Six of Swords encourages the querent to embrace change, seek new horizons, and trust in the process of recovery and growth.</p>

                <p>In the context of personal growth, the Six of Swords can indicate a period of self-discovery and the development of resilience through transition and recovery. It may suggest that the querent is learning to leave behind past difficulties, heal from their wounds, and move forward towards a brighter future. In relationships, the Six of Swords can signify the need for mutual support, the resolution of conflicts, and the journey towards a healthier and more fulfilling partnership.</p>

                <p>In career and financial matters, the Six of Swords can signal opportunities for growth and success through transition and recovery. It advises the querent to embrace change, seek new horizons, and trust in the process of recovery and growth. The Six of Swords reminds the querent that with transition, recovery, and moving forward, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Six of Swords in the Tarot deck is a powerful symbol of transition, recovery, and moving forward. By understanding and embracing the energy it represents, individuals can leave behind difficulties, heal from past wounds, and embark on a journey towards a better future. The Six of Swords encourages the querent to embrace change, seek new horizons, and trust in the process of recovery and growth. It is a reminder that with transition, recovery, and moving forward, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
