import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacSnake() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Snake | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_snake",
        title: "chinese_zodiac_snake",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Snake</h1>
                    <Link to='/chinese_zodiac_snake' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacSnake.png' alt='A dragon in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_dragon' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Dragon</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_horse' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Horse</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Snake</h2>
                    <p>The Snake is the sixth animal in the Chinese Zodiac cycle. Those born under this sign are known for their wisdom, enigma, and grace. Snakes are deep thinkers and highly intuitive, often able to understand complex situations and uncover hidden truths. They possess a calm and mysterious demeanor, making them intriguing to others. However, their secretive nature can sometimes lead to trust issues and misunderstandings.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Snake</h2>
                    <p>The Snake is the sixth sign in the Chinese Zodiac, and those born under this sign are known for their wisdom, enigma, and grace. Snakes are deep thinkers and highly intuitive, often able to understand complex situations and uncover hidden truths. They possess a calm and mysterious demeanor, making them intriguing to others.</p>

                    <p>Snakes are also known for their intelligence and analytical skills. They are strategic and methodical in their approach, often making well-thought-out decisions. Their ability to see beyond the surface allows them to navigate through life's challenges with ease. However, their secretive nature can sometimes lead to trust issues and misunderstandings.</p>

                    <p>Despite their reserved exterior, Snakes have a compassionate and empathetic side. They care deeply about their loved ones and are always willing to offer support and guidance. Their loyal and protective nature makes them reliable friends and partners. However, their tendency to internalize emotions can sometimes lead to stress and anxiety.</p>

                    <p>Snakes are also known for their appreciation of beauty and elegance. They have a refined sense of taste and enjoy surrounding themselves with art and nature. This appreciation for aesthetics often translates into a sophisticated and harmonious lifestyle. However, their desire for control and perfection can sometimes make them overly critical and demanding.</p>
               </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Career and Compatibility of the Snake</h2>
                    <p>In their careers, Snakes excel in roles that require strategic thinking, intuition, and analysis. They are often successful in fields such as research, psychology, and finance, where their intelligence and insight can shine. Snakes are excellent problem-solvers who can navigate complex situations with ease. Their calm and methodical approach makes them valuable assets in any professional environment.</p>

                    <p>Snakes are known for their analytical skills and ability to uncover hidden truths. They thrive in environments that require deep thinking and careful planning. Their strategic mindset enables them to make sound decisions and achieve long-term success. However, their secretive nature can sometimes lead to trust issues and misunderstandings. It's important for Snakes to practice openness and transparency in their professional relationships.</p>

                    <p>When it comes to compatibility, Snakes are most compatible with the Ox, Rooster, and Dragon. These signs complement the Snake's traits and help to balance their enigmatic nature. The Ox offers stability and reliability, the Rooster provides diligence and organization, and the Dragon shares the Snake's ambition and vision. Snakes may experience challenges in relationships with the Tiger and Pig, as these signs may clash with the Snake's personality and create conflict. To maintain harmony, Snakes need to practice clear communication and empathy in their relationships.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Snake</h2>
                <p>Snakes generally enjoy good health, thanks to their calm and composed nature. They are mindful of their well-being and often take a holistic approach to health, incorporating both physical and mental practices into their routines. However, their tendency to internalize stress and emotions can sometimes lead to health issues. Snakes need to find effective ways to release tension, such as through meditation, yoga, or engaging in creative activities.</p>

                <p>Snakes are prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Snakes maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Snakes enjoy peaceful and harmonious environments. They are often drawn to activities that promote relaxation and introspection, such as reading, meditation, or engaging in artistic pursuits. Snakes also value comfort and enjoy creating cozy, welcoming homes. Surrounding themselves with beauty and tranquility helps them maintain a positive and balanced outlook on life.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_dragon' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Dragon</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_horse' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Horse</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
