
import {React, useState, useContext, useEffect} from "react"
import {Link} from 'react-router-dom';
import ChaldComponant from "../componants/ChaldComponant";
import PythagComponant from "../componants/PythagComponant";
import ZodiacComponant from "../componants/ZodiacComponant";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/footer";
import DateDropdown from "../componants/DateDropdown/DateDropdown";
import ReactGA from 'react-ga4';
import {convertLetterToNumberChaldean, convertLetterToNumberPythagorean, calculateLifePathNumber, addDigitsInNumber, calculateZodiac} from "../utilities/functions"
import { CSSTransition } from 'react-transition-group';
import Zodiac from "./Zodiac/Zodiac";
import ZodiacIcon from "../componants/ZodiacIcon";





export default function Home(){

    useEffect(() => {
        document.title = 'Astrology | Numerology | Tarot | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/home",
        title:"home",
    })

    const[data,setData] = useState({
        name:'',
        zodiacSign:'',
        chalCombinedNumber:0,
        chalNumber:0,
        month: '',
        day: '',
        year: '',
        lifePathNum:0,       

    })

    const handleSetMonth = (value) => {
        setData({...data, month: value});
    };
    const handleSetDay = (value) => {
        setData({...data, day: value});
    };
    const handleSetYear = (value) => {
        setData({...data, year: value});
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    ReactGA.send({
        hitType:"pageview",
        page:"/home",
        title:"Home",
    })

    const calculateName = () => {
        // Change the background color

        const chalCombined = calculateChaldeanNumber(data.name)
        var lifePathString = calculateLifePathNumber(data.month, data.day, data.year)

        var lifePathNumber = addDigitsInNumber(lifePathString)
        
        var chalNum = addDigitsInNumber(chalCombined)
        var finished = false;
        var numString = chalNum.toString();
        var zodiac = calculateZodiac(data.month, data.day)
        console.log("ZODIAC from CALC: ", zodiac)


        


        while(!finished)
                {
                        numString = chalNum.toString();
                        if(numString.length > 1)

                                {
                                        chalNum = addDigitsInNumber(chalNum);
                                }
                        else
                        {
                                finished =  true;
                        }  
                }



            finished = false;
        while(!finished){  
            lifePathString = lifePathNumber.toString();       
                if(lifePathString.length > 1){
                    lifePathNumber = addDigitsInNumber(lifePathNumber);
                    }
                else{
                    finished =  true;
                }  
            }


        setData({...data, chalCombinedNumber: chalCombined, chalNumber: chalNum, lifePathNum: lifePathNumber, zodiacSign: zodiac})


    };


    function calculateChaldeanNumber(name) {


        var listOfNumbers = [];
        var outputNumber = 0;

        for(var i = 0; i < name.length; i++)
            {
                const number = convertLetterToNumberChaldean(name[i])
                listOfNumbers.push(number)

            }

        for(var j = 0; j < listOfNumbers.length; j++)
            {
                outputNumber = outputNumber + listOfNumbers[j]

            }

        return outputNumber;

      }
      
    //   function addDigitsInNumber(number)
    //   {
    //     const numberString = number.toString();
    //     var sum = 0;

    //     for(var i = 0; i < numberString.length;i++)
    //             {
    //                     sum += parseInt(numberString[i])
    //             }

    //     return sum
        
    //   }



    return(
        <>
                <div><Navbar /></div>
            <div id="homeMainContainer" style={{display:'flex', margin:'auto'}}>
                
                <div id="homeHeaderContainer" style={{display:'flex', flex:'1', margin:'auto', justifyContent:'center', textAlign:'left'}}><h1>Welcome to Cosmic Secrets</h1></div>
            </div>
            
            <div style={{display:'flex', flexDirection:'row', margin:'auto', justifyContent:'center'}}>

            <Link to='/aries'>
                <ZodiacIcon zodiacSign='Aries' size='53px' margin='4px'/>
            </Link>
            <Link to='/taurus'>
                <ZodiacIcon zodiacSign='Taurus' size='53px' margin='4px'/>
            </Link>
            <Link to='/gemini'>
                <ZodiacIcon zodiacSign='Gemini' size='53px' margin='4px'/>
            </Link>
            <Link to='/cancer'>
                <ZodiacIcon zodiacSign='Cancer' size='53px' margin='4px'/>
            </Link>
            <Link to='/leo'>
                <ZodiacIcon zodiacSign='Leo' size='53px' margin='4px'/>
            </Link>
            <Link to='/virgo'>
                <ZodiacIcon zodiacSign='Virgo' size='53px' margin='4px'/>
            </Link>


            </div>
            

            <div style={{display:'flex', flexDirection:'row', margin:'auto', justifyContent:'center'}}>
            <Link to='/libra'>
                <ZodiacIcon zodiacSign='Libra' size='53px' margin='4px'/>
            </Link>
            <Link to='/scorpio'>
                <ZodiacIcon zodiacSign='Scorpio' size='53px' margin='4px'/>
            </Link>

            <Link to='/sagittarius'>
                <ZodiacIcon zodiacSign='Sagittarius' size='53px' margin='4px'/>
            </Link>
            <Link to='/capricorn'>
                <ZodiacIcon zodiacSign='Capricorn' size='53px' margin='4px'/>
            </Link>
            <Link to='/aquarius'>
                <ZodiacIcon zodiacSign='Aquarius' size='53px' margin='4px'/>
            </Link>
            <Link to='/pisces'>
                <ZodiacIcon zodiacSign='Pisces' size='53px' margin='4px'/>
            </Link>
                
            </div>


            <div style={{ display:'flex', flex:'1',margin:'auto', alignContent:'center', justifyContent:'center'}}>
            <div style={{ display:'flex', flexDirection:'column', maxWidth:'800px', width:'100%',justifyContent:'center', backgroundColor:'rgb(46,45,41)', borderRadius:'20px', borderColor:'#646cff', borderWidth:'3px', borderStyle:'solid', marginLeft:'20px', marginRight:'20px', marginTop:'20px' }}>
                <div style={{display:'flex', flex:1, margin:'auto'}}> 
                        <h2>
                        Get your Astrological Profile:
                        </h2> 
                </div>
                <div style={{display:'flex', flex:'row', flex:1, justifyContent:'center', margin:'auto'}}>

                    

                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, marginLeft:'15px', marginRight:'15px', marginTop:'15px', fontSize:'14pt'}}>
                            Full Name
                        </div>
                        <div style={{display:'flex', flex:1, marginLeft:'15px', marginRight:'15px', marginTop:'15px', fontSize:'14pt'}}>
                            Birthday
                        </div>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, alignItems:'center', marginRight:'15px', marginTop:'15px'}}>
                            <input type='text' style={{display:'flex', flex:1, fontSize:'14pt', borderRadius:'8px', fontWeight:'bold'}}value={data.name} onChange={(e) => setData({...data, name: e.target.value})}></input>
                        </div>
                        <div style={{marginTop:'15px', marginRight:'15px'}}>
                            <DateDropdown month={data.month} setMonth={handleSetMonth} day={data.day} setDay={handleSetDay} year={data.year} setYear={handleSetYear}/>
                        </div>
                    </div>                       

                    
                </div>
                
                <div style={{display:'flex', flex:'row', justifyContent:'center', marginTop:'50px', marginBottom:'50px'}}>
                        <button onClick={calculateName} style={{fontSize: '16pt', borderRadius:'5px'}}>Get Free Profile</button>
                </div>  

            </div>
            </div>
        <div style={{alignContent:'center', marginTop:'25px'}}>
                {data.zodiacSign !== "" ? <ZodiacComponant sign = {data.zodiacSign} dispLink = 'true' style={{marginBottom:'50px'}}/>:null}
                {data.lifePathNum !== 0 ? <PythagComponant num = {data.lifePathNum} dispLink = 'true' />:null}
                {data.chalNumber !== 0 ? <ChaldComponant chNum = {data.chalNumber} dispLink = 'true' />:null}               
        </div>

        <Footer />
            
       </>

    )
}