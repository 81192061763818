import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import BlogTile from "../../componants/BlogTile";
import ReactGA from 'react-ga4';
import { post1, post2 } from "./BlogEntries";


export default function Blog(){

    useEffect(() => {
        document.title = 'Blog | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/blog",
        title:"blog",
    })

    const blogPost1 = post1()
    const blogPost2 = post2()


    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    
    <div ref={topContainer} />
    <div>
    <h1>Musings</h1>
    <div style={{display:'flex', flexDirection:'row', width:'90%', maxWidth:'800px', margin:'auto', justifyContent:'center'}}>
        
        <BlogTile number={blogPost1.number} postAddress='/blog/1'author={blogPost1.author} date={blogPost1.date} teaserContent={blogPost1.teaserContent} title={blogPost1.title} img={blogPost1.img} altText='Tyrion Lannister'/>
        <BlogTile number={blogPost2.number} postAddress='/blog/2' author={blogPost2.author} date={blogPost2.date} teaserContent={blogPost2.teaserContent} title={blogPost2.title} img={blogPost2.img} altText='Clay figures of a man and woman holding hands, with astrological symbols around them'/>



        </div>

    <Footer />

    </div>
</>)}