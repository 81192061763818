import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function SunTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Sun Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "sun_tarot",
        title: "sun_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Sun</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/sun_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/19_Sun.jpg' alt='Sun Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Sun</h2>

                <p>The Sun is the nineteenth card in the Major Arcana of the Tarot deck, numbered as 19. It represents joy, success, and vitality. The Sun is a symbol of positivity, enlightenment, and the abundance of life. This card encourages the querent to embrace happiness, celebrate their achievements, and bask in the warmth of success and fulfillment.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Sun card typically features a bright sun shining in the sky, illuminating everything with its light. Below the sun, a child rides a white horse, symbolizing innocence, purity, and freedom. The child holds a red banner, representing vitality and celebration. Sunflowers bloom in the background, symbolizing abundance and the joy of life. The overall imagery emphasizes themes of joy, success, and the celebration of life.</p>

                <p>The Sun's number, 19, signifies completion, fulfillment, and the culmination of a journey. This card's symbolism encourages the querent to embrace joy, celebrate their achievements, and bask in the warmth of success and fulfillment.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Sun card appears in a reading, it signifies joy, success, and vitality. It suggests that the querent is in a period of positivity and fulfillment, where their achievements are being recognized and celebrated. The Sun card encourages the querent to embrace happiness, celebrate their successes, and enjoy the abundance of life.</p>

                <p>In the context of personal growth, the Sun card can indicate a period of enlightenment and self-discovery. It may suggest that the querent is experiencing a deep sense of joy and fulfillment, and is able to see the positive aspects of their journey. In relationships, the Sun card can signify the presence of joy, harmony, and mutual success, emphasizing the importance of celebrating achievements together.</p>

                <p>In career and financial matters, the Sun card can signal opportunities for success and recognition. It advises the querent to embrace their achievements, celebrate their successes, and enjoy the abundance that comes with hard work and dedication. The Sun card reminds the querent that with positivity, enlightenment, and the celebration of life, they can achieve their goals and enjoy the rewards of their efforts.</p>

                <h2>Conclusion</h2>

                <p>The Sun card in the Tarot deck is a powerful symbol of joy, success, and vitality. By understanding and embracing the energy it represents, individuals can embrace happiness, celebrate their achievements, and bask in the warmth of success and fulfillment. The Sun card encourages the querent to embrace joy, celebrate their successes, and enjoy the abundance of life. It is a reminder that with positivity, enlightenment, and the celebration of life, we can achieve our goals and enjoy the rewards of our efforts.</p>

                <Footer />
            </div>
        </>
    )
}
