import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacOx() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Ox | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_ox",
        title: "chinese_zodiac_ox",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Ox</h1>
                    <Link to='/chinese_zodiac_ox' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacOx.png' alt='An ox in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>

                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rat</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_tiger' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Tiger</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Ox</h2>
                    <p>The Ox is the second animal in the Chinese Zodiac cycle. Those born under this sign are known for their hardworking, reliable, and determined nature. Oxen are diligent and possess a strong sense of responsibility. They are methodical and patient, often achieving success through perseverance and consistent effort. Oxen are also known for their honesty and dependability. However, they can be stubborn and may find it difficult to adapt to change.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Ox</h2>
                    <p>The Ox is the second sign in the Chinese Zodiac, and those born under this sign are known for their strength, reliability, and hardworking nature. Oxen are determined and persistent, often setting clear goals and working steadily towards them. Their patience and methodical approach make them excellent at overcoming obstacles and achieving long-term success.</p>

                    <p>Oxen are also known for their honesty and integrity. They value truth and fairness, and their straightforward nature makes them trustworthy companions. While they may not be the most outgoing or sociable, their loyalty and dependability make them cherished friends and partners. Oxen take their commitments seriously and are always ready to lend support to their loved ones.</p>

                    <p>However, Oxen can sometimes be stubborn and inflexible. Their strong will and determination can lead them to be resistant to change and new ideas. It is important for Oxen to learn to be more adaptable and open-minded, as this will help them navigate the complexities of life more effectively. Additionally, their tendency to work tirelessly can sometimes lead to burnout, so it is crucial for Oxen to find a balance between work and relaxation.</p>

                    <p>Oxen are also known for their practicality and grounded nature. They prefer stability and security, often seeking out environments that provide them with a sense of order and predictability. While this can make them excellent planners and organizers, it can also lead to a reluctance to take risks or explore new opportunities. Embracing a more adventurous spirit can help Oxen enrich their lives and broaden their horizons.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Career and Compatibility of the Ox</h2>
                    <p>In their careers, Oxen excel in roles that require patience, determination, and attention to detail. They are often successful in fields such as agriculture, engineering, and finance, where their methodical and diligent nature can shine. Oxen are reliable team members and leaders, known for their ability to stay focused and see projects through to completion. Their practical and grounded approach makes them well-suited for careers that require long-term planning and stability.</p>

                    <p>Oxen are known for their strong work ethic and commitment to excellence. They take pride in their ability to produce high-quality work and are often seen as pillars of reliability in their workplaces. Their perseverance and dedication enable them to overcome obstacles and achieve their goals. However, their tendency to be stubborn and resistant to change can sometimes hinder their progress. It's important for Oxen to remain open to new ideas and approaches.</p>

                    <p>When it comes to compatibility, Oxen are most compatible with the Rat, Snake, and Rooster. These signs complement the Ox's traits and help to balance their steadfast nature. The Rat offers resourcefulness and quick thinking, the Snake provides wisdom and insight, and the Rooster shares the Ox's hardworking and reliable nature. Oxen may experience challenges in relationships with the Sheep and Horse, as these signs may clash with the Ox's personality and create conflict. To maintain harmony, Oxen need to practice flexibility and open-mindedness in their relationships.</p>
                 </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                                <h2>Health and Lifestyle of the Ox</h2>
                <p>Oxen generally have a robust constitution and enjoy good health. Their disciplined and steady nature helps them maintain a balanced lifestyle. However, their tendency to overwork and their stubbornness can sometimes lead to physical and mental strain. It's important for Oxen to take regular breaks and incorporate relaxation into their routines to avoid burnout. Regular exercise, a nutritious diet, and adequate rest are crucial for maintaining their health.</p>

                <p>Oxen are prone to stress due to their high expectations and perfectionist tendencies. They need to find effective ways to manage stress, such as through meditation, mindfulness practices, or engaging in hobbies they enjoy. Spending time in nature can also be particularly beneficial for Oxen, helping them to relax and recharge.</p>

                <p>In terms of lifestyle, Oxen prefer stability and routine. They enjoy activities that involve physical labor or craftsmanship, such as gardening, woodworking, or other hands-on hobbies. Oxen also appreciate comfort and often create cozy, welcoming homes. They value quality over quantity and are willing to invest in durable and high-quality items that bring them long-term satisfaction.</p>
                            </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rat</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_tiger' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Tiger</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
