import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Aries(){

    ReactGA.send({
        hitType:"pageview",
        page:"/aries",
        title:"aries",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Aries: The Ram (March 21 - April 19)</h1>
                
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/aries1.png' alt='Aries the Ram' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/pisces' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Pisces</Link>   
                    Next:      <Link to='/taurus' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Taurus</Link>
                </div>
                
                <p>Aries is the first sign of the zodiac, symbolizing new beginnings and leadership. Represented by the Ram, Aries is ruled by Mars, the planet of action, energy, and desire. People born under this sign are known for their fiery passion, determination, and pioneering spirit.</p>

                <h2>Personality Traits</h2>
                <p>Aries individuals are characterized by their boldness and enthusiasm. They are natural leaders, often taking the initiative in both personal and professional situations. Aries are known for their courage and willingness to face challenges head-on. They thrive in dynamic environments and are always eager to explore new opportunities.</p>
                <p>Despite their strong and assertive exterior, Aries can also be incredibly warm-hearted and generous. They are fiercely loyal to their friends and loved ones, always ready to defend and support them. However, their impulsive nature can sometimes lead to impatience and a tendency to act without considering the consequences.</p>

                <h2>Career</h2>
                <p>In the professional realm, Aries excels in roles that require leadership, innovation, and a proactive approach. They are well-suited for careers in entrepreneurship, management, sports, and any field that demands high energy and quick decision-making. Aries' competitive spirit drives them to excel and strive for excellence in whatever they do.</p>
                <p>Aries employees are known for their initiative and ability to take charge of projects. They are not afraid to take risks and often bring a fresh perspective to their work. However, they may need to work on patience and collaboration, as their independent nature can sometimes clash with team dynamics.</p>

                <h2>Relationships</h2>
                <p>In relationships, Aries is passionate and adventurous. They seek partners who can match their energy and enthusiasm for life. Aries values honesty and directness, and they appreciate partners who are open and straightforward. They are drawn to individuals who challenge them and keep the excitement alive.</p>
                <p>As friends, Aries are loyal and protective. They are always ready to stand up for their friends and offer support. In romantic relationships, they are affectionate and enjoy spontaneity. However, their impulsive nature can sometimes lead to conflicts, so finding a balance and practicing patience is key to maintaining harmonious relationships.</p>
                </div>

            <Footer />
            </>
    )}
