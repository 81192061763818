import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Libra(){

    useEffect(() => {
        document.title = 'Zodiac | Libra | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/libra",
        title:"libra",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Libra: The Scales (September 23 - October 22)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/libra2.png' alt='Libra the Scales' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/virgo' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Virgo</Link>   
                    Next:      <Link to='/scorpio' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Scorpio</Link>
                </div>
                <p>Libra is the seventh sign of the zodiac, symbolizing balance, harmony, and partnership. Represented by the Scales, Libra is ruled by Venus, the planet of love, beauty, and diplomacy. People born under this sign are known for their charm, sociability, and strong sense of justice.</p>

                <h2>Personality Traits</h2>
                <p>Libra individuals are characterized by their diplomatic and fair-minded nature. They are natural peacemakers who seek harmony in all aspects of life. Libras are known for their charm and social grace, often excelling in situations that require tact and negotiation. They value beauty and aesthetics, and they have a keen eye for art and design.</p>
                <p>Despite their easygoing and amiable demeanor, Libras can be indecisive and avoid confrontations. They strive to keep the peace and may sometimes suppress their own needs to please others. While they are excellent at seeing multiple perspectives, they can struggle with making decisions and committing to a course of action.</p>

                <h2>Career</h2>
                <p>In the professional realm, Libra excels in roles that require diplomacy, collaboration, and a strong aesthetic sense. They are well-suited for careers in law, public relations, art, design, and any field that involves working with people and creating harmony. Libras' ability to mediate and bring balance makes them valuable team members and leaders.</p>
                <p>Libra employees are known for their cooperative and balanced approach to work. They thrive in environments that value teamwork and mutual respect. However, they may need to work on their decision-making skills and ability to assert themselves, as their desire to avoid conflict can sometimes hinder their effectiveness.</p>

                <h2>Relationships</h2>
                <p>In relationships, Libra is affectionate and romantic. They seek partners who can provide balance and harmony in their lives. Libra values equality and fairness, and they appreciate partners who are communicative and considerate. They are drawn to individuals who share their love for beauty and aesthetics.</p>
                <p>As friends, Libras are loyal and supportive. They are excellent listeners and enjoy creating a peaceful and enjoyable atmosphere for their loved ones. In romantic relationships, they are attentive and enjoy nurturing their partner. However, their tendency to avoid conflict can sometimes lead to unresolved issues, so open communication and mutual understanding are key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Libra is a sign that embodies balance, harmony, and partnership. Their charm, sociability, and diplomatic nature make them engaging and well-loved individuals. By embracing their strengths and working on their challenges, Libra individuals can lead fulfilling lives filled with love, beauty, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}