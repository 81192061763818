import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacDog() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Dog | Cosmic Secrets';
    }, []);
    

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_dog",
        title: "chinese_zodiac_dog",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Dog</h1>
                    <Link to='/chinese_zodiac_dog' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacDog.png' alt='A dog in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rooster' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rooster</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_pig' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Pig</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Dog</h2>
                    <p>The Dog is the eleventh animal in the Chinese Zodiac cycle. Those born under this sign are known for their loyalty, honesty, and reliability. Dogs are compassionate and protective, often going out of their way to help and support others. They value justice and fairness and have a strong sense of duty. However, their loyalty can sometimes make them overly cautious and skeptical of new experiences and people.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Dog</h2>
                    <p>The Dog is the eleventh sign in the Chinese Zodiac, and those born under this sign are known for their loyalty, honesty, and reliability. Dogs are compassionate and protective, often going out of their way to help and support others. They value justice and fairness and have a strong sense of duty.</p>

                    <p>Dogs are also known for their intelligence and quick thinking. They are resourceful and adaptable, often finding creative solutions to problems. Their keen intuition allows them to read situations and people effectively, helping them navigate social interactions with ease. However, their sensitivity can sometimes make them prone to anxiety and overthinking.</p>

                    <p>Despite their positive traits, Dogs can be overly cautious and skeptical. Their desire to protect themselves and their loved ones can sometimes make them hesitant to trust others or take risks. It is important for Dogs to learn to balance their cautious nature with openness and a willingness to embrace new experiences.</p>

                    <p>Dogs are also known for their strong sense of duty and responsibility. They take their commitments seriously and are always willing to lend a helping hand. Their loyalty and dedication make them reliable friends and partners. However, their protective nature can sometimes make them appear possessive or controlling. It is important for Dogs to balance their loyalty with respect for others' independence and individuality.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Dog</h2>
                <p>In their careers, Dogs excel in roles that require loyalty, reliability, and a strong sense of duty. They are often successful in fields such as law, healthcare, and public service, where their commitment and integrity can shine. Dogs are excellent team players who can build strong, trusting relationships with colleagues. Their protective and compassionate nature makes them valuable assets in any professional environment.</p>

                <p>Dogs are known for their dedication and strong sense of responsibility. They thrive in environments that allow them to help and protect others. Their loyalty and commitment often lead to long-term success and fulfillment in their careers. However, their cautious nature can sometimes make them hesitant to take risks and embrace new opportunities. It's important for Dogs to develop confidence in their abilities and trust their instincts.</p>

                <p>When it comes to compatibility, Dogs are most compatible with the Rabbit, Tiger, and Horse. These signs complement the Dog's traits and help to balance their loyal nature. The Rabbit offers empathy and understanding, the Tiger provides boldness and excitement, and the Horse shares the Dog's loyalty and enthusiasm. Dogs may experience challenges in relationships with the Dragon and Goat, as these signs may clash with the Dog's personality and create conflict. To maintain harmony, Dogs need to practice patience and understanding in their relationships.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Dog</h2>
                <p>Dogs generally enjoy good health, thanks to their active and energetic nature. They are always on the go, which helps them maintain a good level of physical fitness. However, their tendency to overthink and worry can sometimes lead to stress and anxiety. It's important for Dogs to find effective ways to manage stress and anxiety, such as through meditation, yoga, or other relaxation techniques. Regular exercise, a balanced diet, and sufficient sleep are essential for Dogs to stay healthy and energized.</p>

                <p>Dogs are also prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Dogs maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Dogs enjoy socializing and engaging in activities that stimulate their minds. They are often drawn to hobbies that involve problem-solving and strategic thinking, such as puzzles, games, or learning new skills. Dogs also appreciate the finer things in life and enjoy indulging in luxury and comfort when possible. However, it's important for them to strike a balance and avoid overindulgence to maintain their health and well-being.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_rooster' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Rooster</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_pig' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Pig</Link>
                </div>
                
            </div>
            <Footer />
        </>
    )
}
