import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Taurus(){

    useEffect(() => {
        document.title = 'Zodiac | Taurus | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/taurus",
        title:"taurus",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Taurus: The Bull (April 20 - May 20)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/taurus1.png' alt='Taurus the Bull' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/aries' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Aries</Link>   
                    Next:      <Link to='/gemini' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Gemini</Link>
                </div>
                <p>Taurus is the second sign of the zodiac, symbolizing stability, persistence, and practicality. Represented by the Bull, Taurus is ruled by Venus, the planet of love, beauty, and pleasure. People born under this sign are known for their dependable nature, strong will, and appreciation for the finer things in life.</p>

                <h2>Personality Traits</h2>
                <p>Taurus individuals are characterized by their practical and reliable nature. They value stability and security, often seeking comfort and consistency in their lives. Taureans are known for their patience and determination, able to see projects through to completion with steady and persistent effort. They have a strong sense of loyalty and are dependable friends and partners.</p>
                <p>Despite their sometimes stubborn and resistant-to-change demeanor, Taureans have a deep appreciation for beauty and luxury. They enjoy indulging in the pleasures of life, from good food to beautiful surroundings. While their practicality is a strength, it can also make them resistant to change and new ideas, so they benefit from staying open to new experiences.</p>

                <h2>Career</h2>
                <p>In the professional realm, Taurus excels in roles that require consistency, patience, and a practical approach. They are well-suited for careers in finance, real estate, agriculture, and any field that involves working with tangible results and long-term projects. Taureans' reliability and strong work ethic make them valuable assets in any workplace.</p>
                <p>Taurus employees are known for their dedication and ability to remain calm under pressure. They bring a sense of stability and dependability to their work, often becoming the backbone of their teams. However, they may need to work on their flexibility and willingness to adapt to new situations, as their preference for routine can sometimes limit their growth.</p>

                <h2>Relationships</h2>
                <p>In relationships, Taurus is loyal and affectionate. They seek partners who can provide stability and share their appreciation for the finer things in life. Taurus values trust and reliability, and they appreciate partners who are honest and committed. They are drawn to individuals who can offer a sense of security and a stable, loving relationship.</p>
                <p>As friends, Taureans are dependable and supportive. They are always there to lend a helping hand and offer practical advice. In romantic relationships, they are deeply caring and enjoy creating a comfortable and nurturing environment for their partners. However, their stubbornness can sometimes lead to conflicts, so finding a balance between persistence and compromise is key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Taurus is a sign that embodies stability, persistence, and practicality. Their dependable nature, strong will, and appreciation for beauty make them reliable and steadfast individuals. By embracing their strengths and working on their challenges, Taurus individuals can lead fulfilling lives filled with security, comfort, and meaningful connections.</p>
            </div>

            <Footer />
            </>
    )}