import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function EightOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Eight of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "eight_of_cups",
        title: "eight_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Eight of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/eight_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups08.jpg' alt='Eight of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/seven_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Seven of Cups</Link>   
                    <Link to='/nine_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Nine of Cups</Link>
                </div>
                <h2>Overview of the Eight of Cups</h2>

                <p>The Eight of Cups is a card in the Minor Arcana of the Tarot deck that represents departure, letting go, and the search for deeper meaning. It signifies a period of transition, where one feels compelled to leave behind what is familiar and seek out new experiences or a higher purpose. This card encourages the querent to follow their inner guidance, let go of unfulfilling situations, and embark on a journey of self-discovery and growth.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Eight of Cups typically features a figure walking away from a stack of eight cups, heading towards a distant mountain or a new horizon. The cups are arranged in a way that suggests something is missing, symbolizing the unfulfilled emotions or desires that prompt the departure. The overall imagery emphasizes themes of departure, letting go, and the search for deeper meaning.</p>

                <p>The Eight of Cups' symbolism emphasizes themes of departure, letting go, and the search for deeper meaning. This card encourages the querent to follow their inner guidance, let go of unfulfilling situations, and embark on a journey of self-discovery and growth.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Eight of Cups appears in a reading, it signifies departure, letting go, and the search for deeper meaning. It suggests that the querent is in a period of transition, feeling compelled to leave behind what is familiar and seek out new experiences or a higher purpose. The Eight of Cups encourages the querent to follow their inner guidance, let go of unfulfilling situations, and embark on a journey of self-discovery and growth.</p>

                <p>In the context of personal growth, the Eight of Cups can indicate a period of introspection and the need to seek out new experiences or a higher purpose. It may suggest that the querent is recognizing the need to leave behind unfulfilling situations and is ready to embark on a journey of self-discovery and growth. In relationships, the Eight of Cups can signify the need to let go of unfulfilling connections and seek out more meaningful and fulfilling relationships.</p>

                <p>In career and financial matters, the Eight of Cups can signal opportunities for growth through departure and the search for deeper meaning. It advises the querent to follow their inner guidance, let go of unfulfilling situations, and seek out new experiences or a higher purpose. The Eight of Cups reminds the querent that with departure, letting go, and the search for deeper meaning, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Eight of Cups in the Tarot deck is a powerful symbol of departure, letting go, and the search for deeper meaning. By understanding and embracing the energy it represents, individuals can follow their inner guidance, let go of unfulfilling situations, and embark on a journey of self-discovery and growth. The Eight of Cups encourages the querent to follow their inner guidance, let go of unfulfilling situations, and seek out new experiences or a higher purpose. It is a reminder that with departure, letting go, and the search for deeper meaning, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
