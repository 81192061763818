import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function AceOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Ace of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "ace_of_cups",
        title: "ace_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Ace of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/ace_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups01.jpg' alt='Ace of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/king_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: King of Cups</Link>   
                    <Link to='/two_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Two of Cups</Link>
                </div>

                <h2>Overview of the Ace of Cups</h2>

                <p>The Ace of Cups is a powerful card in the Minor Arcana of the Tarot deck, representing new beginnings, emotional fulfillment, and spiritual awakening. It signifies the start of a new emotional journey, filled with love, compassion, and joy. This card encourages the querent to embrace their emotions, open their heart, and allow the flow of positive energy to bring about personal and spiritual growth.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Ace of Cups typically features a hand emerging from the clouds, holding a cup overflowing with water. The water spills into a tranquil pool, symbolizing the abundance of emotions and the flow of spiritual energy. Surrounding the cup are five streams of water, representing the five senses and the interconnectedness of emotional experiences. A dove often appears above the cup, symbolizing peace, love, and divine inspiration.</p>

                <p>The Ace of Cups' symbolism emphasizes themes of emotional renewal, spiritual awakening, and the abundance of love and compassion. This card encourages the querent to open their heart, embrace their emotions, and allow the flow of positive energy to bring about personal and spiritual growth.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Ace of Cups appears in a reading, it signifies new beginnings, emotional fulfillment, and spiritual awakening. It suggests that the querent is entering a period of emotional renewal, where they are open to love, compassion, and joy. The Ace of Cups encourages the querent to embrace their emotions, open their heart, and allow the flow of positive energy to bring about personal and spiritual growth.</p>

                <p>In the context of personal growth, the Ace of Cups can indicate a period of emotional healing and spiritual awakening. It may suggest that the querent is becoming more in touch with their emotions and is ready to embrace love, compassion, and joy. In relationships, the Ace of Cups can signify the start of a new romance, the deepening of an existing relationship, or the rekindling of love and affection.</p>

                <p>In career and financial matters, the Ace of Cups can signal opportunities for emotional fulfillment and creative expression. It advises the querent to pursue their passions, embrace their creativity, and allow their emotions to guide them towards success and fulfillment. The Ace of Cups reminds the querent that with love, compassion, and spiritual awakening, they can achieve their goals and find true happiness.</p>

                <h2>Conclusion</h2>

                <p>The Ace of Cups in the Tarot deck is a powerful symbol of new beginnings, emotional fulfillment, and spiritual awakening. By understanding and embracing the energy it represents, individuals can open their hearts, embrace their emotions, and allow the flow of positive energy to bring about personal and spiritual growth. The Ace of Cups encourages the querent to embrace love, compassion, and joy, and to allow these positive energies to guide them towards fulfillment and happiness. It is a reminder that with love and emotional renewal, we can achieve our goals and find true happiness.</p>

                <Footer />
            </div>
        </>
    )
}
