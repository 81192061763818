import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import ChaldComponant from '../../componants/ChaldComponant';
import PythagComponant from "../../componants/PythagComponant";
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default function LifePath7() {

  useEffect(() => {
    document.title = 'Pythagorean Numerology | Life Path Number 7 | Cosmic Secrets';
}, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/life_path_7",
        title:"life_path_7",})



    const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
        }, []);




    return (
      <>
      <Navbar />
      <div ref={topContainer} />
      <div style={{marginTop:'25px'}}>
      <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
        <h1>Life Path Number 7 - The Seeker</h1>
        <div style={{ margin:'auto', textAlign:'center'}}>
            <img src='https://d3zlw6prgce9n.cloudfront.net/LifePath7.png' alt='Man representing The Seeker looking down intently at a book with purple cosmic background' style={{ width: '300px', height: '300px', borderRadius: '10px' }} />
        </div>
        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                Previous:<Link to='/life_path_6' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Number 6</Link>   
                Next:      <Link to='/life_path_8' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Number 8</Link>
             </div>
        <p>In Pythagorean numerology, Life Path Number 7 is known as the number of the seeker. People with this life path number are often seen as introspective, analytical, and spiritual individuals who are dedicated to exploring the deeper meaning of life. They are thoughtful, introspective, and have a natural curiosity about the mysteries of the universe.</p>
        
        <h2>Personality</h2>
        <p>Individuals with Life Path Number 7 possess a reflective and inquisitive personality. They are introspective, philosophical, and often drawn to the study of metaphysical and spiritual subjects. These individuals thrive on understanding the world around them and are constantly seeking knowledge and wisdom. Their analytical nature helps them to think deeply and critically about life, often leading them to profound insights and discoveries.</p>

        <h2>Career</h2>
        <p>Career-wise, those with Life Path Number 7 excel in roles that involve research, analysis, and deep thinking. They are well-suited for careers in science, philosophy, psychology, spirituality, and any field that involves investigation and exploration. Their intellectual curiosity and ability to focus deeply make them effective in roles that require concentration and critical thinking. They thrive in environments that allow them to work independently and delve into complex subjects.</p>

        <h2>Relationships</h2>
        <p>In relationships, Life Path Number 7 individuals value intellectual connection, depth, and mutual understanding. They are thoughtful and introspective partners who seek to build a relationship based on shared values and deep conversations. Their introspective nature means they may need personal space and time for reflection, which can sometimes make them seem distant. However, they are loyal and devoted partners who are committed to understanding and supporting their loved ones. Communication and mutual respect are key to maintaining a healthy relationship with a Life Path Number 7 individual.</p>

        <h2>Strengths</h2>
        <p>The strengths of Life Path Number 7 include their analytical abilities, intuition, and thirst for knowledge. They are highly intelligent and have a natural talent for research and investigation. Their ability to think deeply and critically helps them to uncover hidden truths and gain profound insights. They are also introspective and intuitive, often possessing a strong sense of inner wisdom and spiritual awareness.</p>

        <h2>Weaknesses</h2>
        <p>Despite their many strengths, Life Path Number 7 individuals can also have weaknesses. Their strong desire for solitude and introspection can sometimes lead to isolation and difficulty in forming close relationships. They may struggle with being overly critical and skeptical, finding it hard to trust others or accept things at face value. Additionally, their focus on intellectual and spiritual pursuits can sometimes cause them to neglect practical matters and daily responsibilities. It is important for them to learn to balance their quest for knowledge with practical living and social interaction.</p>
        
        <h2>Conclusion</h2>
        <p>In conclusion, Life Path Number 7 is the path of the seeker and philosopher. Those with this life path are driven by a desire to understand the deeper meaning of life and explore the mysteries of the universe. By embracing their strengths and addressing their weaknesses, they can lead fulfilling and impactful lives, gaining wisdom and insight while contributing to the knowledge and understanding of the world around them.</p>
        </div>


      
      </div>
      <Footer />
      </>
    )
}
