import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function KnightOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Knight of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "knight_of_swords",
        title: "knight_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Knight of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/knight_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords12.jpg' alt='Knight of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/page_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Page of Swords</Link>   
                    <Link to='/queen_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Queen of Swords</Link>
                </div>
                <h2>Overview of the Knight of Swords</h2>

                <p>The Knight of Swords is a card in the Minor Arcana of the Tarot deck that represents action, determination, and intellectual ambition. It signifies a period of focused energy, decisive action, and the pursuit of goals with intensity and determination. This card encourages the querent to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Knight of Swords typically features a knight charging forward on a horse, holding a raised sword, and moving with great speed. The scene depicts a sense of action, determination, and the pursuit of goals with intensity. The overall imagery emphasizes themes of focused energy, decisive action, and intellectual ambition.</p>

                <p>The Knight of Swords' symbolism emphasizes themes of action, determination, and intellectual ambition. This card encourages the querent to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Knight of Swords appears in a reading, it signifies action, determination, and intellectual ambition. It suggests that the querent is in or will soon enter a period of focused energy, decisive action, and the pursuit of goals with intensity and determination. The Knight of Swords encourages the querent to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity.</p>

                <p>In the context of personal growth, the Knight of Swords can indicate a period of self-discovery and the development of determination and intellectual ambition. It may suggest that the querent is learning to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity. In relationships, the Knight of Swords can signify the need for clear communication, mutual support, and the pursuit of shared goals with determination and intensity.</p>

                <p>In career and financial matters, the Knight of Swords can signal opportunities for growth and success through focused energy, decisive action, and intellectual ambition. It advises the querent to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity. The Knight of Swords reminds the querent that with action, determination, and intellectual ambition, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Knight of Swords in the Tarot deck is a powerful symbol of action, determination, and intellectual ambition. By understanding and embracing the energy it represents, individuals can embrace their drive, take swift action, and pursue their ambitions with confidence and clarity. The Knight of Swords encourages the querent to embrace their drive, take swift action, and pursue their ambitions with confidence and clarity. It is a reminder that with action, determination, and intellectual ambition, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
