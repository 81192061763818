import {React, useState} from "react"
import {Link} from 'react-router-dom';

export default function PythagComponant(props){

    
    const number = props.num
    const dispLink = props.dispLink
    const[data,setData] = useState({
        lifePathNumber:props.num,
        lifePathTitle:"",
        image:'',
        imageAltText:'' 
    })


    if(number==1)
        {
            data.lifePathTitle = 'The Leader'

            data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath1.png'
            data.imageAltText = 'Man in a suit standing in front of a swirling background, representing The Leader'

        }
    if(number==2)
            {
                data.lifePathTitle = 'The Diplomat'

                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath2.png'
                data.imageAltText = 'Diplomat with outstretched arms in front of cosmic background and olive branches in front'
            }
    if(number==3)
            {
                data.lifePathTitle = 'The Communicator'

                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath3.png'
                data.imageAltText = 'Woman in front of swirling cosmic background, representing The Communicator'
            }
    if(number==4)
            {
                data.lifePathTitle = 'The Builder'

                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath4.png'
                data.imageAltText = 'Man working on blueprints, representing The Builder, with cosmic background'
            }
    if(number==5)
            {
                data.lifePathTitle = 'The Adventurer'

                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath5.png'
                data.imageAltText = 'Man with backpack represeting The Adventurer running through cosmic background'
            }
   if(number==6)
            {
                data.lifePathTitle = 'The Nurturer'

                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath6.png'
                data.imageAltText = 'Woman representing The Nurturer looking down at hands with swirling cosmic background'
            }
    if(number==7)
            {
                data.lifePathTitle = 'The Seeker'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath7.png'
                data.imageAltText = 'Man representing The Seeker looking down intently at a book with purple cosmic background'
            }
    if(number==8)
            {
                data.lifePathTitle = 'The Achiever'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath8.png'
                data.imageAltText = 'Man wearing suit representing The Achiever standing on top of the world with cosmic background'
            }
    if(number==9)
            {
                data.lifePathTitle = 'The Humanitarian'
                data.image = 'https://d3zlw6prgce9n.cloudfront.net/LifePath9.png'
                data.imageAltText = 'Woman representing The Humanitarian giving to outstretched hands with swirling cosmic background'
            }




return(
    <>
        <div style={{display:'flex', flexDirection:'column', alignContent:'center', backgroundColor:'rgb(46,45,41)', width:'95%', maxWidth:'800px', margin:'auto', marginBottom:'25px', borderRadius:'20px', borderColor:'#646cff', borderWidth:'3px', borderStyle:'solid'}}>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{display:'flex', flex:'2', flexDirection:'column', margin:'auto', justifyContent:'center', textAlign:'center', marginTop:'0px'}}>
            <div>
                <h1>
                    Life Path Number {number}
                </h1>
            </div>
            <div >
                <h2>
                    {data.lifePathTitle}
                </h2>
            </div>
            
            
        
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{maxWidth:'600px'}}>


            </div>
            </div>
            </div>
            <div style={{display:'flex', flex:1, paddingBottom:'2%',paddingTop:'2%', paddingLeft:'2%', paddingRight:'2%',alignItems:'center'}}>
                <img src={data.image} alt={data.imageAltText} style={{ borderRadius:'15px', maxWidth:'100%', maxHeight:'100%'}}></img>
            </div>
            
        </div> 
        <div style={{margin:'auto'}}>
            {dispLink ? <Link to={`/life_path_${number}`}> <h3>Learn More</h3></Link>:null}
        </div>
        </div>



    </>
    )

}