import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Capricorn(){

    useEffect(() => {
        document.title = 'Zodiac | Capricorn | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/capricorn",
        title:"capricorn",})

        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Capricorn: The Goat (December 22 - January 19)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/capricorn1.png' alt='Capricorn the Goat' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/sagittarius' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Sagittarius</Link>   
                    Next:      <Link to='/aquarius' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Aquarius</Link>
                </div>
                <p>Capricorn is the tenth sign of the zodiac, symbolizing discipline, responsibility, and ambition. Represented by the Goat, Capricorn is ruled by Saturn, the planet of structure, hard work, and discipline. People born under this sign are known for their determination, practicality, and strong sense of duty.</p>

                <h2>Personality Traits</h2>
                <p>Capricorn individuals are characterized by their ambitious and disciplined nature. They are highly goal-oriented and possess a strong work ethic, often setting high standards for themselves and others. Capricorns are practical and grounded, preferring to take a methodical and strategic approach to achieving their goals.</p>
                <p>Despite their serious and reserved exterior, Capricorns have a deep sense of loyalty and responsibility towards their loved ones. They value tradition and often have a strong sense of family and community. While they can be cautious and conservative, they are also reliable and dependable, making them trustworthy friends and partners.</p>

                <h2>Career</h2>
                <p>In the professional realm, Capricorn excels in roles that require leadership, organization, and long-term planning. They are well-suited for careers in business, finance, management, and any field that demands perseverance and dedication. Capricorns thrive in structured environments where they can set and achieve tangible goals.</p>
                <p>Capricorn employees are known for their reliability and diligence. They are often the backbone of their organizations, ensuring that tasks are completed efficiently and effectively. However, they may need to work on balancing their work and personal life, as their dedication to their careers can sometimes lead to burnout.</p>

                <h2>Relationships</h2>
                <p>In relationships, Capricorn is loyal and committed. They seek partners who share their values and are willing to work together towards common goals. Capricorn values stability and reliability, and they appreciate partners who are supportive and dependable. They are drawn to individuals who can provide emotional security and a sense of partnership.</p>
                <p>As friends, Capricorns are supportive and trustworthy. They are always ready to offer practical advice and help in times of need. In romantic relationships, they are devoted and often take their commitments seriously. However, their reserved nature can sometimes make it difficult for them to express their emotions, so open communication and patience are key to maintaining healthy relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Capricorn is a sign that embodies discipline, responsibility, and ambition. Their determination, practicality, and loyalty make them reliable and successful individuals. By embracing their strengths and working on their challenges, Capricorn individuals can achieve great success and lead fulfilling lives both personally and professionally.</p>
            </div>

            <Footer />
            </>
    )}