import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ThreeOfWands() {

    useEffect(() => {
        document.title = 'Tarot | Three of Wands | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "three_of_wands",
        title: "three_of_wands",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Three of Wands</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/three_of_wands'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Wands03.jpg' alt='Three of Wands Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/two_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Two of Wands</Link>   
                    <Link to='/four_of_wands' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Four of Wands</Link>
                </div>
                <h2>Overview of the Three of Wands</h2>

                <p>The Three of Wands is a card in the Minor Arcana of the Tarot deck that represents foresight, expansion, and the anticipation of future opportunities. It signifies a period of waiting for results, the implementation of plans, and the exploration of new horizons. This card encourages the querent to look ahead with optimism, prepare for upcoming ventures, and embrace the potential for growth and expansion.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Three of Wands typically features a figure standing on a cliff, gazing out at ships sailing on the horizon. The figure holds a wand firmly, symbolizing support and stability, while the other two wands are planted in the ground, representing the groundwork that has been laid. The overall imagery emphasizes themes of foresight, expansion, and the anticipation of future opportunities.</p>

                <p>The Three of Wands' symbolism emphasizes themes of foresight, expansion, and the anticipation of future opportunities. This card encourages the querent to look ahead with optimism, prepare for upcoming ventures, and embrace the potential for growth and expansion.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Three of Wands appears in a reading, it signifies foresight, expansion, and the anticipation of future opportunities. It suggests that the querent is in or will soon enter a period of waiting for results, the implementation of plans, and the exploration of new horizons. The Three of Wands encourages the querent to look ahead with optimism, prepare for upcoming ventures, and embrace the potential for growth and expansion.</p>

                <p>In the context of personal growth, the Three of Wands can indicate a period of self-discovery and the pursuit of long-term goals. It may suggest that the querent is exploring new opportunities, anticipating future possibilities, and preparing for personal development. In relationships, the Three of Wands can signify the need for mutual planning and the exploration of new dynamics, encouraging the querent to look ahead with optimism and embrace the potential for growth in their partnerships.</p>

                <p>In career and financial matters, the Three of Wands can signal opportunities for growth and success through foresight and strategic planning. It advises the querent to look ahead with optimism, prepare for upcoming ventures, and embrace the potential for expansion and new opportunities. The Three of Wands reminds the querent that with foresight, expansion, and the anticipation of future opportunities, they can achieve their ambitions and navigate their path with clarity and purpose.</p>

                <h2>Conclusion</h2>

                <p>The Three of Wands in the Tarot deck is a powerful symbol of foresight, expansion, and the anticipation of future opportunities. By understanding and embracing the energy it represents, individuals can look ahead with optimism, prepare for upcoming ventures, and embrace the potential for growth and expansion. The Three of Wands encourages the querent to look ahead with optimism, prepare for upcoming ventures, and embrace the potential for growth and expansion. It is a reminder that with foresight, expansion, and the anticipation of future opportunities, we can achieve our ambitions and navigate our path with clarity and purpose.</p>

                <Footer />
            </div>
        </>
    )
}
