
export function convertLetterToNumberChaldean(letter) {
    var output = null;
    const convertedLetter = letter.toUpperCase();

    if (convertedLetter === 'A') {
        output = 1;
    }
    if (convertedLetter === 'B') {
        output = 2;
    }
    if (convertedLetter === 'C') {
        output = 3;
    }
    if (convertedLetter === 'D') {
        output = 4;
    }
    if (convertedLetter === 'E') {
        output = 5;
    }
    if (convertedLetter === 'F') {
        output = 8;
    }
    if (convertedLetter === 'G') {
        output = 3;
    }
    if (convertedLetter === 'H') {
        output = 5;
    }
    if (convertedLetter === 'I') {
        output = 1;
    }
    if (convertedLetter === 'J') {
        output = 1;
    }
    if (convertedLetter === 'K') {
        output = 2;
    }
    if (convertedLetter === 'L') {
        output = 3;
    }
    if (convertedLetter === 'M') {
        output = 4;
    }
    if (convertedLetter === 'N') {
        output = 5;
    }
    if (convertedLetter === 'O') {
        output = 7;
    }
    if (convertedLetter === 'P') {
        output = 8;
    }
    if (convertedLetter === 'Q') {
        output = 1;
    }
    if (convertedLetter === 'R') {
        output = 2;
    }
    if (convertedLetter === 'S') {
        output = 3;
    }
    if (convertedLetter === 'T') {
        output = 4;
    }
    if (convertedLetter === 'U') {
        output = 6;
    }
    if (convertedLetter === 'V') {
        output = 6;
    }
    if (convertedLetter === 'W') {
        output = 6;
    }
    if (convertedLetter === 'X') {
        output = 5;
    }
    if (convertedLetter === 'Y') {
        output = 1;
    }
    if (convertedLetter === 'Z') {
        output = 7;
    }

    return output;
}

// src/utils/chaldeanUtils.js

export function convertLetterToNumberPythagorean(letter) {
    var output = null;
    const convertedLetter = letter.toUpperCase();

    if (convertedLetter === 'A') {
        output = 1;
    }
    if (convertedLetter === 'B') {
        output = 2;
    }
    if (convertedLetter === 'C') {
        output = 3;
    }
    if (convertedLetter === 'D') {
        output = 4;
    }
    if (convertedLetter === 'E') {
        output = 5;
    }
    if (convertedLetter === 'F') {
        output = 6;
    }
    if (convertedLetter === 'G') {
        output = 7;
    }
    if (convertedLetter === 'H') {
        output = 8;
    }
    if (convertedLetter === 'I') {
        output = 9;
    }
    if (convertedLetter === 'J') {
        output = 1;
    }
    if (convertedLetter === 'K') {
        output = 2;
    }
    if (convertedLetter === 'L') {
        output = 3;
    }
    if (convertedLetter === 'M') {
        output = 4;
    }
    if (convertedLetter === 'N') {
        output = 5;
    }
    if (convertedLetter === 'O') {
        output = 6;
    }
    if (convertedLetter === 'P') {
        output = 7;
    }
    if (convertedLetter === 'Q') {
        output = 8;
    }
    if (convertedLetter === 'R') {
        output = 9;
    }
    if (convertedLetter === 'S') {
        output = 1;
    }
    if (convertedLetter === 'T') {
        output = 2;
    }
    if (convertedLetter === 'U') {
        output = 3;
    }
    if (convertedLetter === 'V') {
        output = 4;
    }
    if (convertedLetter === 'W') {
        output = 5;
    }
    if (convertedLetter === 'X') {
        output = 6;
    }
    if (convertedLetter === 'Y') {
        output = 7;
    }
    if (convertedLetter === 'Z') {
        output = 8;
    }

    return output;
}

export function calculateLifePathNumber(month, day, year) {


    var monthAsInt = 0;
    var lifePathNumber = 0;

    if(month === "January"){
        monthAsInt = 1;
    }
    if(month === "February"){
        monthAsInt = 2;
    }
    if(month === "March"){
        monthAsInt = 3;
    }
    if(month === "April"){
        monthAsInt = 4;
    }
    if(month === "May"){
        monthAsInt = 5;
    }
    if(month === "June"){
        monthAsInt = 6;
    }
    if(month === "July"){
        monthAsInt = 7;
    }
    if(month === "August"){
        monthAsInt = 8;
    }
    if(month === "September"){
        monthAsInt = 9;
    }
    if(month === "October"){
        monthAsInt = 10;
    }
    if(month === "November"){
        monthAsInt = 11;
    }
    if(month === "December"){
        monthAsInt = 12;
    }


const combinedNumber = monthAsInt.toString() + day.toString() + year.toString();


return(combinedNumber)

}


export function addDigitsInNumber(number)
      {
        const numberString = number.toString();
        var sum = 0;

        for(var i = 0; i < numberString.length;i++)
                {
                        sum += parseInt(numberString[i])
                }

        return sum
        
      }

      export function calculateZodiac(month, day)
      {
        var zodiac = ''
        if(month==='January')            {
            if(day <= 19){
                        
                zodiac = 'Capricorn'
            }
            else{

                zodiac = 'Aquarius'
            }
        }
        if(month==='February')            {
            if(day <= 18){
                        
                zodiac = 'Aquarius'
            }
            else{

                zodiac = 'Pisces'
            }
        }
        if(month==='March')            {
            if(day <= 20){
                        
                zodiac = 'Pisces'
            }
            else{

                zodiac = 'Aries'
            }
        }
        if(month==='April')            {
            if(day <= 19){
                        
                zodiac = 'Aries'
            }
            else{

                zodiac = 'Taurus'
            }
        }
        if(month==='May')            {
            if(day <= 20){
                        
                zodiac = 'Taurus'
            }
            else{

                zodiac = 'Gemini'
            }
        }
        if(month==='June')            {
            if(day <= 20){
                        
                zodiac = 'Gemini'
            }
            else{

                zodiac = 'Cancer'
            }
        }
        if(month==='July')            {
            if(day <= 22){
                        
                zodiac = 'Cancer'
            }
            else{

                zodiac = 'Leo'
            }
        }
        if(month==='August')            {
            if(day <= 22){
                        
                zodiac = 'Leo'
            }
            else{

                zodiac = 'Virgo'
            }
        }
        if(month==='September')            {
            if(day <= 22){
                        
                zodiac = 'Virgo'
            }
            else{

                zodiac = 'Libra'
            }
        }
        if(month==='October')            {
            if(day <= 22){
                        
                zodiac = 'Libra'
            }
            else{

                zodiac = 'Scorpio'
            }
        }
        if(month==='November')            {
            if(day <= 21){
                        
                zodiac = 'Scorpio'
            }
            else{

                zodiac = 'Sagittarius'
            }
        }
        if(month==='December')            {
            if(day <= 21){
                        
                zodiac = 'Sagittarius'
            }
            else{

                zodiac = 'Capricorn'
            }
        }


        return zodiac
      }

