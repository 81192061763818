import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TemperanceTarot() {

    useEffect(() => {
        document.title = 'Tarot | Temperance Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "temperance_tarot",
        title: "temperance_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Temperance</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/temperance_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/14_Temperance.jpg' alt='Temperance Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of Temperance</h2>

                <p>Temperance is the fourteenth card in the Major Arcana of the Tarot deck, numbered as 14. It represents balance, harmony, and moderation. The Temperance card is a symbol of patience, self-control, and the blending of opposites. This card encourages the querent to seek balance in their life, practice moderation, and find harmony through the integration of different elements.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Temperance card typically features an angel with one foot on land and the other in water, symbolizing the balance between the material and emotional realms. The angel pours water from one cup to another, representing the blending of opposites and the flow of life. Behind the angel is a path leading to a bright sun, symbolizing enlightenment and the journey towards balance. The overall imagery emphasizes the importance of harmony, balance, and the integration of different aspects of life.</p>

                <p>Temperance's number, 14, signifies balance, moderation, and the union of opposites. This card's symbolism encourages the querent to seek harmony, practice moderation, and find balance in all areas of their life.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Temperance card appears in a reading, it signifies the importance of balance, harmony, and moderation. It suggests that the querent is in a situation where they need to find balance and integrate different aspects of their life. The Temperance card encourages the querent to practice patience, seek harmony, and blend opposing elements to create a balanced and harmonious life.</p>

                <p>In the context of personal growth, the Temperance card can indicate a period of self-reflection and the pursuit of balance. It may suggest that the querent is learning to integrate different aspects of themselves and is seeking harmony in their life. In relationships, the Temperance card can signify the importance of compromise, understanding, and the blending of different personalities to create a harmonious relationship.</p>

                <p>In career and financial matters, the Temperance card can signal opportunities for growth and success through balance and moderation. It advises the querent to seek harmony in their work, practice moderation in their financial decisions, and find balance between their personal and professional lives. The Temperance card reminds the querent that balance, harmony, and the integration of different elements are essential for long-term success and fulfillment.</p>

                <h2>Conclusion</h2>

                <p>The Temperance card in the Tarot deck is a powerful symbol of balance, harmony, and moderation. By understanding and embracing the energy it represents, individuals can seek harmony, practice moderation, and find balance in all areas of their life. The Temperance card encourages the querent to integrate different aspects of their life, practice patience, and seek harmony through the blending of opposites. It is a reminder that balance, harmony, and the integration of different elements are essential for long-term success and fulfillment.</p>

                <Footer />
            </div>
        </>
    )
}
