import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function DevilTarot() {

    useEffect(() => {
        document.title = 'Tarot | The Devil Card | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "devil_tarot",
        title: "devil_tarot",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>The Devil</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/devil_tarot'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/15_Devil.jpg' alt='Devil Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <h2>Overview of The Devil</h2>

                <p>The Devil is the fifteenth card in the Major Arcana of the Tarot deck, numbered as 15. It represents bondage, materialism, and the shadow self. The Devil is a symbol of temptation, addiction, and the illusions that bind us. This card encourages the querent to confront their shadows, break free from unhealthy attachments, and recognize the power they have to liberate themselves from limiting beliefs and behaviors.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Devil card typically features a horned and winged figure resembling traditional depictions of the devil. He sits on a pedestal, with a man and a woman chained to it. Despite the chains, the figures do not struggle, symbolizing voluntary bondage. The inverted pentagram above the Devil's head represents the material world and the illusion of power. The overall imagery emphasizes the themes of bondage, temptation, and the shadow self.</p>

                <p>The Devil's number, 15, signifies the material world, temptation, and the challenges of breaking free from bondage. This card's symbolism encourages the querent to confront their shadows, recognize their own power, and break free from unhealthy attachments and limiting beliefs.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Devil card appears in a reading, it signifies the importance of confronting one's shadows and breaking free from bondage. It suggests that the querent may be feeling trapped by materialism, addiction, or unhealthy relationships. The Devil card encourages the querent to recognize the illusions that bind them, confront their shadows, and take steps to liberate themselves from limiting beliefs and behaviors.</p>

                <p>In the context of personal growth, the Devil card can indicate a period of self-reflection and the need to confront one's shadows. It may suggest that the querent is becoming aware of their own limitations and is taking steps to break free from unhealthy patterns. In relationships, the Devil card can signify the importance of honesty, confronting issues, and breaking free from unhealthy dynamics.</p>

                <p>In career and financial matters, the Devil card can signal opportunities for growth through confronting materialism and breaking free from limiting beliefs. It advises the querent to recognize the illusions that bind them, confront their shadows, and take steps to liberate themselves from unhealthy attachments and limiting beliefs. The Devil card reminds the querent that true freedom comes from recognizing their own power and breaking free from the illusions that bind them.</p>

                <h2>Conclusion</h2>

                <p>The Devil card in the Tarot deck is a powerful symbol of bondage, materialism, and the shadow self. By understanding and embracing the energy it represents, individuals can confront their shadows, break free from unhealthy attachments, and recognize the power they have to liberate themselves from limiting beliefs and behaviors. The Devil card encourages the querent to confront their shadows, recognize their own power, and take steps to liberate themselves from the illusions that bind them. It is a reminder that true freedom comes from recognizing one's own power and breaking free from the illusions that bind us.</p>

                <Footer />
            </div>
        </>
    )
}
