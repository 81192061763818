import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function MavenBlogger(){

    useEffect(() => {
        document.title = 'Mystic Maven | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/blog/maven",
        title:"/blog/maven",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <div ref={topContainer} />


    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
    <h1>Mystic Maven</h1>

        <p>
            Welcome, seekers of the unknown. I am Mystic Maven, your guide through the enigmatic and mystical realms of astrology and beyond. My journey is one of exploration and discovery, delving into the esoteric and the occult with a reverence for the ancient wisdom that guides us.
        </p>
        <p>
            Together, we will uncover the mystical rituals, interpret the tarot's secrets, and embrace the occult practices that have been passed down through generations. Let's embark on this journey into the unknown, guided by the stars and the wisdom of the ancients.
        </p>
       
    </div>
    <Footer />
    </>
  )}

