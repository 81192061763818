import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function StarBlogger(){

    useEffect(() => {
        document.title = 'Star Sage | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/blog/star",
        title:"/blog/star",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>

    <Navbar />
    <body>
    <div ref={topContainer} />


    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
    <div class="blogger-profile">
    <h1>Star Sage</h1>

        <p>
            Greetings, fellow seekers of cosmic wisdom! I am Star Sage, your guide to understanding the vast and intricate world of astrology. With a keen eye for detail and a passion for uncovering the mysteries of the stars, I strive to bring clarity and insight to our celestial studies.
        </p>
        <p>
            Join me as we explore the intricacies of astrological charts, the movements of the planets, and the rich history that shapes our understanding of the cosmos. Together, we will unlock the secrets of the stars and apply this ancient wisdom to our modern lives.
        </p>
    </div>


        
    </div>
    </body>
    <Footer />
    </>
  )}

