import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacMonkey() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Monkey | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_monkey",
        title: "chinese_zodiac_monkey",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Monkey</h1>
                    <Link to='/chinese_zodiac_monkey' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacMonkey.png' alt='A monkey in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_goat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Goat</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rooster' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rooster</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Monkey</h2>
                    <p>The Monkey is the ninth animal in the Chinese Zodiac cycle. Those born under this sign are known for their intelligence, curiosity, and playfulness. Monkeys are quick-witted and resourceful, often able to solve problems with ease. They have a natural charm and are great at making others laugh. However, their mischievous nature can sometimes lead them into trouble and may make them appear unreliable.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Monkey</h2>
                    <p>The Monkey is the ninth sign in the Chinese Zodiac, and individuals born under this sign are known for their intelligence, curiosity, and playfulness. Monkeys are quick-witted and resourceful, often able to solve problems with ease. They have a natural charm and are great at making others laugh.</p>

                    <p>Monkeys are also known for their adaptability and versatility. They thrive in dynamic environments and are always eager to explore new ideas and experiences. Their innovative mindset allows them to come up with creative solutions and think outside the box. However, their mischievous nature can sometimes lead them into trouble and may make them appear unreliable.</p>

                    <p>Despite their playful exterior, Monkeys have a compassionate and caring side. They value their relationships deeply and are always willing to offer support and encouragement. Their loyal and protective nature makes them reliable friends and partners. However, their restless nature can sometimes make them prone to boredom and impatience.</p>

                    <p>Monkeys are also known for their love of adventure and excitement. They enjoy engaging in activities that challenge them both mentally and physically. Their dynamic personality often draws others to them, and they enjoy being the life of the party. However, it is important for Monkeys to balance their adventurous spirit with mindfulness and self-care to avoid burnout and maintain their well-being.</p>
               </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Monkey</h2>
                    <p>In their careers, Monkeys excel in roles that require intelligence, creativity, and adaptability. They are often successful in fields such as technology, entertainment, and communication, where their quick wit and resourcefulness can shine. Monkeys are excellent problem-solvers who can navigate complex situations with ease. Their dynamic and outgoing personality makes them valuable assets in any professional environment.</p>

                    <p>Monkeys are known for their innovative thinking and ability to adapt to changing circumstances. They thrive in environments that encourage creativity and allow them to take risks. Their intelligence and resourcefulness often lead to groundbreaking achievements and significant contributions to their fields. However, their mischievous nature can sometimes make them appear unreliable and prone to trouble. It's important for Monkeys to balance their playfulness with responsibility and discipline.</p>

                    <p>When it comes to compatibility, Monkeys are most compatible with the Rat, Dragon, and Snake. These signs complement the Monkey's traits and help to balance their playful nature. The Rat offers resourcefulness and quick thinking, the Dragon provides ambition and vision, and the Snake shares the Monkey's intelligence and insight. Monkeys may experience challenges in relationships with the Tiger and Pig, as these signs may clash with the Monkey's personality and create conflict. To maintain harmony, Monkeys need to practice patience and understanding in their relationships.</p>
                 </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Monkey</h2>
                <p>Monkeys are known for their energetic and lively nature, which generally helps them maintain good physical health. They enjoy engaging in physical activities and sports, which keep them fit and active. However, their playful and mischievous nature can sometimes lead to accidents or injuries. It's important for Monkeys to practice caution and take care of their bodies to avoid unnecessary harm.</p>

                <p>Monkeys can also be prone to stress and anxiety due to their ambitious and competitive nature. They need to find effective ways to manage stress, such as through relaxation techniques, meditation, or engaging in creative activities that allow them to express themselves. Monkeys benefit from a balanced lifestyle that includes time for both work and leisure.</p>

                <p>In terms of lifestyle, Monkeys enjoy excitement and novelty. They are often drawn to activities that challenge them both physically and mentally, such as travel, adventure sports, or exploring new hobbies. Monkeys also value their independence and enjoy having the freedom to pursue their interests. However, it's important for them to maintain a healthy balance and avoid overextending themselves.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_goat' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Goat</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_rooster' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Rooster</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
