import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ThreeOfCups() {

    useEffect(() => {
        document.title = 'Tarot | Three of Cups | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "three_of_cups",
        title: "three_of_cups",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Three of Cups</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/three_of_cups'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Cups03.jpg' alt='Three of Cups Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/two_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Two of Cups</Link>   
                    <Link to='/four_of_cups' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Four of Cups</Link>
                </div>

                <h2>Overview of the Three of Cups</h2>

                <p>The Three of Cups is a card in the Minor Arcana of the Tarot deck that represents celebration, friendship, and community. It signifies joyous gatherings, shared happiness, and the strength of supportive connections. This card encourages the querent to embrace the joy of togetherness, celebrate their successes, and nurture the bonds of friendship and community.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Three of Cups typically features three women dancing in a circle, holding cups high in celebration. They stand on a lush, fertile ground, symbolizing abundance and growth. The overall imagery emphasizes themes of joy, celebration, and the strength found in supportive friendships and communities.</p>

                <p>The Three of Cups' symbolism emphasizes themes of celebration, friendship, and community. This card encourages the querent to embrace the joy of togetherness, celebrate their successes, and nurture the bonds of friendship and community.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Three of Cups appears in a reading, it signifies celebration, friendship, and community. It suggests that the querent is in or will soon enter a period of joy and shared happiness, marked by gatherings and the strengthening of supportive connections. The Three of Cups encourages the querent to embrace these moments of togetherness, celebrate their successes, and nurture the bonds of friendship and community.</p>

                <p>In the context of personal growth, the Three of Cups can indicate a period of emotional fulfillment and the importance of social connections. It may suggest that the querent is experiencing the joy of supportive friendships and is learning the value of community. In relationships, the Three of Cups can signify joyous celebrations, the strengthening of friendships, or the creation of a supportive social network.</p>

                <p>In career and financial matters, the Three of Cups can signal opportunities for collaboration and collective success. It advises the querent to embrace teamwork, celebrate shared achievements, and foster a supportive work environment. The Three of Cups reminds the querent that with celebration, friendship, and community, they can achieve their goals and experience fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Three of Cups in the Tarot deck is a powerful symbol of celebration, friendship, and community. By understanding and embracing the energy it represents, individuals can embrace the joy of togetherness, celebrate their successes, and nurture the bonds of friendship and community. The Three of Cups encourages the querent to embrace these moments of togetherness, celebrate their successes, and nurture the bonds of friendship and community. It is a reminder that with celebration, friendship, and community, we can achieve our goals and experience fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
