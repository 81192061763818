import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';


export default function MinorArcana(){

    useEffect(() => {
        document.title = 'Tarot | The Minor Arcana | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"minor_arcana",
        title:"minor_arcana",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

  return (
    <>
    <div ref={topContainer} />
    <Navbar />

    <div style={{width:'90%', maxWidth:'800px', margin:'auto'}}>
    <header className="header" style={{backgroundColor:'rgb(46,45,41)'}} >
      <h1>The Minor Arcana</h1>
      </header>
      <h2>The Minor Arcana in Tarot</h2>

        <p>The Minor Arcana is a set of 56 cards in the Tarot deck, divided into four suits, each representing different aspects of life and human experience. These cards focus on everyday events, challenges, and interactions, providing detailed insights into the querent's daily life and personal situations. While the <Link to='/major_arcana'>Major Arcana</Link> deals with significant life events and spiritual lessons, the Minor Arcana offers a more granular view of the querent's journey, highlighting the practical and immediate influences that shape their experiences.</p>

        <h2>Overview of the Minor Arcana Cards</h2>

        <p>The Minor Arcana is divided into four suits: <Link to='/wands'>Wands</Link>, <Link to='/cups'>Cups</Link>, <Link to='/swords'>Swords</Link>, and <Link to='/pentacles'>Pentacles</Link>. Each suit contains 14 cards, including ten numbered cards (Ace to Ten) and four Court Cards (Page, Knight, Queen, and King). Each suit represents a different element and area of life:</p>
        <ul>
            <br />

        <li style={{marginTop:'30px'}}><strong><Link to='/wands'>Wands:</Link></strong> Associated with the element of fire, Wands represent creativity, passion, action, and ambition. They often deal with issues related to career, projects, and personal growth.</li>
        <div style={{margin:'auto', textAlign:'center', marginTop:'30px'}}>
            <Link to='/ace_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands01.jpg' alt='Ace of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/two_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands02.jpg' alt='Two of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/three_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands03.jpg' alt='Three of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/four_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands04.jpg' alt='Four of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/five_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands05.jpg' alt='Five of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/six_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands06.jpg' alt='Six of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/seven_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands07.jpg' alt='Seven of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <div style={{margin:'auto', textAlign:'center', marginBottom:'75px'}}>
            <Link to='/eight_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands08.jpg' alt='Eight of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/nine_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands09.jpg' alt='Nine of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/ten_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands10.jpg' alt='Ten of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/page_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands11.jpg' alt='Page of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/knight_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands12.jpg' alt='Knight of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/queen_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands13.jpg' alt='Queen of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/king_of_wands'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Wands14.jpg' alt='King of Wands Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <li><strong><Link to='/cups'>Cups:</Link></strong> Linked to the element of water, Cups symbolize emotions, relationships, intuition, and the subconscious. They address matters of the heart, love, and emotional well-being.</li>
        <div style={{margin:'auto', textAlign:'center', marginTop:'30px'}}>
            <Link to='/ace_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups01.jpg' alt='Ace of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/two_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups02.jpg' alt='Two of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/three_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups03.jpg' alt='Three of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/four_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups04.jpg' alt='Four of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/five_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups05.jpg' alt='Five of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/six_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups06.jpg' alt='Six of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/seven_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups07.jpg' alt='Seven of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <div style={{margin:'auto', textAlign:'center', marginBottom:'75px'}}>
            <Link to='/eight_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups08.jpg' alt='Eight of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/nine_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups09.jpg' alt='Nine of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/ten_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups10.jpg' alt='Ten of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/page_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups11.jpg' alt='Page of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/knight_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups12.jpg' alt='Knight of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/queen_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups13.jpg' alt='Queen of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/king_of_cups'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Cups14.jpg' alt='King of Cups Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <li><strong><Link to='/swords'>Swords:</Link></strong> Corresponding to the element of air, Swords represent intellect, communication, conflict, and decision-making. They focus on challenges, mental clarity, and the power of thought.</li>
        <div style={{margin:'auto', textAlign:'center', marginTop:'30px'}}>
            <Link to='/ace_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords01.jpg' alt='Ace of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/two_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords02.jpg' alt='Two of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/three_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords03.jpg' alt='Three of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/four_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords04.jpg' alt='Four of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/five_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords05.jpg' alt='Five of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/six_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords06.jpg' alt='Six of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/seven_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords07.jpg' alt='Seven of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <div style={{margin:'auto', textAlign:'center', marginBottom:'75px'}}>
            <Link to='/eight_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords08.jpg' alt='Eight of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/nine_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords09.jpg' alt='Nine of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/ten_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords10.jpg' alt='Ten of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/page_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords11.jpg' alt='Page of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/knight_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords12.jpg' alt='Knight of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/queen_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords13.jpg' alt='Queen of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/king_of_swords'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Swords14.jpg' alt='King of Swords Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <li><strong><Link to='/pentacles'>Pentacles:</Link></strong> Connected to the element of earth, Pentacles signify material aspects, such as finances, work, health, and physical security. They deal with practical matters, prosperity, and the physical world.</li>
        <div style={{margin:'auto', textAlign:'center', marginTop:'30px'}}>
            <Link to='/ace_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents01.jpg' alt='Ace of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/two_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents02.jpg' alt='Two of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/three_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents03.jpg' alt='Three of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/four_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents04.jpg' alt='Four of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/five_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents05.jpg' alt='Five of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/six_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents06.jpg' alt='Six of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/seven_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents07.jpg' alt='Seven of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        <div style={{margin:'auto', textAlign:'center', marginBottom:'75px'}}>
            <Link to='/eight_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents08.jpg' alt='Eight of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/nine_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents09.jpg' alt='Nine of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/ten_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents10.jpg' alt='Ten of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/page_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents11.jpg' alt='Page of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/knight_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents12.jpg' alt='Knight of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/queen_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents13.jpg' alt='Queen of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
            <Link to='/king_of_pentacles'>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/Pents14.jpg' alt='King of Pentacles Tarot Card ' style={{width:'80px', height:'128px', borderRadius:'10px', margin:'3px'}}/>
            </Link>
        </div>
        </ul>

        <h2>Symbolism and Interpretation</h2>

        <p>Each suit in the Minor Arcana tells a story that progresses from the Ace to the Ten, depicting a journey or cycle related to the suit's theme. The Aces represent new beginnings and potential, while the numbered cards describe the development and progression of the suit's energy. The Tens signify completion and the realization of the suit's journey. The Court Cards represent people or personalities that influence the querent's life, offering insights into relationships and personal dynamics.</p>

        <p>For example, the <Link to='/ace_of_wands'>Ace of Wands</Link> signifies a burst of creative energy and the start of a new project, while the <Link to='/ten_of_wands'>Ten of Wands</Link> depicts the culmination of hard work and the burden of responsibilities. The <Link to='/page_of_cups'>Page of Cups</Link> might represent a youthful, emotionally open individual, while the <Link to='/king_of_swords'>King of Swords</Link> symbolizes a mature, authoritative figure who values logic and clarity. Each card's symbolism and position within a spread provide context and meaning, helping the tarot reader interpret the querent's current situation and potential outcomes.</p>

        <h2>The Four Suits and Their Meanings</h2>

        <p><strong><Link to='/wands'>Wands:</Link></strong> The suit of Wands is associated with the element of fire and represents action, ambition, and creativity. It is often linked to career and personal projects, symbolizing the drive and energy needed to achieve goals. Wands cards can indicate periods of growth, enthusiasm, and inspiration, as well as challenges related to impulsiveness or burnout.</p>

        <p><strong><Link to='/cups'>Cups:</Link></strong> The suit of Cups is connected to the element of water and deals with emotions, relationships, and intuition. Cups cards reflect matters of the heart, such as love, friendship, and emotional fulfillment. They can reveal deep feelings, inner conflicts, and the importance of emotional balance. Cups also emphasize the role of intuition and the subconscious in guiding decisions and actions.</p>

        <p><strong><Link to='/swords'>Swords:</Link></strong> The suit of Swords corresponds to the element of air and represents intellect, communication, and conflict. Swords cards often address challenges, decisions, and the power of thought. They can highlight mental clarity, truth, and the need for decisive action, as well as issues related to stress, conflict, and miscommunication. Swords encourage the querent to think critically and navigate difficulties with wisdom and integrity.</p>

        <p><strong><Link to='/pentacles'>Pentacles:</Link></strong> The suit of Pentacles is linked to the element of earth and signifies material aspects, such as finances, work, and health. Pentacles cards focus on practical matters, prosperity, and physical security. They can indicate success, stability, and the rewards of hard work, as well as challenges related to financial stress or physical health. Pentacles emphasize the importance of grounding, perseverance, and the practical application of skills and resources.</p>

        <h2>Conclusion</h2>

        <p>The Minor Arcana in Tarot provides a detailed and nuanced view of the querent's everyday life and personal experiences. By exploring the symbolism and meanings of these 56 cards, individuals can gain insights into their daily challenges, relationships, and opportunities. Whether used for personal reflection, guidance, or divination, the Minor Arcana offers valuable tools for understanding the intricate patterns and influences that shape our lives. Together with the Major Arcana, these cards create a comprehensive and rich tapestry of the human experience, offering wisdom and guidance for those who seek it.</p>

        <div style={{marginTop:'75px', textAlign:'center'}}>
                See Also: <strong><Link to='/tarot' style={{margin:'20px'}}>Tarot</Link><Link to='/major_arcana'>Major Arcana</Link></strong>
        </div>
        <Footer />
    </div>
    </>
  )}

