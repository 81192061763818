import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function PageOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Page of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "page_of_swords",
        title: "page_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Page of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/page_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords11.jpg' alt='Page of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ten_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ten of Swords</Link>   
                    <Link to='/knight_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Knight of Swords</Link>
                </div>
                <h2>Overview of the Page of Swords</h2>

                <p>The Page of Swords is a card in the Minor Arcana of the Tarot deck that represents curiosity, communication, and intellectual exploration. It signifies a period of new ideas, enthusiasm for learning, and the willingness to explore new perspectives. This card encourages the querent to embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Page of Swords typically features a young figure holding a sword upright, standing in a dynamic pose with the wind blowing. The scene depicts a sense of curiosity, alertness, and readiness for action. The overall imagery emphasizes themes of new ideas, communication, and the pursuit of intellectual exploration.</p>

                <p>The Page of Swords' symbolism emphasizes themes of curiosity, communication, and intellectual exploration. This card encourages the querent to embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Page of Swords appears in a reading, it signifies curiosity, communication, and intellectual exploration. It suggests that the querent is in or will soon enter a period of new ideas, enthusiasm for learning, and the willingness to explore new perspectives. The Page of Swords encourages the querent to embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination.</p>

                <p>In the context of personal growth, the Page of Swords can indicate a period of self-discovery and the development of intellectual curiosity and communication skills. It may suggest that the querent is learning to appreciate the value of new ideas, engage in open communication, and pursue intellectual growth. In relationships, the Page of Swords can signify the need for honest communication, mutual understanding, and the importance of exploring new perspectives within the partnership.</p>

                <p>In career and financial matters, the Page of Swords can signal opportunities for growth and success through curiosity, communication, and intellectual exploration. It advises the querent to embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination. The Page of Swords reminds the querent that with curiosity, communication, and intellectual exploration, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Page of Swords in the Tarot deck is a powerful symbol of curiosity, communication, and intellectual exploration. By understanding and embracing the energy it represents, individuals can embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination. The Page of Swords encourages the querent to embrace their curiosity, engage in open communication, and pursue intellectual growth with eagerness and determination. It is a reminder that with curiosity, communication, and intellectual exploration, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
