import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ThreeOfPentacles() {

    useEffect(() => {
        document.title = 'Tarot | Three of Pentacles | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "three_of_pentacles",
        title: "three_of_pentacles",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Three of Pentacles</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/three_of_pentacles'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Pents03.jpg' alt='Three of Pentacles Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/two_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Two of Pentacles</Link>   
                    <Link to='/four_of_pentacles' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Four of Pentacles</Link>
                </div>
                <h2>Overview of the Three of Pentacles</h2>

                <p>The Three of Pentacles is a card in the Minor Arcana of the Tarot deck that represents collaboration, teamwork, and the manifestation of skills. It signifies a period of working together with others to achieve common goals, the recognition of one's talents, and the satisfaction of seeing one's efforts come to fruition. This card encourages the querent to value collaboration, leverage their skills, and work harmoniously with others to achieve success.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Three of Pentacles typically features three figures working together on a cathedral, with one person holding architectural plans. The scene depicts a sense of collaboration, craftsmanship, and the successful manifestation of skills. The overall imagery emphasizes themes of teamwork, collaboration, and the recognition of one's talents and efforts.</p>

                <p>The Three of Pentacles' symbolism emphasizes themes of collaboration, teamwork, and the manifestation of skills. This card encourages the querent to value collaboration, leverage their skills, and work harmoniously with others to achieve success.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Three of Pentacles appears in a reading, it signifies collaboration, teamwork, and the manifestation of skills. It suggests that the querent is in or will soon enter a period of working together with others to achieve common goals, the recognition of one's talents, and the satisfaction of seeing one's efforts come to fruition. The Three of Pentacles encourages the querent to value collaboration, leverage their skills, and work harmoniously with others to achieve success.</p>

                <p>In the context of personal growth, the Three of Pentacles can indicate a period of self-discovery and the development of one's talents through collaboration and teamwork. It may suggest that the querent is learning to appreciate the value of working with others and recognizing the importance of their contributions. In relationships, the Three of Pentacles can signify the need for mutual effort, cooperation, and the joint pursuit of shared goals.</p>

                <p>In career and financial matters, the Three of Pentacles can signal opportunities for growth and success through collaboration and the effective use of one's skills. It advises the querent to value teamwork, leverage their talents, and work harmoniously with others to achieve success. The Three of Pentacles reminds the querent that with collaboration, teamwork, and the manifestation of skills, they can achieve their goals and find satisfaction in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Three of Pentacles in the Tarot deck is a powerful symbol of collaboration, teamwork, and the manifestation of skills. By understanding and embracing the energy it represents, individuals can value collaboration, leverage their skills, and work harmoniously with others to achieve success. The Three of Pentacles encourages the querent to value collaboration, leverage their skills, and work harmoniously with others to achieve success. It is a reminder that with collaboration, teamwork, and the manifestation of skills, we can achieve our goals and find satisfaction in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
