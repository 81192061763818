import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function EightOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Eight of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "eight_of_swords",
        title: "eight_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Eight of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/eight_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords08.jpg' alt='Eight of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/seven_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Seven of Swords</Link>   
                    <Link to='/nine_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Nine of Swords</Link>
                </div>
                <h2>Overview of the Eight of Swords</h2>

                <p>The Eight of Swords is a card in the Minor Arcana of the Tarot deck that represents restriction, limitation, and feeling trapped. It signifies a period of mental or emotional bondage, where the querent feels confined and unable to move forward. This card encourages the querent to confront their fears, recognize their own power, and find a way to break free from their limitations.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Eight of Swords typically features a blindfolded and bound figure surrounded by eight swords, standing in shallow water. The scene depicts a sense of restriction, limitation, and feeling trapped. The overall imagery emphasizes themes of mental or emotional bondage, confinement, and the need to break free from limitations.</p>

                <p>The Eight of Swords' symbolism emphasizes themes of restriction, limitation, and feeling trapped. This card encourages the querent to confront their fears, recognize their own power, and find a way to break free from their limitations.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Eight of Swords appears in a reading, it signifies restriction, limitation, and feeling trapped. It suggests that the querent is in or will soon enter a period of mental or emotional bondage, where they feel confined and unable to move forward. The Eight of Swords encourages the querent to confront their fears, recognize their own power, and find a way to break free from their limitations.</p>

                <p>In the context of personal growth, the Eight of Swords can indicate a period of self-discovery and the development of inner strength and resilience. It may suggest that the querent is learning to confront their fears, recognize their own power, and find a way to break free from their limitations. In relationships, the Eight of Swords can signify the need for honest communication, mutual support, and the recognition of each other's strengths and abilities to overcome challenges.</p>

                <p>In career and financial matters, the Eight of Swords can signal challenges and setbacks that may cause feelings of restriction and limitation. It advises the querent to confront their fears, recognize their own power, and find a way to break free from their limitations. The Eight of Swords reminds the querent that with restriction, limitation, and feeling trapped, they can still find a way to break free and achieve their goals.</p>

                <h2>Conclusion</h2>

                <p>The Eight of Swords in the Tarot deck is a powerful symbol of restriction, limitation, and feeling trapped. By understanding and embracing the energy it represents, individuals can confront their fears, recognize their own power, and find a way to break free from their limitations. The Eight of Swords encourages the querent to confront their fears, recognize their own power, and find a way to break free from their limitations. It is a reminder that with restriction, limitation, and feeling trapped, we can find a way to break free and achieve our goals.</p>

                <Footer />
            </div>
        </>
    )
}
