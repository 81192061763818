import { React, useEffect, useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/footer";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ChineseZodiacGoat() {

    useEffect(() => {
        document.title = 'Chinese Zodiac | Goat | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "chinese_zodiac_goat",
        title: "chinese_zodiac_goat",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto', marginTop: '25px' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1><Link to='/chinese_zodiac' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Chinese Zodiac </Link>- Goat</h1>
                    <Link to='/chinese_zodiac_goat' >
                        <img src='https://d3zlw6prgce9n.cloudfront.net/ChineseZodiacGoat.png' alt='A goat in front of a cosmic background' style={{ width: '250px', height: '250px', borderRadius: '10px', margin: '3px', marginTop:'25px', margin:'auto' }} />
                    </Link>
                </header>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_horse' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Horse</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_monkey' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Monkey</Link>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Overview of the Goat</h2>
                    <p>The Goat is the eighth animal in the Chinese Zodiac cycle. Those born under this sign are known for their calmness, gentleness, and sympathy. Goats are compassionate and nurturing, often seeking to create a peaceful and harmonious environment. They are also artistic and have a keen sense of aesthetics. However, their sensitivity can sometimes make them prone to indecision and self-doubt.</p>
                </div>
                
                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                    <h2>Personality Traits of the Goat</h2>
                    <p>The Goat is the eighth sign in the Chinese Zodiac, and those born under this sign are known for their calmness, gentleness, and sympathy. Goats are compassionate and nurturing, often seeking to create a peaceful and harmonious environment. They are also artistic and have a keen sense of aesthetics.</p>

                    <p>Goats are also known for their empathy and understanding. They are excellent listeners and often provide emotional support to those in need. Their gentle and caring nature makes them cherished friends and partners. However, their sensitivity can sometimes make them prone to indecision and self-doubt.</p>

                    <p>Despite their gentle nature, Goats have a strong sense of determination and resilience. They are capable of working diligently towards their goals and achieving success through perseverance. However, their aversion to conflict can sometimes lead them to avoid difficult situations or decisions. It is important for Goats to develop assertiveness and confidence in expressing their needs and opinions.</p>

                    <p>Goats are also known for their love of beauty and aesthetics. They have a keen eye for detail and enjoy surrounding themselves with art and nature. This appreciation for the finer things in life often translates into a refined and elegant lifestyle. However, their desire for comfort and security can sometimes make them reluctant to step out of their comfort zones and take risks.</p>
                 </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Career and Compatibility of the Goat</h2>
                <p>In their careers, Goats excel in roles that require creativity, empathy, and attention to detail. They are often successful in fields such as art, design, and healthcare, where their compassionate and nurturing nature can shine. Goats are excellent team players who can collaborate effectively with others. Their ability to create harmony and beauty makes them valuable assets in any professional environment.</p>

                <p>Goats are known for their artistic talents and love of beauty. They thrive in environments that allow them to express their creativity and make a positive impact. Their empathy and understanding make them effective caregivers and counselors. However, their sensitivity can sometimes make them prone to indecision and self-doubt. It's important for Goats to develop confidence in their abilities and trust their instincts.</p>

                <p>When it comes to compatibility, Goats are most compatible with the Rabbit, Horse, and Pig. These signs complement the Goat's traits and help to balance their gentle nature. The Rabbit offers empathy and understanding, the Horse provides energy and enthusiasm, and the Pig shares the Goat's compassion and kindness. Goats may experience challenges in relationships with the Ox and Dog, as these signs may clash with the Goat's personality and create conflict. To maintain harmony, Goats need to practice clear communication and assertiveness in their relationships.</p>
                </div>

                <div style={{ padding: '20px', borderRadius: '12px', borderColor: '#646cff', borderWidth: '2px', borderStyle: 'solid', marginBottom: '50px', marginTop: '50px' }}>
                <h2>Health and Lifestyle of the Goat</h2>
                <p>Goats generally enjoy good health, thanks to their gentle and calm nature. They are usually mindful of their well-being and take care to maintain a balanced lifestyle. However, their sensitivity and tendency to worry can sometimes lead to stress and anxiety. It's important for Goats to find ways to relax and unwind, such as through meditation, yoga, or spending time in nature.</p>

                <p>Goats are prone to digestive issues and need to pay attention to their diet. Eating a balanced and nutritious diet, staying hydrated, and avoiding excessive consumption of rich or processed foods can help Goats maintain good health. Regular exercise, such as walking, swimming, or gentle yoga, can also be beneficial for their physical and mental well-being.</p>

                <p>In terms of lifestyle, Goats enjoy peaceful and harmonious environments. They are often drawn to activities that promote relaxation and creativity, such as reading, gardening, or engaging in artistic pursuits. Goats also value comfort and enjoy creating cozy, welcoming homes. Surrounding themselves with beauty and tranquility helps them maintain a positive and balanced outlook on life.</p>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/chinese_zodiac_horse' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '75px' }}>Previous: Horse</Link>
                    <Link to='/chinese_zodiac' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '75x', marginRight: '75px' }}>Chinese Zodiac</Link>
                    <Link to='/chinese_zodiac_monkey' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Monkey</Link>
                </div>

                </div>
            <Footer />
        </>
    )
}
