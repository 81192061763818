import { React, useEffect, useRef } from "react";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/footer';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Aquarius(){

    useEffect(() => {
        document.title = 'Zodiac | Aquarius | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType:"pageview",
        page:"/aquarius",
        title:"aquarius",})
    
        const topContainer = useRef();
        useEffect(() => {
            // To make sure page starts from the top
            topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
            }, []);

    return(
            <>
            <Navbar />
            <div ref={topContainer} />
            <div style={{width:'90%', maxWidth:'900px', margin:'auto'}}>
                <h1>Aquarius: The Water Bearer (January 20 - February 18)</h1>
                <div style={{margin:'auto', textAlign:'center'}}>
                    <img src='https://d3zlw6prgce9n.cloudfront.net/aquarius1.png' alt='Aquarius the Waterbearer' style={{width:'250px', height:'250px', borderRadius:'10px'}}/>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    Previous:<Link to='/capricorn' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Capricorn</Link>   
                    Next:      <Link to='/pisces' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Pisces</Link>
                </div>
                <p>Aquarius is the eleventh sign of the zodiac, symbolizing innovation, independence, and humanitarianism. Represented by the Water Bearer, Aquarius is ruled by Uranus, the planet of sudden changes, originality, and revolution. People born under this sign are known for their progressive thinking, intellectualism, and strong desire to make a positive impact on the world.</p>

                <h2>Personality Traits</h2>
                <p>Aquarius individuals are characterized by their visionary and forward-thinking nature. They are often seen as the trailblazers of the zodiac, unafraid to challenge the status quo and embrace new ideas. Aquarians are deeply intellectual and enjoy engaging in thought-provoking conversations. They have a strong sense of social justice and are passionate about humanitarian causes.</p>
                <p>Despite their sometimes aloof and detached demeanor, Aquarians are deeply caring and empathetic. They value their independence and freedom, often seeking unconventional paths in life. Aquarians are known for their creativity and originality, always bringing a fresh perspective to any situation. However, their need for independence can sometimes make them appear distant or unemotional to others.</p>

                <h2>Career</h2>
                <p>In the professional realm, Aquarius excels in roles that require innovation, creativity, and a forward-thinking approach. They are well-suited for careers in technology, science, research, and any field that involves problem-solving and futuristic thinking. Aquarians thrive in environments that allow them to explore new ideas and challenge conventional methods.</p>
                <p>Aquarius employees are known for their ability to think outside the box and come up with innovative solutions. They are often the ones who introduce new technologies and methodologies to their workplaces. However, they may need to work on their communication skills and ability to collaborate with others, as their independent nature can sometimes lead to isolation.</p>

                <h2>Relationships</h2>
                <p>In relationships, Aquarius is open-minded and experimental. They seek partners who can engage them intellectually and share their passion for social causes. Aquarius values honesty and transparency, and they appreciate partners who are communicative and open to new experiences. They are drawn to individuals who respect their need for independence and freedom.</p>
                <p>As friends, Aquarians are loyal and supportive, always ready to offer a unique perspective or a helping hand. In romantic relationships, they are affectionate and enjoy exploring new ideas and activities with their partners. However, their need for personal space and independence can sometimes create challenges, so finding a balance between togetherness and freedom is key to maintaining harmonious relationships.</p>

                <h2>Conclusion</h2>
                <p>Overall, Aquarius is a sign that embodies innovation, independence, and humanitarianism. Their intellectualism, creativity, and progressive nature make them inspiring and forward-thinking individuals. By embracing their strengths and working on their challenges, Aquarius individuals can lead fulfilling lives and make a significant impact on the world around them.</p>
            </div>

            <Footer />
            </>
    )}