import { React, useEffect, useRef } from "react";
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function TwoOfSwords() {

    useEffect(() => {
        document.title = 'Tarot | Two of Swords | Cosmic Secrets';
    }, []);

    ReactGA.send({
        hitType: "pageview",
        page: "two_of_swords",
        title: "two_of_swords",
    })

    const topContainer = useRef();
    useEffect(() => {
        // To make sure page starts from the top
        topContainer.current.scrollIntoView({ block: "end", behavior: 'smooth' });
    }, []);

    return (
        <>
            <div ref={topContainer} />
            <Navbar />
            <div style={{ width: '90%', maxWidth: '800px', margin: 'auto' }}>
                <header className="header" style={{ backgroundColor: 'rgb(46,45,41)' }} >
                    <h1>Two of Swords</h1>
                </header>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <Link to='/two_of_swords'>
                        <img src='https://d3zlw6prgce9n.cloudfront.net/Swords02.jpg' alt='Two of Swords Tarot Card' style={{ width: '200px', height: '330px', borderRadius: '10px', margin: '3px' }} />
                    </Link>
                </div>
                <div style={{ margin: 'auto', textAlign: 'center', marginTop: '25px' }}>
                    <Link to='/ace_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '50px' }}>Previous: Ace of Swords</Link>   
                    <Link to='/three_of_swords' style={{ textDecoration: 'none', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Next: Three of Swords</Link>
                </div>
                <h2>Overview of the Two of Swords</h2>

                <p>The Two of Swords is a card in the Minor Arcana of the Tarot deck that represents indecision, stalemate, and the need for balance. It signifies a period of being at a crossroads, facing difficult choices, and the necessity of finding equilibrium. This card encourages the querent to weigh their options carefully, seek inner balance, and make a decision with clarity and confidence.</p>

                <h2>Symbolism and Imagery</h2>

                <p>The imagery on the Two of Swords typically features a blindfolded woman holding two crossed swords, sitting by the water. The scene depicts a sense of indecision, balance, and the need to look inward for clarity. The overall imagery emphasizes themes of being at a crossroads, facing difficult choices, and finding equilibrium.</p>

                <p>The Two of Swords' symbolism emphasizes themes of indecision, stalemate, and the need for balance. This card encourages the querent to weigh their options carefully, seek inner balance, and make a decision with clarity and confidence.</p>

                <h2>Interpretation and Meaning</h2>

                <p>When the Two of Swords appears in a reading, it signifies indecision, stalemate, and the need for balance. It suggests that the querent is in or will soon enter a period of being at a crossroads, facing difficult choices, and the necessity of finding equilibrium. The Two of Swords encourages the querent to weigh their options carefully, seek inner balance, and make a decision with clarity and confidence.</p>

                <p>In the context of personal growth, the Two of Swords can indicate a period of self-discovery and the development of inner balance and decision-making skills. It may suggest that the querent is learning to navigate difficult choices and find equilibrium. In relationships, the Two of Swords can signify the need for clear communication, mutual understanding, and the resolution of conflicts within the partnership.</p>

                <p>In career and financial matters, the Two of Swords can signal opportunities for growth and success through careful decision-making and balance. It advises the querent to weigh their options carefully, seek inner balance, and make a decision with clarity and confidence. The Two of Swords reminds the querent that with indecision, stalemate, and the need for balance, they can achieve their goals and find fulfillment in their endeavors.</p>

                <h2>Conclusion</h2>

                <p>The Two of Swords in the Tarot deck is a powerful symbol of indecision, stalemate, and the need for balance. By understanding and embracing the energy it represents, individuals can weigh their options carefully, seek inner balance, and make a decision with clarity and confidence. The Two of Swords encourages the querent to weigh their options carefully, seek inner balance, and make a decision with clarity and confidence. It is a reminder that with indecision, stalemate, and the need for balance, we can achieve our goals and find fulfillment in our endeavors.</p>

                <Footer />
            </div>
        </>
    )
}
